import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Form, Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
// Utils/Actions
import Util from "../../util/Util";
import { updateUser } from "../../actions/user/user";
// Components
import CustomLabel from "../sub/CustomLabel";
import MenuButton from "../sub/bootstrap/MenuButton";
import InputTextElement from "../sub/InputTextElement";
import AlertModal from "../sub/modals/AlertModal";

class UpdatePwdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      disabled: false,
      error: null,
      modal: null,
    };
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
      error: null,
    });
    return;
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.password) ||
      Util.emptyString(this.state.passwordConfirmation)
    );
  }

  closeModal() {
    this.setState({
      password: "",
      passwordConfirmation: "",
    });
    return;
  }

  update() {
    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      let errorMessage = this.props.intl.formatMessage({
        id: "Password.Does.Not.Match",
      });
      NotificationManager.error(errorMessage);
      return;
    }

    // Check password syntax
    if (!Util.checkValidPasswordForRecovery(this.state.password)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "Password.Not.Conform",
      });
      NotificationManager.error(errorMessage);
      return;
    }

    const data = {
      updatedField: "password",
      updatedValue: this.state.password,
    };

    this.setState({ disabled: true });

    const onUpdatePwdSuccess = () => {
      const content = <FormattedMessage id="Password.Reset.Success" />;
      this.setState({
        disabled: false,
        modal: (
          <AlertModal content={content} onClose={() => this.closeModal()} />
        ),
      });
      return;
    };

    this.props.onUpdateUser(data, onUpdatePwdSuccess);
  }

  render() {
    return (
      <React.Fragment>
        <Form.Group as={Row} className="mb-3 align-items-center">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Password" })}
            required
            htmlFor="password"
            displayAsCol
          />
          {/* // hidden cuz not obligatory for us & our solution, but DOM & React give an advert : check -> https://www.chromium.org/developers/design-documents/create-amazing-password-forms  */}
          <InputTextElement
            hidden
            id="usernamePwChange"
            type="text"
            autoComplete="username"
            defaultValue={this.props.user.username}
          />
          <Col md={7}>
            <InputTextElement
              id="password"
              type="password"
              autoComplete="new-password"
              value={this.state.password}
              onChange={(e) => this.onChange("password", e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 align-items-center">
          <CustomLabel
            label={this.props.intl.formatMessage({
              id: "Password.Confirmation",
            })}
            required
            htmlFor="passwordConfirmation"
            displayAsCol
          />
          <Col md={7}>
            <InputTextElement
              id="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              value={this.state.passwordConfirmation}
              onChange={(e) =>
                this.onChange("passwordConfirmation", e.target.value)
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3 align-items-center">
          <Col className="text-end">
            <MenuButton
              type="submit"
              onClick={(e) => this.update()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Password.Modify" />
            </MenuButton>
          </Col>
        </Form.Group>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (data, successCallback) =>
      dispatch(updateUser(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UpdatePwdForm));
