import React from "react";
import { Modal, Row, Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeskProductCard from "./DeskProductCard";
import Icon from "../../sub/Icon";
import MenuButton from "../../sub/bootstrap/MenuButton";

export default class DeskProductRelatedProductsModal extends React.Component {
  render() {
    const {
      product,
      deskProducts,
      deskStore,
      closeModal,
      setOngoingState,
      scrollToTopDesk,
      openRelatedProductDetail,
    } = this.props;

    const products = deskProducts
      .filter((p) => product.relatedProducts.includes(p._id))
      .sort(
        (a, b) =>
          product.relatedProducts.indexOf(a._id) -
          product.relatedProducts.indexOf(b._id),
      );

    const productsNode = products.map((p) => (
      <DeskProductCard
        key={p._id}
        scrollToTopDesk={() => scrollToTopDesk()}
        setOngoingState={(state) => openRelatedProductDetail(state.product_id)}
        product={p}
        deskStore={deskStore}
      />
    ));

    return (
      <Modal
        show={true}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body style={{ backgroundColor: "#e9e9e9" }}>
          <Alert variant="success">
            <Icon icon="check-circle" className="me-2" />
            <FormattedMessage id="Additional.Products.Message" />
          </Alert>
          <Row md={3} className="mt-4 d-flex justify-content-center">
            {productsNode}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <MenuButton
            variant="warning"
            icon="arrow-circle-right"
            className="ms-2"
            onClick={() =>
              setOngoingState({ relatedCategories: true, modal: null })
            }
          >
            <FormattedMessage id="Skip.Additional.Products" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}
