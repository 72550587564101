import { GET_COORDINATION_NOTIF } from "../../actions/coordination/coordination";

export default function coordinationNotifications(state = 0, action) {
  switch (action.type) {
    case GET_COORDINATION_NOTIF:
      return action.coordination;
    default:
      return state;
  }
}
