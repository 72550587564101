import { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";

const TextareaCounter = ({
  id,
  className,
  value,
  onChange,
  onBlur,
  countLimit,
  rows,
  placeholder,
  disabled,
}) => {
  const [text, setText] = useState(value || "");

  useEffect(() => {
    setText(value || "");
  }, [value]);

  const handleTextChange = (event) => {
    const newText = event.target.value;
    setText(newText);
    if (onChange) {
      onChange(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const setClass = (textLength) => {
    let textLengthClass = "text-success";

    switch (true) {
      case textLength >= countLimit:
        textLengthClass = "text-danger";
        break;

      case textLength >= (countLimit / 4) * 3:
        textLengthClass = "text-warning";
        break;

      default:
        break;
    }
    return textLengthClass;
  };

  return (
    <Form.Group controlId={id} className={className}>
      <Row>
        <Col>
          <Form.Control
            as="textarea"
            rows={rows}
            value={text}
            onChange={handleTextChange}
            onBlur={handleBlur}
            maxLength={countLimit}
            placeholder={placeholder}
            disabled={disabled}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-end">
          <small>
            <div className={setClass(text.length)}>
              {text.length}/{countLimit}
            </div>
          </small>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default TextareaCounter;
