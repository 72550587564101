import { io } from "socket.io-client";

class WebSocketStarter {
  constructor(url, path) {
    this.url = url;
    this.path = path;
    this.socket = null;
  }

  setSocket(value) {
    this.socket = value;
  }

  getSocket() {
    return this.socket;
  }

  setUrl(value) {
    this.url = value;
  }

  getUrl() {
    return this.url;
  }

  start() {
    //SOCKET GOOO
    const socket = io(this.url, { path: this.path });
    this.setSocket(socket);
    this.on("test", () => console.log("reussi"));
  }

  emit(demand, data) {
    this.socket.emit(demand, data);
  }

  on(demand, callback) {
    this.socket.on(demand, callback);
  }
}

export default WebSocketStarter;
