import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_DOCUMENTS_CATEGORIES = "GET_DOCUMENTS_CATEGORIES";

export const createDocumentsCategories = function (data, successCallback) {
  return function () {
    return axios
      .post(APIUrl.addDocumentsDbCategory, data)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateDocumentsCategories = function (data, successCallback) {
  return function () {
    return axios
      .post(APIUrl.updateDocumentsDbCategory, data)
      .then(function () {
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDocumentsCategories = function (dataId, successCallback) {
  return function () {
    return axios
      .post(APIUrl.deleteDocumentsDbCategory, { categoryId: dataId })
      .then(function () {
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
