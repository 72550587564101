import Icon from "./Icon.js";
import React from "react";
import Util from "../../util/Util";
import PopoverHelper from "./bootstrap/PopoverHelper";
import { Form, Col } from "react-bootstrap";

export default class CustomLabel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      label: this.props.label ? this.props.label : "",
      htmlFor: this.checkProp(this.props.htmlFor) ? this.props.htmlFor : "",
      required: this.props.required ? this.props.required : null,
      help: this.props.help || false,
      helpPlacement: this.props.helpPlacement || "auto",
      displayAsCol: this.props.displayAsCol || false,
    };
  }

  // Check if required props are set and are of type "string" and not empty
  checkProp = (myProp) => {
    return Util.typeOf(myProp) === "String" && myProp.length > 0;
  };

  render() {
    let labelClassName = this.checkProp(this.props.labelClassName)
      ? this.props.labelClassName
      : "custom-label";

    let requiredIcon;

    // Specific options for labels made of icons where there is no need for a "String" label
    let iconOption = this.props.iconOptions ? this.props.iconOptions : null;
    if (iconOption && !this.state.label)
      iconOption = <Icon icon={iconOption} className={"mb-2 fa-sm"} />;

    if (this.state.required) {
      requiredIcon = <Icon icon="asterisk" />;
      labelClassName = "custom-label text-danger required " + labelClassName;
    }

    const myCustomLabel = (
      <Form.Label htmlFor={this.state.htmlFor} className={labelClassName}>
        <span>
          {this.state.label}
          {requiredIcon} {iconOption}
        </span>
      </Form.Label>
    );

    const myLabelHelper = this.state.help && (
      <small>
        <PopoverHelper placement={this.state.helpPlacement}>
          {this.state.help}
        </PopoverHelper>
      </small>
    );

    return this.state.displayAsCol ? (
      <Col>
        {myCustomLabel}
        {myLabelHelper}
      </Col>
    ) : (
      <>
        {myCustomLabel}
        {myLabelHelper}
      </>
    );
  }
}
