import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addRoomToDM,
  deleteRoomFromDM,
} from "../../../../actions/settings/auditDM/admin/auditDM";
import CreateRoomAssociationModal from "./CreateRoomAssociationModal";
import RoomAssociations from "./RoomAssociations";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import { Col, Row } from "react-bootstrap";

function AuditDMRoomsAdmin({
  currentDM,
  auditRooms,
  onAddRoomToDM,
  onDeleteRoomFromDM,
}) {
  const getRoomNames = (currentDMRooms) => {
    if (!currentDMRooms) return [];

    const names = [];

    outer: for (const room of currentDMRooms) {
      for (const c of auditRooms) {
        if (room.room_id === c._id) {
          names.push(c.name);
          continue outer;
        }
      }
    }
    return names;
  };

  const [dmRooms, setDmRooms] = useState(
    getRoomNames(currentDM && currentDM.rooms ? currentDM.rooms : []),
  );
  const [modal, setModal] = useState(null);

  const getRoomId = (roomName) => {
    for (const c of auditRooms) {
      if (c.name === roomName) return c._id;
    }
  };

  useEffect(() => {
    if (
      currentDM &&
      currentDM.rooms &&
      currentDM.rooms.length !== dmRooms.length
    ) {
      setDmRooms(getRoomNames(currentDM.rooms));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDM, dmRooms]);

  const closeModal = () => setModal(null);

  const addRoom = (roomId) => {
    if (!currentDM || !currentDM._id || !roomId) return;

    // Update list of associated rooms for current DM
    onAddRoomToDM({ auditDMId: currentDM._id, roomId: roomId }, () =>
      closeModal(),
    );
  };

  const openCreateRoomAssociationModal = () => {
    setModal(
      <CreateRoomAssociationModal
        close={() => closeModal()}
        addRoom={(roomId) => addRoom(roomId)}
      />,
    );
  };

  const deleteRoom = (room) => {
    if (!currentDM || !currentDM._id || !room) return;

    // Update auditRooms for current DM
    onDeleteRoomFromDM(
      { auditDMId: currentDM._id, roomId: getRoomId(room) },
      () => closeModal(),
    );
  };

  return (
    <>
      <TableToolbar>
        <Row>
          <Col className="text-end">
            <MenuButton onClick={() => openCreateRoomAssociationModal()}>
              <FormattedMessage id="Add.Environment" />
            </MenuButton>
          </Col>
        </Row>
      </TableToolbar>

      <RoomAssociations
        auditRooms={dmRooms}
        onDeleteRoom={(room) => deleteRoom(room)}
      />

      {modal}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddRoomToDM: (data, successCallback) =>
      dispatch(addRoomToDM(data, successCallback)),
    onDeleteRoomFromDM: (data, successCallback) =>
      dispatch(deleteRoomFromDM(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditDMRoomsAdmin));
