import ArrayUtil from "../../../util/ArrayUtil";
export default class Geolocation {
  // Get postal codes from api gouv.fr
  static getCitiesByPostalCode(postalCode, currentCity, successCallback) {
    var addressCompl =
      postalCode + "&postcode=" + postalCode + "&type=municipality&limit=25";

    async function getCitiesByPostalCodeRequest() {
      var APIaddress =
        "https://api-adresse.data.gouv.fr/search/?q=" + addressCompl;

      var config = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };

      const cityFromApi = fetch(APIaddress, config);
      return await cityFromApi;
    }

    var getCities = async (callbackFunction) => {
      try {
        const result = await getCitiesByPostalCodeRequest().then((res) => {
          return res.json();
        });
        return callbackFunction(result);
      } catch (e) {
        console.log(e);
      }
    };

    const callbackFunction = (result, callback) => {
      let list = [];
      for (let value of result.features) {
        if (value.properties.name + "" === currentCity) continue;
        list.push({
          _id: value.properties.name.toUpperCase() + "",
          name: value.properties.name.toUpperCase() + "",
        });
      }

      return successCallback(ArrayUtil.sortByKey(list, "_id"));
    };

    getCities(callbackFunction);
  }
}
