import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import AuditRoomsRowAdmin from "./AuditRoomsRowAdmin";
import { updateAuditRoom } from "../../../../actions/settings/auditRooms/admin/auditRooms";

class AuditRoomsTableAdmin extends React.Component {
  render() {
    var sortIndex = 1;
    var sortIndexForAll = false;
    let auditRoomsRowNode = this.props.auditRooms.map((auditRoom) => {
      if (!auditRoom.sortIndex || sortIndexForAll) {
        let data = {
          auditRoomId: auditRoom._id,
          updatedField: "sortIndex",
          updatedValue: sortIndex++,
        };
        this.props.onUpdateAuditRoom(data, () => {
          return null;
        });
        sortIndexForAll = true;
      }
      return (
        <AuditRoomsRowAdmin
          key={auditRoom._id}
          auditRoom={auditRoom}
          displaysDmAssociatedModal={(auditRoom, target) =>
            this.props.displaysDmAssociatedModal(auditRoom, target)
          }
          openEditModal={(auditRoom) => this.props.openEditModal(auditRoom)}
          openConfModal={(title, content, successCallback) =>
            this.props.openConfModal(title, content, successCallback)
          }
        />
      );
    });
    return (
      <React.Fragment>
        <table className="table table-striped table-bordered tablee4mad">
          <thead>
            <tr>
              <th className="col-3">
                <FormattedMessage id="Name" />
              </th>
              <th>
                <FormattedMessage id="Default.Text" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Associated.DM" />
              </th>
              <th className="col-2 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{auditRoomsRowNode}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateAuditRoom: (data, successCallback) =>
      dispatch(updateAuditRoom(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuditRoomsTableAdmin);
