import APIUrl from "../../APIUrl";
import axios from "axios";

// export const GET_AUDIT = "GET_AUDIT";

// function getAuditAction(audit) { return { type: GET_AUDIT, audit: audit } }
export const GET_DESK_AUDIT = "GET_DESK_AUDIT";

function getDeskAuditAction(deskaudits) {
  return { type: GET_DESK_AUDIT, deskaudits: deskaudits };
}

export const getDeskAudits = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskAudits)
      .then(function (response) {
        dispatch(getDeskAuditAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getDeskAuditsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskAuditsAdmin)
      .then(function (response) {
        dispatch(getDeskAuditAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getDeskAudit = function (name, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskAudit + name)
      .then(function (response) {
        dispatch(getDeskAuditAction(response.data));
        //if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskAudit = function (audit, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskAudit, audit)
      .then(function (response) {
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sendDeskAudit = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sendDeskAudit, data)
      .then(function (response) {
        // dispatch(getAudit(response.data));
        if (successCallback) successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDeskAudit = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskAudit, data)
      .then(function () {
        dispatch(getDeskAudits());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
