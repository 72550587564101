import APIUrl from "../../../../APIUrl";
import axios from "axios";
import { getAuditDMs } from "../../auditDM/admin/auditDM";

export const GET_AUDIT_ROOMS = "GET_AUDIT_ROOMS";

function getAuditRoomsAction(auditRooms) {
  return { type: GET_AUDIT_ROOMS, auditRooms: auditRooms };
}

export const getAuditRooms = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditRooms)
      .then(function (response) {
        dispatch(getAuditRoomsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getAuditRoom = function (name, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditRoom + name)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addAuditRoom = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addAuditRoom, data)
      .then(function (response) {
        dispatch(getAuditRooms());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addAuditRoomFile = function (auditRoomId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addAuditRoomFile + auditRoomId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function () {
        dispatch(getAuditRooms());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateAuditRoom = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateAuditRoom, data)
      .then(function () {
        dispatch(getAuditRooms());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteAuditRoom = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteAuditRoom, data)
      .then(function () {
        dispatch(getAuditRooms());
        dispatch(getAuditDMs());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sortRoom = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sortRoom, data)
      .then(function (response) {
        dispatch(getAuditRooms(() => successCallback()));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
