import React, { Component } from "react";
import Roles from "../../enums/Roles";
import Date from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import TextareaCounter from "../sub/TextareaCounter";
import { ListGroup, Container, Row, Col, Badge, Card } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";
import Icon from "../sub/Icon";
import StringUtil from "../../util/StringUtil";
import { connect } from "react-redux";
import Util from "../../util/Util";

class CoordinationChat extends Component {
  constructor(props) {
    super(props);
    this.messagesEndRef = React.createRef();
  }

  getClient(clientId, clients) {
    return clients.find((client) => client._id === clientId);
  }

  getTypeClient(client, prescriberType) {
    for (let p of prescriberType) {
      if (client.type === p._id) {
        return p.name;
      }
    }
    return null;
  }

  getCollaboratorType(client, collaboratorTypes) {
    for (let p of collaboratorTypes) {
      if (client.collaborator_type_id === p._id) {
        return p.name;
      }
    }
    return null;
  }

  getUserType(user) {
    switch (user.role) {
      case Roles.ADMIN:
        return this.props.intl.formatMessage({ id: `Role.${Roles.ADMIN}` });
      case Roles.SALES_REP:
        return StringUtil.ucFirst(
          this.getCollaboratorType(user, this.props.collaboratorTypes),
        );
      case Roles.CLIENT:
        return StringUtil.ucFirst(
          this.getTypeClient(user, this.props.prescriberTypes),
        );
      default:
        return this.props.intl.formatMessage({ id: "Not.Specified" });
    }
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ behavior: "instant" });
    }
  };

  getStatus(list, client) {
    for (let user of list) {
      if (user.user_id === client._id) {
        return true;
      }
    }
    return false;
  }

  testActivity(client) {
    let activityTest = false;
    if (typeof client === "object") {
      activityTest = this.getStatus(this.props.connectedList, client);
    } else if (client === true) {
      activityTest = true;
    }

    return activityTest;
  }

  badgeActivity(client) {
    let activityTest = this.testActivity(client);

    return (
      <Badge bg={activityTest ? "success" : "secondary"}>
        <Icon
          icon={
            activityTest
              ? "fa-regular fa-circle-dot"
              : "fa-regular fa-times-circle"
          }
          className="me-1"
        />
        <small>
          {activityTest ? (
            <FormattedMessage id="Online" />
          ) : (
            <FormattedMessage id="Offline" />
          )}
        </small>
      </Badge>
    );
  }

  setGradient(degree) {
    degree = degree === "0deg" ? degree : "180deg";

    const gradient = {
      background:
        "linear-gradient(" +
        degree +
        ", rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 100%)",
    };

    if (degree === "0deg") {
      return (
        <div
          className="position-absolute w-100 bottom-0 p-3 z-1"
          style={gradient}
        />
      );
    } else {
      return (
        <div className="position-absolute w-100 p-3 z-1" style={gradient} />
      );
    }
  }

  messageCard(
    senderIsSystem,
    senderName,
    message,
    lastMessage,
    offset,
    variant,
  ) {
    offset = offset ? 6 : 0;
    variant = variant || "info";
    const textVariant = senderIsSystem ? "success" : "secondary";

    return (
      <Container fluid key={message._id}>
        <Row>
          <Col md={{ span: 6, offset: offset }}>
            <Card bg={variant}>
              <Card.Body>
                <Row className="align-items-center">
                  <Col className="col-3 pe-0">
                    <Icon
                      icon={senderIsSystem ? "rotate" : "user"}
                      size="1x"
                      className={
                        "text-" +
                        textVariant +
                        " img-thumbnail rounded-circle p-3"
                      }
                      fixedWidth
                    />
                  </Col>
                  <Col className="ps-0">
                    <strong>{senderName}</strong>

                    <p className="text-start">
                      {senderIsSystem ? (
                        <FormattedMessage id={message.message} />
                      ) : (
                        message.message
                      )}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="text-end" md={{ span: 6, offset: offset }}>
            <p className="text-secondary">
              <small>
                {lastMessage && (
                  <>
                    <span
                      className={"text-danger cursor-pointer"}
                      onClick={() => this.props.onRemoveMessage(message._id)}
                    >
                      <FormattedMessage id={"Delete"} />
                    </span>
                    <span> - </span>
                  </>
                )}
                {Date.toDateWithHour(message.createdAt)}
              </small>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { clients, messages, user, onChangeMessage, send } = this.props;

    const sortedClients = clients
      .map((client) => ({ ...client })) // Ou fais une autre transformation si nécessaire
      .sort((a, b) => {
        // Vérifie que 'name' existe pour éviter l'erreur
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        } else {
          return 0; // Si 'name' est manquant, les laisse à leur place d'origine
        }
      });

    return (
      <Container fluid id="coordinationChat">
        {/* Chat Section */}
        <Row>
          {/* Chat Box */}
          <Col ref={this.chatBoxRef} className="ps-0">
            <Card>
              <Card.Body>
                <Row>
                  <Col className="pe-0">
                    <Container fluid className="position-relative p-0">
                      {this.setGradient("180deg")}
                      <div
                        className="py-5 ps-2 pe-3 h-100 overflow-auto"
                        style={{ maxHeight: "60vh", scrollBehavior: "smooth" }}
                      >
                        {messages &&
                          messages.map((message, i) => {
                            if (message.sender === user._id) {
                              return this.messageCard(
                                false,
                                Util.formatFullName(
                                  user?.first_name,
                                  user?.name,
                                ),
                                message,
                                messages.length - 1 === i,
                                message.sender === user._id,
                                message.sender === user._id
                                  ? "primary"
                                  : "secondary",
                              );
                            } else if (message.sender === null) {
                              return this.messageCard(
                                true,
                                <FormattedMessage id={"Notification.System"} />,
                                message,
                              );
                            } else {
                              return this.messageCard(
                                false,
                                Util.formatFullName(
                                  this.getClient(message.sender, clients)
                                    ?.first_name,
                                  this.getClient(message.sender, clients)?.name,
                                ),
                                message,
                                false,
                                false,
                                "light",
                              );
                            }
                          })}
                      </div>
                      {this.setGradient("0deg")}
                    </Container>
                  </Col>
                </Row>

                <Row className="align-items-end pt-3 pb-3 border-top align-items-top">
                  <Col sm={9} xl={10}>
                    {/* Typing area */}
                    <TextareaCounter
                      countLimit="300"
                      rows={2}
                      placeholder={this.props.intl.formatMessage({
                        id: "Add.Message",
                      })}
                      id="placeholderText"
                      value={this.props.sendingMessage || ""}
                      onChange={(e) => {
                        onChangeMessage(e.target.value);
                      }}
                    />
                  </Col>
                  <Col sm={3} xl={2} className="align-self-start">
                    <MenuButton
                      onClick={send}
                      disabled={
                        !this.props.sendingMessage ||
                        this.props.sendingMessage === "" ||
                        this.props.sendingMessage === null
                      }
                      icon="paper-plane"
                      className="w-100"
                    >
                      <FormattedMessage id="Send" />
                    </MenuButton>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col className="col-auto px-0">
            <ListGroup>
              <ListGroup.Item variant="dark">
                <strong>
                  {this.props.intl.formatMessage({ id: "Attendees" })}
                </strong>
              </ListGroup.Item>
              {sortedClients.map((client) => (
                <ListGroup.Item
                  className={
                    this.testActivity(client) ? "accordion-selected p-2" : "p-2"
                  }
                  disabled={!this.testActivity(client)}
                  key={client._id}
                >
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <Icon
                        icon="user"
                        size="1x"
                        className={
                          this.testActivity(client)
                            ? "text-success img-thumbnail rounded-circle p-3 border border-success"
                            : "text-secondary img-thumbnail rounded-circle p-3"
                        }
                        fixedWidth
                      />
                    </Col>
                    <Col className="col-auto">
                      <p className="m-0">
                        <strong>
                          {Util.formatFullName(client.first_name, client.name)}
                        </strong>
                      </p>
                      <p className="m-0 text-nowrap">
                        <small>{this.getUserType(client)}</small>
                      </p>
                    </Col>
                    <Col className="text-end">{this.badgeActivity(client)}</Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>

            <ListGroup className="mt-3">
              <ListGroup.Item className="p-2 bg-primary">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <Icon
                      icon="user"
                      size="1x"
                      className="text-success img-thumbnail rounded-circle p-3 border border-success"
                      fixedWidth
                    />
                  </Col>
                  <Col className="col-auto">
                    <p className="m-0">
                      <strong>
                        {Util.formatFullName(user.first_name, user.name)}
                      </strong>
                    </p>
                    <p className="m-0 text-nowrap">
                      <small>{this.getUserType(user)}</small>
                    </p>
                  </Col>
                  <Col className="text-end">
                    {this.badgeActivity(this.props.user)}
                    <div ref={this.messagesEndRef}></div>
                  </Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>

            <ListGroup className="mt-3">
              <ListGroup.Item key="system" className="p-2 bg-info">
                <Row className="align-items-center">
                  <Col className="pe-0">
                    <Icon
                      icon="rotate"
                      size="1x"
                      className="text-success img-thumbnail rounded-circle p-3 border border-success"
                      spin
                      fixedWidth
                    />
                  </Col>
                  <Col className="col-auto ps-0">
                    <p className="m-0">
                      <strong>PHARMA MAD</strong>
                    </p>
                    <p className="m-0 text-nowrap">
                      <small>
                        <FormattedMessage id="Notification.System" />
                      </small>
                    </p>
                  </Col>
                  <Col className="text-end">{this.badgeActivity(true)}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          {/* Participants List */}
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prescriberTypes: state.prescriberTypes,
    collaboratorTypes: state.collaboratorTypes,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CoordinationChat));
