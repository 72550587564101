import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { updateProductSettingAdmin } from "../../../../actions/settings/productsSettings/admin/productsSettings";
import CustomLabel from "../../../sub/CustomLabel";
import InputNumber from "../../../sub/fields/input/InputNumber";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  FormSelect,
  InputGroup,
  Row,
} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PopoverHelper from "../../../sub/bootstrap/PopoverHelper";

class ProductsSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      margin_selected: this.props.productsSettingsAdmin.margin_selected,
      margin_rate_min: this.props.productsSettingsAdmin.margin_rate_min,
      margin_rate_max: this.props.productsSettingsAdmin.margin_rate_max,
      gross_margin_rate_min:
        this.props.productsSettingsAdmin.gross_margin_rate_min,
      gross_margin_rate_max:
        this.props.productsSettingsAdmin.gross_margin_rate_max,
    };
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  update(field, value) {
    const data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateProductSettingAdmin(data);
  }

  render() {
    const fieldRateMin =
      this.state.margin_selected === "margin"
        ? "margin_rate_min"
        : "gross_margin_rate_min";
    const fieldRateMax =
      this.state.margin_selected === "margin"
        ? "margin_rate_max"
        : "gross_margin_rate_max";

    return (
      <Container className="w-75">
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title className="p-0 m-0 text-dark">
                  <FormattedMessage id="Product.Price.Calculation" />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Alert variant="info">
                  Choisissez d'afficher l'indicateur de marge le plus adapté à
                  votre stratégie commerciale.
                </Alert>
                <Form.Group as={Row} className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Margin.Rate.Selection",
                    })}
                    htmlFor="selected_margin"
                    labelClassName="ps-0"
                    displayAsCol
                  />
                  <Col md={4}>
                    <FormSelect
                      id="selected_margin"
                      className="form-control"
                      value={this.state.margin_selected}
                      onChange={(e) => {
                        this.onFieldChange("margin_selected", e.target.value);
                        this.update("margin_selected", e.target.value);
                      }}
                    >
                      <option value="margin">
                        <FormattedMessage id="Margin.Rate" />
                      </option>
                      <option value="gross_margin">
                        <FormattedMessage id="Gross.Margin.Rate" />
                      </option>
                    </FormSelect>
                  </Col>
                  <Col md={1} className="text-center">
                    <PopoverHelper>
                      <FormattedMessage
                        id={
                          this.state.margin_selected === "margin"
                            ? "Margin.Rate.Info"
                            : "Gross.Margin.Rate.Info"
                        }
                      />
                    </PopoverHelper>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Margin.Rate.Min",
                    })}
                    htmlFor="rateMin"
                    displayAsCol
                  />
                  <Col md={4}>
                    <InputGroup style={{ borderBottom: 0 }} id="rateMin">
                      <InputNumber
                        id="inputGroupRateMin"
                        min={1}
                        defaultValue={this.state[fieldRateMin]}
                        onChange={(e) => this.onFieldChange(fieldRateMin, e)}
                        onBlur={(e) =>
                          this.update(fieldRateMin, e.target.value)
                        }
                      />
                      <InputGroup.Text id="inputGroupRateMin">
                        %
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md={1} className="text-center">
                    <PopoverHelper>
                      <FormattedMessage
                        id={
                          this.state.margin_selected === "margin"
                            ? "Margin.Rate.Info"
                            : "Gross.Margin.Rate.Info"
                        }
                      />
                    </PopoverHelper>
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center mb-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Margin.Rate.Max",
                    })}
                    htmlFor="rateMax"
                    displayAsCol
                  />
                  <Col md={4}>
                    <InputGroup style={{ borderBottom: 0 }} id="rateMax">
                      <InputNumber
                        id="inputGroupRateMax"
                        min={1}
                        max={
                          this.state.margin_selected === "margin"
                            ? undefined
                            : this.state.margin_rate_max
                        }
                        defaultValue={this.state[fieldRateMax]}
                        onChange={(e) => this.onFieldChange(fieldRateMax, e)}
                        onBlur={(e) =>
                          this.update(fieldRateMax, e.target.value)
                        }
                      />
                      <InputGroup.Text id="inputGroupRateMax">
                        %
                      </InputGroup.Text>
                    </InputGroup>
                  </Col>
                  <Col md={1} className="text-center">
                    <PopoverHelper>
                      <FormattedMessage
                        id={
                          this.state.margin_selected === "margin"
                            ? "Margin.Rate.Info"
                            : "Gross.Margin.Rate.Info"
                        }
                      />
                    </PopoverHelper>
                  </Col>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productsSettingsAdmin: state.productsSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateProductSettingAdmin: (data) =>
      dispatch(updateProductSettingAdmin(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductsSettingsAdmin));
