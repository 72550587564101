import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COLLABORATORS = "GET_COLLABORATORS";

function getCollaboratorsAction(collaborators) {
  return { type: GET_COLLABORATORS, collaborators: collaborators };
}

export const getCollaborators = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCollaborators)
      .then(function (response) {
        dispatch(getCollaboratorsAction(response.data));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addCollaborator = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCollaborator, data)
      .then(function (response) {
        if (successCallback)
          dispatch(getCollaborators(() => successCallback(response.data)));
        else dispatch(getCollaborators());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateCollaborator = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateCollaborator, data)
      .then(function (response) {
        dispatch(getCollaborators());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeCollaborator = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeCollaborator, data)
      .then(function (response) {
        dispatch(getCollaborators());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addCollaboratorClient = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addCollaboratorClient, data)
      .then(function (response) {
        dispatch(getCollaborators());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeCollaboratorClient = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeCollaboratorClient, data)
      .then(function (response) {
        dispatch(getCollaborators());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
