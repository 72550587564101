import APIUrl from "../../../../APIUrl";
import axios from "axios";

export const GET_ADMIN_PRODUCTS_SETTINGS = "GET_ADMIN_PRODUCTS_SETTINGS";

function getProductsSettingsAdminAction(productsSettingsAdmin) {
  return {
    type: GET_ADMIN_PRODUCTS_SETTINGS,
    productsSettingsAdmin: productsSettingsAdmin,
  };
}

export const getProductsSettingsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getProductsSettingsAdmin)
      .then(function (response) {
        dispatch(getProductsSettingsAdminAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateProductSettingAdmin = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateProductSettingAdmin, data)
      .then(function (response) {
        dispatch(getProductsSettingsAdmin());
      })
      .catch(function (err) {
        throw err;
      });
  };
};
