import APIUrl from "../../../../APIUrl";
import axios from "axios";

export const GET_PRESCRIBER_TYPES = "GET_PRESCRIBER_TYPES";
export const DELETE_PRESCRIBER_TYPE = "DELETE_PRESCRIBER_TYPE";

function getPrescriberTypesAction(prescriberTypes) {
  return { type: GET_PRESCRIBER_TYPES, prescriberTypes: prescriberTypes };
}
function deletePrescriberTypeAction(prescriberTypeId) {
  return { type: DELETE_PRESCRIBER_TYPE, prescriberTypeId: prescriberTypeId };
}

export const getPrescriberTypes = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPrescriberTypes)
      .then(function (response) {
        if (response) dispatch(getPrescriberTypesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPrescriberType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPrescriberType, data)
      .then(function (response) {
        dispatch(getPrescriberTypes());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updatePrescriberType = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updatePrescriberType, data)
      .then(function () {
        dispatch(getPrescriberTypes());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePrescriberType = function (
  prescriberTypeId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deletePrescriberType, { prescriberTypeId: prescriberTypeId })
      .then(function () {
        dispatch(deletePrescriberTypeAction(prescriberTypeId));
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
