import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PrescriberTypeModalAdmin from "./PrescriberTypeModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import {
  deletePrescriberType,
  updatePrescriberType,
} from "../../../../actions/settings/prescriberType/admin/prescriberType";
import ActionMenu from "../../../sub/ActionMenu";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class PrescriberTypes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openPrescriberTypeModal(clientType) {
    this.setState({
      modal: (
        <PrescriberTypeModalAdmin
          isOpen={true}
          clientType={clientType}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openEditModal(prescriberType) {
    this.setState({
      modal: (
        <PrescriberTypeModalAdmin
          isOpen={true}
          closeModal={() => this.closeModal()}
          prescriberType={prescriberType}
        />
      ),
    });
  }

  delete(_id, name) {
    this.setState({
      modal: (
        <ConfirmationModal
          onAccept={() => this.props.onDeletePrescriberType(_id)}
          onDecline={() => this.closeModal()}
        >
          <FormattedMessage
            id="Client.Type.Remove.Confirmation"
            values={{ name: name }}
          />
        </ConfirmationModal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  getClientTypesRow(clientType) {
    const clientTypesRow = this.props.prescriberTypes
      .filter((ct) => ct.client_type === clientType)
      .map((t, i) => {
        const menuItems = [];

        menuItems.push(
          {
            icon: "pen-to-square",
            action: () => this.openEditModal(t),
            text: <FormattedMessage id="Modify" />,
          },
          {
            icon: "trash",
            action: () => this.delete(t._id, t.name),
            text: <FormattedMessage id="Delete" />,
          },
        );

        return (
          <tr key={i}>
            <td className="align-middle">{t.name}</td>
            <td className="text-end">
              <ActionMenu items={menuItems} />
            </td>
          </tr>
        );
      });

    return clientTypesRow;
  }

  getClientTypes(clientType) {
    const clientTypes = this.props.prescriberTypes.filter(
      (ct) => ct.client_type === clientType,
    );
    return (
      <>
        <TableToolbar
          message={
            (!clientTypes || clientTypes.length === 0) && (
              <FormattedMessage id="Empty.Client.Types" />
            )
          }
        >
          <Row>
            <Col className="text-end">
              <MenuButton
                onClick={(e) => this.openPrescriberTypeModal(clientType)}
              >
                <FormattedMessage id="Add.Client.Type" />
              </MenuButton>
            </Col>
          </Row>
        </TableToolbar>

        {clientTypes && clientTypes.length !== 0 && (
          <table className="table table-striped tablee4mad">
            <thead>
              <tr className="tablerowhead">
                <th className="col">
                  <FormattedMessage id="Categorie.Client" />{" "}
                </th>
                <th className="text-end">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{this.getClientTypesRow(clientType)}</tbody>
          </table>
        )}

        {this.state.modal}
      </>
    );
  }

  render() {
    return (
      <Tabs
        defaultActiveKey="client-types-professional"
        id="settings-client-types"
      >
        <Tab
          eventKey="client-types-professional"
          title={<FormattedMessage id="Professional" />}
        >
          {this.getClientTypes("prescriber")}
        </Tab>

        <Tab
          eventKey="client-types-individual"
          title={<FormattedMessage id="Individual" />}
        >
          {this.getClientTypes("helper")}
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    prescriberTypes: state.prescriberTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePrescriberType: (_id) => dispatch(deletePrescriberType(_id)),
    onUpdatePrescriberType: (prescriberTypes) =>
      dispatch(updatePrescriberType(prescriberTypes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrescriberTypes);
