import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { findClient } from "../../../actions/apiMust/apiMust";
import Util from "../../../util/Util";
import CustomLabel from "../../sub/CustomLabel";
import FindCliModal from "./FindCliModal";
import AlertModal from "../../sub/modals/AlertModal";
import Icon from "../../sub/Icon.js";
import MenuButton from "../../sub/bootstrap/MenuButton";
import { findClientLomaco } from "../../../actions/apiLomaco/apiLomaco";
import { updClient } from "../../../actions/clients/clients";
import { CrmProviders } from "../../../enums/CrmProviders";
import { Col, Form, Row } from "react-bootstrap";

class ApiConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crmId:
        this.props.client.crm_id &&
        Util.typeOf(this.props.client.crm_id !== "Null")
          ? this.props.client.crm_id
          : "",
      linkedAccount:
        this.props.client.crm_id &&
        Util.typeOf(this.props.client.crm_id !== "Null")
          ? true
          : false,
      loading: false,
      modal: null,
    };
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  findClient(crmSoftware) {
    this.setState({ loading: true });
    let data = {
      crmId: this.state.crmId,
    };

    if (
      this.props.company.crm.enabled &&
      this.props.company.crm.software === CrmProviders.MUST.software
    ) {
      this.props.onFindClient(
        data,
        (result) => this.openModal(crmSoftware, result),
        (result) => this.openModal(crmSoftware, result.data, data),
      );
    } else if (
      this.props.company.crm.enabled &&
      this.props.company.crm.software === CrmProviders.LOMACO.software
    ) {
      this.props.onLomacoClient(
        data,
        (result) => this.openModal(crmSoftware, result),
        (result) => this.openModal(crmSoftware, result.data, data),
      );
    }
    // Send to BE
  }

  openModal(crmSoftware, data, dataCodeSearch) {
    if (data.code !== 512) {
      this.setState({
        loading: false,
        modal: (
          <FindCliModal
            apiData={data}
            closeModal={() => this.closeModal()}
            client={this.props.client}
            crmId={this.state.crmId}
            linkedAccount={this.state.linkedAccount}
            onComplete={(crmId) => this.onComplete(crmId)}
          />
        ),
      });
    } else {
      if (data.data) {
        if (data.errorMessage === "No client found") {
          this.props.onUpdatePrescriber(
            {
              _id: this.props.client._id,
              updatedField: "crm_id",
              updatedValue: "",
            },
            () => {},
          );
        }
        this.setState({
          loading: false,
          modal: (
            <AlertModal
              title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
              content={
                <>
                  <FormattedMessage
                    id={data.data}
                    values={{ crmSoftware: crmSoftware }}
                  />
                  <br></br>
                  <code>{JSON.stringify(data.errorMessage)}</code>
                </>
              }
              onClose={() => this.closeModal()}
            />
          ),
        });
      } else {
        this.setState({
          loading: false,
          modal: (
            <AlertModal
              title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
              content={
                <FormattedMessage
                  id="API.CRM.Client.Find.Error"
                  values={{
                    dataCodeSearch: dataCodeSearch.crmId,
                    crmSoftware: crmSoftware,
                  }}
                />
              }
              onClose={() => this.closeModal()}
            />
          ),
        });
      }
    }
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  onComplete(crmId) {
    this.setState({
      crmId: crmId,
      linkedAccount: crmId !== "",
    });

    return this.closeModal();
  }

  definedClientCode() {
    return this.state.crmId && Util.typeOf(this.state.crmId === "Number");
  }

  render() {
    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { software: crmSoftware = null } = this.props.company.crm
      ? this.props.company.crm
      : {};

    return (
      <React.Fragment>
        <div className="mb-3">
          {this.state.linkedAccount ? (
            <div className="alert alert-success">
              <Icon icon="circle-check" className="me-2" />
              <FormattedMessage
                id="API.CRM.Client.Linked"
                values={{ crmSoftware: crmSoftware }}
              />
            </div>
          ) : (
            <div className="alert alert-info">
              <Icon icon="info-circle" className="me-2" />
              <FormattedMessage
                id="API.CRM.Client.Link.Info"
                values={{ crmSoftware: crmSoftware }}
              />
            </div>
          )}

          <Form.Group as={Row} className="align-items-center">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Client.Code" })}
              htmlFor="crmId"
              displayAsCol
            />
            <Col md={9}>
              <input
                disabled={this.state.linkedAccount}
                type="text"
                className="form-control w-50 me-2"
                id="crmId"
                value={this.state.crmId}
                // https://github.com/react-bootstrap/react-bootstrap/issues/1517
                onInput={(e) => {
                  // e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 5)
                }}
                onChange={(e) => this.onFieldChange("crmId", e.target.value)}
              />
              <MenuButton
                icon={this.state.loading ? "spinner" : "magnifying-glass"}
                disabled={!this.definedClientCode() || this.state.loading}
                className="me-2"
                onClick={() => this.findClient(crmSoftware)}
                processing={this.state.loading}
              >
                {this.state.loading ? (
                  <FormattedMessage id="Plz.Wait" />
                ) : (
                  <FormattedMessage id="Verify" />
                )}
              </MenuButton>
            </Col>
          </Form.Group>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFindClient: (data, successCallback, failureCallback) =>
      dispatch(findClient(data, successCallback, failureCallback)),
    onLomacoClient: (data, successCallback, failureCallback) =>
      dispatch(findClientLomaco(data, successCallback, failureCallback)),
    onUpdatePrescriber: (data, successCallback) =>
      dispatch(updClient(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ApiConfiguration));
