import React from "react";
import Maths from "../../util/Maths";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import PriceUtil from "../../util/PriceUtil";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";
import { FormattedMessage } from "react-intl";

class OrderProductRow extends React.Component {
  render() {
    const { product } = this.props;
    return (
      <tr id={"product-" + product.id_product}>
        <td
          className={
            product.urgent
              ? "align-middle text-danger bg-danger bg-opacity-10"
              : "align-middle"
          }
        >
          {product.ref}
        </td>
        <td
          className={
            product.urgent
              ? "align-middle text-danger bg-danger bg-opacity-10"
              : "align-middle"
          }
        >
          {ProductsParserUtil.swapDesignationDelimiter(product.designation) +
            " "}
          {product.urgent && (
            <PopoverHelper variant="danger" icon="bell">
              <FormattedMessage id="Product.Is.Urgent" />
            </PopoverHelper>
          )}
        </td>
        <td
          className={
            product.urgent
              ? "align-middle text-danger bg-danger bg-opacity-10"
              : "align-middle"
          }
        >
          {product.quantity}
        </td>
        <td
          className={
            product.urgent
              ? "align-middle text-danger bg-danger bg-opacity-10"
              : "align-middle"
          }
        >
          {PriceUtil.formatEuro(Maths.round(product.prix_u_ht_emera))}
        </td>
        <td
          className={
            product.urgent
              ? "align-middle text-danger bg-danger bg-opacity-10"
              : "align-middle"
          }
        >
          {PriceUtil.formatEuro(Maths.round(product.total_ht))}
        </td>
      </tr>
    );
  }
}

export default OrderProductRow;
