const ClientMapping = {
  account_type: "TYPE DE COMPTE",
  client_type: "TYPE DE CLIENT",
  type: "CATEGORIE",
  mercurial_Id: "CATALOGUE ASSOCIE",
  name: "NOM",
  first_name: "PRENOM",
  address: "ADRESSE",
  addressAdditionnal: "COMPL. ADRESSE",
  postal_code: "CODE POSTAL",
  city: "VILLE",
  phone: "TELEPHONE",
  mobile: "TEL. PORTABLE",
  email: "EMAIL",
  notifyByEmail: "NOTIFIER PAR EMAIL",
};

export default ClientMapping;
