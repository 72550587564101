import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import Icon from "../Icon";
import MenuButton from "../bootstrap/MenuButton";

class AlertModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      content: this.props.content || "your content here.",
    };
  }

  /**
   * The component is not destroyed anymore on each call.
   * Instead, we use the regular hide function of Modal bootstrap (which means also that the Modal will close smoothly from now on)
   * But if we need to display the modal again (after it have been close once), then we need to check what is the previous recorded state
   * That is the reason of this componentDidUpdate call.
   * BENEFIT : The close method is not required anymore to manage the confirm dialog.
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.show) {
      this.setState({
        show: true,
        content: this.props.content || "your content here.",
      });
    }
  }

  switchTextColor(variant) {
    let color;
    switch (variant) {
      case "danger":
      case "secondary":
      case "dark":
      case "success":
        color = "text-white";
        break;

      default:
        break;
    }

    return color;
  }

  onClose() {
    // Little hack there. Should have used proper callbacks, but the modal was used everywhere,
    // so used async/await no to break the existing code.
    let callback = async () => {
      await this.props.onClose();
    };

    this.setState({ show: false }, callback);
  }

  render() {
    const { size, title, content, closeButtonLabel, variant } = this.props;

    return (
      <Modal
        show={this.state.show}
        onHide={() => this.onClose()}
        size={size}
        backdrop="static"
        keyboard={false}
        centered={this.props.centered || false}
        variant={variant}
      >
        <Modal.Header className={"bg-" + variant} closeButton>
          <Modal.Title className={this.switchTextColor(variant)}>
            {variant && variant === "danger" && (
              <Icon icon="exclamation-triangle" className="me-1" />
            )}
            {title ? title : <FormattedMessage id="Confirmation" />}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{content}</Modal.Body>

        <Modal.Footer>
          <MenuButton variant={variant} onClick={() => this.onClose()}>
            {closeButtonLabel || <FormattedMessage id="Close" />}
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(AlertModal);
