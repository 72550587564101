import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Alert, Badge } from "react-bootstrap";
import ReactImageFallback from "react-image-fallback";
import Icon from "../sub/Icon.js";
import MenuButton from "../sub/bootstrap/MenuButton";

class UploadProductImgModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      // Uploaded files
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: "hide",
      progressBarVisibility: "show",
      progressbarMessage: (
        <FormattedMessage id="Import.Mercurial.Image.Upload" />
      ),
    });

    this.props.onComplete(this.state.customImg);
  }

  onDropFile(files) {
    const that = this;

    var reader = new FileReader();
    reader.onloadend = function () {
      var imageSrc = reader.result;
      that.setState({
        customImg: [files],
        disabledDropzone: true,
        previewImage: imageSrc,
        disabled: false,
      });
    };

    reader.readAsDataURL(files);
  }

  abortCustomImage() {
    this.setState({
      customImg: [],
      disabledDropzone: false,
      previewImage: false,
    });
  }

  disabled() {
    return this.state.disabled || !this.state.customImg.length;
  }

  render() {
    const currentProductImage = this.props.currentProductImage;
    const defaultProductImage = this.props.defaultProductImage;

    let modalTitle = <FormattedMessage id="Product.Image.Update" />;
    let modalWarning = (
      <Alert variant="success" className="text-justify">
        <FormattedMessage id="Imagery.Message.1" />
      </Alert>
    );
    let confirmButton = !this.disabled() && (
      <MenuButton onClick={() => this.onComplete()}>
        <FormattedMessage id="Import" />
      </MenuButton>
    );

    if (this.props.mode === "delete") {
      modalTitle = <FormattedMessage id="Product.Image.Delete" />;
      modalWarning = (
        <Alert variant="danger" className="text-justify">
          <FormattedMessage id="Imagery.Message.2" />
        </Alert>
      );
      confirmButton = (
        <MenuButton variant="danger" onClick={() => this.onComplete()}>
          <FormattedMessage id="Confirm" />
        </MenuButton>
      );
    }

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="container">
            {modalWarning}
            <div className="row">
              <div className="col-5 text-center">
                <Badge bg="success" className="mb-2">
                  <FormattedMessage id="Suggested.Illustration" />
                </Badge>
                <div className="card-img-top d-block mx-auto img-thumbnail">
                  <ReactImageFallback
                    src={currentProductImage}
                    fallbackImage="/images/no_image_512.png"
                    initialImage="/images/loading.gif"
                    style={{ width: "200px" }}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-2 text-center p-0 pt-5">
                <Icon
                  icon="arrow-right"
                  className={
                    this.state.disabledDropzone
                      ? "fa-3x text-success pt-5"
                      : this.props.mode === "delete"
                        ? "fa-3x text-danger pt-5"
                        : "fa-3x text-dark disabled pt-5"
                  }
                />
              </div>

              {this.props.mode === "upload" && (
                <div className="col-5 text-center">
                  <Badge bg="info" className="mb-2">
                    <FormattedMessage id="Custom.Imagery" />
                  </Badge>
                  {!this.state.disabledDropzone && (
                    <FileDropZone
                      onDropFile={(file) => this.onDropFile(file)}
                      acceptedExtensions={["jpg", "jpeg", "png"]}
                      appearance="thumbnail"
                      multiple={false}
                    />
                  )}
                  {this.state.disabledDropzone && (
                    <div>
                      <div
                        className="card-img-top d-block mx-auto img-thumbnail"
                        style={
                          this.state.disabledDropzone && {
                            border: "1px solid #28a745",
                          }
                        }
                      >
                        <div className="position-absolute">
                          <MenuButton
                            icon="trash"
                            onClick={() => this.abortCustomImage()}
                            variant="danger"
                            className="btn-sm"
                          />
                        </div>
                        <ReactImageFallback
                          src={this.state.previewImage}
                          fallbackImage="/images/no_image_512.png"
                          initialImage="/images/loading.gif"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.props.mode === "delete" && (
                <div className="col-5 text-center">
                  <Badge bg="info" className="mb-2">
                    <FormattedMessage id="Default.Imagery" />
                  </Badge>
                  <div
                    className="card-img-top d-block mx-auto img-thumbnail"
                    style={{ border: "1px solid #dc3545" }}
                  >
                    <ReactImageFallback
                      src={defaultProductImage}
                      fallbackImage="/images/no_image_512.png"
                      initialImage="/images/loading.gif"
                      style={{ width: "200px" }}
                      alt=""
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          {confirmButton}
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UploadProductImgModal));
