import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

const propTypes = {
  title: PropTypes.any.isRequired,
  content: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
};

/**
 * Item for one row of split home
 * @param {String} formattedMessageId id to get formatted message
 * @param {*} content react component to display on card
 * @param {String} link navlink to redirect
 */
class SplitHomeItem extends React.Component {
  render() {
    const { title, content, link } = this.props;
    return (
      <Link to={link} className="HomeItem">
        <Card className="h-100 mb-3">
          <Card.Header>
            <Card.Title>
              <h4 className="text-dark">{title}</h4>
            </Card.Title>
          </Card.Header>
          <Card.Body>{content}</Card.Body>
        </Card>
      </Link>
    );
  }
}

SplitHomeItem.propTypes = propTypes;

export default SplitHomeItem;
