import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Row, Col, Alert, Modal } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import StringUtil from "../../../util/StringUtil";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import DeskProductRelatedCatalogProductRow from "./DeskProductRelatedCatalogProductRow";
import MenuButton from "../../sub/bootstrap/MenuButton";

class DeskProductRelatedCatalogProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: this.props.productToSwap || null,
      productToDelete: null,
    };
  }

  selectProduct(product) {
    this.setState({ selectedProduct: product, productToDelete: null });
  }

  unselectProduct(product) {
    const isProductInCart =
      this.props.deskCart.filter((p) => p._id === product.product_id).length >
      0;

    if (isProductInCart) this.setState({ productToDelete: product });

    this.setState({ selectedProduct: null });
  }

  addToCart(product) {
    const callback = () => {
      NotificationManager.success(
        `${this.props.intl.formatMessage({
          id: "Cart.Add.Success",
        })} ${StringUtil.truncate(product.designation, 50)}`,
        <FormattedMessage id="Message" />,
        6000,
      );
      this.props.closeModal();
    };

    product.quantity = 1;
    product.deskStoreProduct = this.props.deskStoreProduct;

    this.props.addToCart(product, callback);
  }

  deleteFromCart(product) {
    const callback = () => {
      NotificationManager.info(
        <FormattedMessage
          id="Cart.Remove.Success"
          values={{ product: StringUtil.truncate(product.designation, 50) }}
        />,
        <FormattedMessage id="Message" />,
        6000,
      );
      this.props.closeModal();
    };

    this.props.deleteFromCart(product, callback);
  }

  swapProduct(oldProduct, newProduct) {
    const callback = () => {
      NotificationManager.success(
        this.props.intl.formatMessage({ id: "Cart.Replace.Success" }),
        <FormattedMessage id="Message" />,
        6000,
      );
      this.props.closeModal();
    };

    newProduct.quantity = 1;
    newProduct.deskStoreProduct = this.props.deskStoreProduct;

    this.props.deleteFromCart(oldProduct, () =>
      this.props.addToCart(newProduct, callback),
    );
  }

  onConfirm() {
    if (!this.state.selectedProduct && this.state.productToDelete)
      return this.deleteFromCart(this.state.productToDelete);

    if (
      !this.state.selectedProduct ||
      (this.props.productToSwap &&
        this.props.productToSwap._id === this.state.selectedProduct._id)
    )
      return this.props.closeModal();

    this.props.productToSwap
      ? this.swapProduct(this.props.productToSwap, this.state.selectedProduct)
      : this.addToCart(this.state.selectedProduct);
  }

  render() {
    const { deskStoreProduct, products, closeModal } = this.props;

    const productsFromCatalog = products.filter((p) =>
      deskStoreProduct.productsFromCatalog.find(
        (pFromCatalog) => pFromCatalog.product_id === p._id,
      ),
    );

    for (let p of productsFromCatalog) {
      p.sortIndex = deskStoreProduct.productsFromCatalog.find(
        (_p) => _p.product_id === p._id,
      ).sortIndex;
    }

    const productsFromCatalogIds = deskStoreProduct.productsFromCatalog.map(
      (p) => p.product_id,
    );

    const formatedProducts = ProductsParserUtil.parseProducts(
      productsFromCatalog,
      productsFromCatalogIds,
    );

    const productsFromCatalogNodes = formatedProducts
      .sort((a, b) => a.sortIndex - b.sortIndex)
      .map((product) => (
        <DeskProductRelatedCatalogProductRow
          key={product._id}
          product={product}
          productInState={this.state.selectedProduct}
          selectProduct={(product) => this.selectProduct(product)}
          unselectProduct={(product) => this.unselectProduct(product)}
        />
      ));

    return (
      <Modal
        show={true}
        onHide={() => closeModal()}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Products.From.Catalog" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col>
              {deskStoreProduct.productsFromCatalog.length > 0 ? (
                productsFromCatalogNodes
              ) : (
                <Alert variant="secondary">
                  <FormattedMessage id="Products.None" />
                </Alert>
              )}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => closeModal()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton onClick={() => this.onConfirm()}>
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(DeskProductRelatedCatalogProducts);
