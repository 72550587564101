import { GET_DOCUMENTS_CATEGORIES } from "../../actions/documents/categories";

export default function documentsCategories(state = [], action) {
  switch (action.type) {
    case GET_DOCUMENTS_CATEGORIES:
      return action.documentsCategories;
    default:
      return state;
  }
}
