import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import "chart.js/auto";

import PriceUtil from "../../util/PriceUtil";
import { Alert, Col, Container, Row } from "react-bootstrap";

class ProductsBudget extends React.Component {
  sortMap(map) {
    return Object.keys(map)
      .sort((a, b) => map[b] - map[a])
      .reduce(
        (_sortedObj, key) => ({
          ..._sortedObj,
          [key]: map[key],
        }),
        {},
      );
  }

  buildChartData(priceUtilPerFamily, isLine = false) {
    let priceUtilData = {};
    for (let famille of Object.keys(priceUtilPerFamily)) {
      if (this.props.graphData === "quantity")
        priceUtilData[famille] = priceUtilPerFamily[famille].quantity();
      else if (this.props.graphData === "totalHT")
        priceUtilData[famille] = priceUtilPerFamily[famille].totalHt();
      else if (this.props.graphData === "totalTTC")
        priceUtilData[famille] = priceUtilPerFamily[famille].totalTtc();
    }

    priceUtilData = this.sortMap(priceUtilData);

    let data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],
          borderColor: [],
          borderWidth: [],
        },
      ],
    };

    for (let famille of Object.keys(priceUtilData)) {
      // Label
      data.labels.push(famille);

      // Data
      data.datasets[0].data.push(priceUtilData[famille]);

      // BG color
      let color = Util.stringToColor(famille);
      if (!isLine) data.datasets[0].backgroundColor.push(color);
      else {
        data.datasets[0].pointBackgroundColor.push(color);
        data.datasets[0].pointBorderColor.push(color);
        data.datasets[0].backgroundColor.push("rgba(0, 0, 0, 0)");
        data.datasets[0].borderColor.push(color);
        data.datasets[0].borderWidth.push(5);
      }
    }

    return data;
  }

  render() {
    if (!this.props.orders) return null;

    // Gather up all products from all targeted orders
    var orderProducts = [];
    for (let order of this.props.orders) {
      // Careful with start & end dates
      if (
        new Date(order.creation_date) < new Date(this.props.startDate) ||
        new Date(order.creation_date) > new Date(this.props.endDate)
      )
        continue;

      for (let product of order.products) orderProducts.push(product);
    }

    let priceUtilPerFamily = {};

    for (let orderProduct of orderProducts) {
      if (!priceUtilPerFamily[orderProduct.famille])
        priceUtilPerFamily[orderProduct.famille] = new PriceUtil();
      priceUtilPerFamily[orderProduct.famille].add(
        orderProduct,
        orderProduct.quantity,
      );
    }

    let options = {
      legend: {
        display: this.props.graphType === "pie",
      },
    };

    if (
      this.props.graphData === "totalHT" ||
      this.props.graphData === "totalTTC"
    ) {
      options.tooltips = {
        enabled: true,
        mode: "single",
        callbacks: {
          label: (tooltipItems, data) => {
            let value = tooltipItems.yLabel;
            if (!value) value = data.datasets[0].data[tooltipItems.index];

            if (!tooltipItems.label)
              return (
                " " +
                data.labels[tooltipItems.index] +
                " : " +
                PriceUtil.formatEuro(value)
              );
            return PriceUtil.formatEuro(value);
          },
        },
      };
    }
    return (
      <Container>
        {Object.keys(priceUtilPerFamily).length === 0 ? (
          <Row>
            <Col>
              <Alert variant="secondary">
                <FormattedMessage id="Empty.Stats.Orders" />
              </Alert>
            </Col>
          </Row>
        ) : (
          <Row className="pt-3 justify-content-center">
            <Col md={6} className="text-center">
              {this.props.graphType === "pie" && (
                <Doughnut
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerFamily)}
                />
              )}
              {this.props.graphType === "bar" && (
                <Bar
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerFamily)}
                />
              )}
              {this.props.graphType === "line" && (
                <Line
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerFamily, true)}
                />
              )}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default injectIntl(ProductsBudget);
