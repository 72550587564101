const Theme = {
  pharma: {
    id: "pharma_theme",
    name: "PHARMA",
    colors: {
      primary: "#a8c34c",
      secondary: "#b2d33f",
      light: "#b2d33f",
      dark: "#7d8e3f",
      menu: {
        primary: "#627323",
        secondary: "#808789",
        border: "#b7da36",
        border_hover: "#88a02f",
        border_selected: "#627323",
        background: "#e0f78f",
      },
    },
  },
};

export default Theme;
