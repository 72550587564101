import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Modal, Tab, Tabs } from "react-bootstrap";

import Collaborator from "./Collaborator";
import CollaboratorPermissions from "./CollaboratorPermissions";
import { FormattedMessage } from "react-intl";

class CollaboratorsModal extends React.Component {
  close() {
    this.props.close();
  }

  getCollaborator(collaboratorId) {
    if (!collaboratorId) return null;

    for (let collaborator of this.props.collaborators) {
      if (collaborator._id === collaboratorId) return collaborator;
    }
  }

  render() {
    const { collaboratorId, intl, company, onCollaboratorCreated } = this.props;

    let collaborator = this.getCollaborator(collaboratorId);

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!collaboratorId ? (
              <FormattedMessage id="Add.Collaborator" />
            ) : (
              <FormattedMessage id="Edit.Collaborator" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="clients-info" id="clientsTabs">
            <Tab
              eventKey="clients-info"
              title={intl.formatMessage({ id: "Informations" })}
              tabClassName={"text-brown"}
            >
              <Collaborator
                company={company}
                collaborator={collaborator}
                onCollaboratorCreated={(collaborator) =>
                  onCollaboratorCreated(collaborator)
                }
              />
            </Tab>
            <Tab
              eventKey="clients-permissions"
              title={intl.formatMessage({ id: "Permissions" })}
              tabClassName={"text-brown"}
              disabled={!collaborator}
            >
              <CollaboratorPermissions collaborator={collaborator} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CollaboratorsModal));
