import React from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import Util from "../../../../util/Util";
import StringUtil from "../../../../util/StringUtil";
import APIUrl from "../../../../APIUrl";
import Roles from "../../../../enums/Roles";
import ProductsParserUtil from "../../../../util/ProductsParserUtil";

import { FormattedMessage, injectIntl } from "react-intl";
import { Badge, Col, Image, Row } from "react-bootstrap";

class AutoSuggestProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || "",
      suggestions: [],
    };

    if (this.props.parentSuggestInputCbk)
      this.props.parentSuggestInputCbk((value) => this.setState({ value }));
  }

  onChange(event, { newValue }) {
    // Change state value. Careful with i18n option
    this.setState({
      value:
        !Util.emptyString(newValue) && this.props.i18n
          ? this.props.intl.formatMessage({ id: newValue })
          : newValue,
    });

    if (this.props.onChange) this.props.onChange(newValue);
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  getSuggestionValue(suggestion) {
    return ProductsParserUtil.swapDesignationDelimiter(suggestion.designation);
  }

  highlightSearchValue(string, substring, uppercase) {
    // Prepare strings for easier comparison
    let checkString = StringUtil.removeDiacritics(string.toLowerCase());
    let checkValue = StringUtil.removeDiacritics(substring.toLowerCase());

    // Define a string delilmiter for future splits
    let splitDelimiter = "[***]";

    // Search for substring to find in string
    if (checkString.includes(checkValue)) {
      let tempString = checkString.replace(
        checkValue,
        splitDelimiter + checkValue + splitDelimiter,
      );
      let fragments = tempString.split(splitDelimiter);

      return fragments.map((fragment) => {
        if (fragment === checkValue) {
          return (
            <mark key={Math.random()}>
              {uppercase ? substring.toUpperCase() : substring}
            </mark>
          );
        } else {
          return uppercase ? fragment.toUpperCase() : fragment;
        }
      });
    } else {
      return string;
    }
  }

  renderSuggestion(suggestion, value) {
    // if user is not client, maybe he will upload new pictures. We avoid caching them to display changes instantly.
    // Role "CLIENT" will use regular browser cache management.
    const forceCacheRefresh =
      this.props.user.role === Roles.CLIENT ? 0 : Math.random();

    return (
      <small>
        <Row className="p-2 border-bottom border-secondary">
          <Col md={3}>
            <Image
              src={
                APIUrl.getProductImg +
                suggestion.mercurial_id +
                "/" +
                encodeURIComponent(suggestion.ref_frn) +
                "/" +
                forceCacheRefresh +
                "/" +
                encodeURIComponent(suggestion.ref) +
                "?token=" +
                APIUrl.jwtToken
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/images/no_image_512.png";
              }}
              thumbnail
              fluid
              style={{ maxWidth: "10em" }}
              alt=""
            />
          </Col>
          <Col md={9}>
            <strong>
              {this.highlightSearchValue(
                ProductsParserUtil.swapDesignationDelimiter(
                  suggestion.designation,
                ),
                value,
                true,
              )}
            </strong>
            <div>
              <Badge>
                <FormattedMessage id="Reference" />
                {": " + suggestion.ref}
              </Badge>
            </div>
            <p
              className="text-muted pb-0 m-0 mt-2"
              style={{
                display: "-webkit-box",
                overflow: "hidden",
                WebkitLineClamp: "4",
                WebkitBoxOrient: "vertical",
              }}
            >
              <small>
                {this.highlightSearchValue(suggestion.caracteristiques, value)}
              </small>
            </p>
          </Col>
        </Row>
      </small>
    );
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions(value) {
    const inputValue = StringUtil.removeDiacritics(value.trim().toLowerCase());
    const inputLength = inputValue.length;

    // Iterator for suggestions
    let countSuggestions = 0;
    // Max number of valid suggestions returned
    const maxSuggestions = 20;

    return inputLength === 0
      ? []
      : this.props.suggestions.filter((s) => {
          let result = false;
          // Search scope

          if (countSuggestions <= maxSuggestions) {
            result =
              StringUtil.removeDiacritics(s.designation.toLowerCase()).includes(
                inputValue,
              ) ||
              StringUtil.removeDiacritics(
                s.caracteristiques.toLowerCase(),
              ).includes(inputValue) ||
              s.ref.toLowerCase().includes(inputValue) ||
              s.ref_frn.toLowerCase().includes(inputValue) ||
              StringUtil.removeDiacritics(s.famille.toLowerCase()).includes(
                inputValue,
              ) ||
              StringUtil.removeDiacritics(
                s.sous_famille.toLowerCase(),
              ).includes(inputValue);
            if (result) {
              countSuggestions++;
            }
          }

          return result && countSuggestions <= maxSuggestions ? result : false;
        });
  }

  onSuggestionSelected(event, obj) {
    this.props.onSuggestionSelected(obj.suggestion);
  }

  render() {
    const { value, suggestions } = this.state;

    var placeholder = this.props.placeholder
      ? this.props.intl.formatMessage({ id: this.props.placeholder })
      : "";

    const inputProps = {
      placeholder: placeholder,
      value,
      onChange: (event, obj) => this.onChange(event, obj),
      onClick: (event, obj) => {
        if (this.props.onClick) this.props.onClick(event, obj);
      },
    };

    suggestions.sort(function (a, b) {
      if (
        a.designation.toLowerCase().includes(value) === true &&
        b.designation.toLowerCase().includes(value) === true
      ) {
        return -1;
      } else if (
        a.designation.toLowerCase().includes(value) === false &&
        b.designation.toLowerCase().includes(value) === true
      ) {
        return 1;
      } else if (
        a.designation.toLowerCase().includes(value) === false &&
        b.designation.toLowerCase().includes(value) === false
      ) {
        return 0;
      } else if (
        a.designation.toLowerCase().includes(value) === true &&
        b.designation.toLowerCase().includes(value) === false
      ) {
        return -1;
      } else {
        return 0;
      }

      //return a.designation.toLowerCase().includes(value)?a:null;
    });
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={(o) => this.onSuggestionsFetchRequested(o)}
        onSuggestionsClearRequested={() => this.onSuggestionsClearRequested()}
        getSuggestionValue={(s) => this.getSuggestionValue(s)}
        renderSuggestion={(s) => this.renderSuggestion(s, value)}
        inputProps={inputProps}
        onSuggestionSelected={(event, obj) =>
          this.onSuggestionSelected(event, obj)
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(injectIntl(AutoSuggestProducts));
