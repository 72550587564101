import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import Icon from "../Icon";
class PopoverHelper extends React.Component {
  render() {
    const variant = this.props.variant || "info";
    const placement = this.props.placement || "auto";
    const trigger = this.props.trigger || false;
    const className = this.props.className || "";
    let icon = this.props.icon || false;

    let title;
    let usedIcon;
    switch (variant) {
      case "danger":
        usedIcon = icon || "circle-exclamation";
        title = this.props.title || <FormattedMessage id="Warning" />;
        break;
      case "success":
        usedIcon = icon || "circle-check";
        title = this.props.title || <FormattedMessage id="Success" />;
        break;
      default:
        usedIcon = icon || "circle-question";
        title = this.props.title || <FormattedMessage id="Contextual.Help" />;
        break;
    }

    let myPopover = (
      <Popover
        className={"border-" + variant}
        style={{ boxShadow: "2px 2px 5px 0px rgba(0, 0, 0, 0.2)" }}
      >
        {title && (
          <Popover.Header className={"bg-" + variant + " text-light pt-1 pb-1"}>
            <Icon icon={usedIcon} className="me-1" />
            {title}
          </Popover.Header>
        )}
        <Popover.Body className="text-justify">
          {this.props.children}
        </Popover.Body>
      </Popover>
    );

    return trigger ? (
      <OverlayTrigger
        trigger={trigger}
        placement={placement}
        overlay={myPopover}
      >
        <span className={className}>
          <Icon
            className={"text-" + variant + " ms-2 zoomable cursor-pointer"}
            icon={usedIcon}
          />
        </span>
      </OverlayTrigger>
    ) : (
      <OverlayTrigger placement={placement} overlay={myPopover}>
        <span className={className}>
          <Icon
            className={"text-" + variant + " ms-2 zoomable cursor-pointer"}
            icon={usedIcon}
          />
        </span>
      </OverlayTrigger>
    );
  }
}

export default PopoverHelper;
