import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Form, Row, Col } from "react-bootstrap";
import RequestTypes from "../../enums/RequestTypes";
import TextareaCounter from "../sub/TextareaCounter";
import Roles from "../../enums/Roles";
import FileDropZone from "../sub/FileDropZone";
import {
  updateRequest,
  addRequestFiles,
  deleteRequestFile,
} from "../../actions/requests/requests";
import NewMaterialRequest from "./NewMaterialRequest";
import APIUrl from "../../APIUrl";
import CustomLabel from "../sub/CustomLabel";
import FileUtil from "../../util/FileUtil";
import InputLength from "../../enums/InputLength.js";
import MenuButton from "../sub/bootstrap/MenuButton";

class RequestModal extends React.Component {
  constructor(props) {
    super(props);
    let maxFiles = 3;

    this.state = {
      files: [],
      errorFilesDropped: null,
      disabled: false,
      response: "",
      disabledDropZone: false,
      maxFiles: maxFiles,
      maxFileSize: process.env.REACT_APP_UPLOAD_MAXFILESIZE || 2097152, // If not defined in .env, we set it to 2mb (in bytes)
    };

    if (this.props.requestId && this.getRequest(this.props.requestId)) {
      this.state = {
        ...this.state,
        files: this.getRequest(this.props.requestId).files,
        response: this.getRequest(this.props.requestId).response || "",
        disabledDropZone:
          this.getRequest(this.props.requestId).files.length >= maxFiles,
      };
    }
  }

  update(request) {
    let response = this.state.response;

    let data = {
      requestId: request._id,
      updatedField: "response",
      updatedValue: response,
    };

    this.props.onUpdateRequest(data, () => {});
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  checkDataLengthFile(files) {
    let response = false;
    for (let file of files) {
      if (file.size > this.state.maxFileSize) {
        response = true;
      }
    }
    return response;
  }

  onDropFiles(files, request, clearCallback) {
    let formData = new FormData();

    // Required to lookup for possible duplicates
    let fileCount = 0;
    formData.set("fileCount", fileCount);

    if (this.state.files.length + files.length <= this.state.maxFiles) {
      if (!this.checkDataLengthFile(files)) {
        let value = [];
        value = this.state.files;

        for (let file of files) {
          // Avoid duplicates
          if (value.indexOf(file.name) === -1) {
            value.push(file.name);
            this.setState({ files: value });
            formData.append(file.name, file);
            fileCount++;
          }
        }

        // this.setState({ disabledDropZone: true });

        var successCallback = () => {
          clearCallback();
          if (this.state.files.length >= this.state.maxFiles) {
            this.setState({ disabledDropZone: true });
          } else {
            this.setState({ disabledDropZone: false });
          }
        };

        this.setState({ errorFilesDropped: null });

        // Upload only valid files and total files below or equal max allowed files to upload
        if (fileCount > 0) {
          this.props.onAddRequestFiles(request._id, formData, successCallback);
        }
      } else {
        this.setState({
          errorFilesDropped: (
            <FormattedMessage
              id="File.Oversized"
              values={{ value: FileUtil.bytesToSize(this.state.maxFileSize) }}
            />
          ),
        });
      }
    } else {
      this.setState({
        errorFilesDropped: (
          <FormattedMessage
            id="File.Maxlength"
            values={{
              maxFiles: this.state.maxFiles,
              submittedFiles: files.length,
            }}
          />
        ),
      });
    }
  }

  removeFile = (request, file) => {
    // Prepare data to be sent to the BE
    let data = {
      requestId: request._id,
      fileName: file,
    };

    // Define a successcalback on file deletion
    var successCallback = () => {
      // Update state value for files
      const newStateFiles = this.state.files.filter(
        (stateFile) => stateFile !== file,
      );
      this.setState({ files: newStateFiles });

      if (this.state.files.length <= this.state.maxFileSize) {
        this.setState({ disabledDropZone: false });
      }
    };

    this.props.ondeleteRequestFile(data, successCallback);
  };

  getRequest(requestId) {
    for (let request of this.props.requests) {
      if (request._id === requestId) {
        return request;
      }
    }
  }

  render() {
    const { user, requestId, requestType, close, intl } = this.props;

    let isClient = user.role === Roles.CLIENT;
    let request = this.getRequest(requestId);

    let clientRequest = null;
    let requestTypeValue = null;
    if (requestType === RequestTypes.NEW_MATERIAL) {
      requestTypeValue = "New.Material";
      clientRequest = (
        <NewMaterialRequest
          request={request}
          user={user}
          close={() => close()}
        />
      );
    }

    let adminResponseNode = null;
    adminResponseNode = (
      <Form.Group as={Row} className="align-items-center mt-3">
        <CustomLabel
          label={intl.formatMessage({ id: "Response" })}
          htmlFor="response"
        />
        <Col md={7}>
          <TextareaCounter
            countLimit={InputLength.TEXT_LONG}
            id="response"
            rows={5}
            value={this.state.response}
            disabled={isClient}
            onChange={(e) => this.onChange("response", e.target.value)}
            onBlur={() => this.update(request)}
          />
        </Col>
      </Form.Group>
    );

    let filesNode = null;
    if (request && request.files && request.files.length > 0) {
      filesNode = request.files.map((file, key) => {
        return (
          <div key={key} className="mb-1">
            <small>
              {!isClient && (
                <MenuButton
                  icon="trash"
                  size={"sm"}
                  variant="danger"
                  className="me-2"
                  onClick={() => this.removeFile(request, file)}
                />
              )}
              <a
                role="button"
                href={
                  APIUrl.getRequestFile +
                  request._id +
                  "/" +
                  file +
                  "?token=" +
                  APIUrl.jwtToken
                }
                rel="noopener noreferrer"
                download={file}
                target="_blank"
              >
                {file}
              </a>
            </small>
          </div>
        );
      });
    }

    return (
      <Modal
        show={true}
        onHide={() => close()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={"Request." + requestTypeValue} />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {clientRequest}

          {request && (filesNode || adminResponseNode) && (
            <>
              <hr />
              <Form.Group as={Row} className="align-items-center mt-3">
                <div className="col-12 col-sm-5">
                  <span>
                    <FormattedMessage id="Files" />
                  </span>
                </div>
                <Col md={7}>
                  {!isClient && (
                    <div className="mb-2">
                      <FileDropZone
                        disabled={this.state.disabledDropZone}
                        onDropFiles={(files, clearCallback) =>
                          this.onDropFiles(files, request, clearCallback)
                        }
                        acceptedExtensions={[
                          "pdf",
                          "doc",
                          "docx",
                          "jpg",
                          "png",
                          "jpeg",
                        ]}
                        multiple={true}
                      />
                      <FormattedMessage id="Attachments" />:{" "}
                      {this.state.files.length} / {this.state.maxFiles}
                      <p className="text-danger">
                        {this.state.errorFilesDropped}
                      </p>
                    </div>
                  )}

                  {filesNode}
                </Col>
              </Form.Group>
              {adminResponseNode}
            </>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    requests: state.requests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateRequest: (data, successCallback) =>
      dispatch(updateRequest(data, successCallback)),
    onAddRequestFiles: (requestId, data, successCallback) =>
      dispatch(addRequestFiles(requestId, data, successCallback)),
    ondeleteRequestFile: (data, successCallback) =>
      dispatch(deleteRequestFile(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RequestModal));
