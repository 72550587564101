import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomLabel from "../../../sub/CustomLabel";
import { Col, Form, Row } from "react-bootstrap";
import InputNumber from "../../../sub/fields/input/InputNumber";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";

class ShippingModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.index === -1) {
      let max =
        this.props.shippingCosts.length > 0
          ? this.props.shippingCosts[this.props.shippingCosts.length - 1].max
          : 0;

      this.state = {
        min: max,
        max: max + 1,
        cost: 0,
        disabledInputMax: true,
        defaultChecked: true,
        alert: null,
      };
    } else {
      var hasMax = this.props.shippingCosts[this.props.index].max;

      this.state = {
        min: this.props.shippingCosts[this.props.index].min,
        max: this.props.shippingCosts[this.props.index].max,
        cost: this.props.shippingCosts[this.props.index].cost,
        disabledInputMax: !hasMax,
        defaultChecked: !hasMax,
        alert: null,
      };
    }
  }

  close() {
    this.props.closeModal();
  }

  onComplete() {
    if (this.state.min >= this.state.max && !this.state.disabledInputMax) {
      this.setState({ alert: <FormattedMessage id="Min.Greater.Max" /> });
      return false;
    }

    let shippingCosts = this.props.shippingCosts || [];

    let shippingCost = {
      min: this.state.min,
      max: this.state.max,
      cost: this.state.cost || 0,
    };

    if (this.state.disabledInputMax) shippingCost.max = "";

    // Costs exist, modify appropriate line
    if (this.props.index !== -1) shippingCosts[this.props.index] = shippingCost;
    // Costs do not exist, add a new line
    else shippingCosts.push(shippingCost);

    this.props.successCallback(shippingCosts);

    // This is an update, so don't close the window
    if (this.props.index === -1) this.close();
  }

  onChangeMin(min) {
    this.setState({ min: min });
  }

  onChangeMax(max) {
    this.setState({ max: max });
  }

  onChangeCost(cost) {
    this.setState({ cost: cost });
  }

  onClickRadioMax(e) {
    if (this.state.disabledInputMax)
      this.setState({
        max: document.getElementById("maxShippingCost").value,
        disabledInputMax: false,
      });
    else this.setState({ disabledInputMax: true });
  }

  render() {
    var buttonText = <FormattedMessage id="Create" />;
    if (this.props.shippingCost) buttonText = <FormattedMessage id="Modify" />;

    return (
      <ConfirmationModal
        title={<FormattedMessage id="Shipping.Cost" />}
        confirmButtonLabel={buttonText}
        onAccept={() => (this.props.index === -1 ? this.onComplete() : {})}
        onDecline={() => this.close()}
        onDeclineDisabled={this.props.index !== -1}
      >
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Minimum" })}
            htmlFor="Min"
            displayAsCol
            required={this.props.required}
          />
          <Col md={7}>
            <InputNumber
              id="minShippingCost"
              disabled={true}
              min={0}
              defaultValue={this.state.min}
              onChange={(e) => this.onChangeMin(e)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Maximum" })}
            htmlFor="Max"
            displayAsCol
            required={this.props.required}
          />
          <Col md={7}>
            <Row className="align-items-center">
              <Col className="col-auto">
                <Form.Check
                  type="radio"
                  name="max"
                  onBlur={() => this.props.index !== -1 && this.onComplete()}
                  onChange={(e) => this.onClickRadioMax(e)}
                  value="number"
                  defaultChecked={!this.state.defaultChecked}
                />
              </Col>
              <Col>
                <InputNumber
                  id="maxShippingCost"
                  disabled={this.state.disabledInputMax}
                  min={0}
                  defaultValue={this.state.max || this.state.min + 1}
                  onBlur={() => this.props.index !== -1 && this.onComplete()}
                  onChange={(e) => this.onChangeMax(e)}
                />
              </Col>
              <Col className="col-auto">
                <Form.Check
                  type="radio"
                  name="max"
                  onBlur={() => this.props.index !== -1 && this.onComplete()}
                  onChange={(e) => this.onClickRadioMax(e)}
                  defaultChecked={this.state.defaultChecked}
                  value="infinity"
                />
              </Col>
              <Col className="ps-0">
                <h4 className="m-0 ms-2">&infin;</h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <small className="text-danger">{this.state.alert}</small>
              </Col>
            </Row>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({
              id: "Shipping.Cost.Excl.Tax",
            })}
            htmlFor="Cost"
            displayAsCol
            required={this.props.required}
          />
          <Col md={7}>
            <InputNumber
              id="cost"
              min={0}
              defaultValue={this.state.cost}
              onBlur={() => this.props.index !== -1 && this.onComplete()}
              onChange={(e) => this.onChangeCost(e)}
            />
          </Col>
        </Form.Group>
      </ConfirmationModal>
    );
  }
}

export default injectIntl(ShippingModalAdmin);
