import React from "react";
import { connect } from "react-redux";
import FileDropZone from "../sub/FileDropZone";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Form, Row } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import Icon from "../sub/Icon";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import DateUtil from "../../util/DateUtil";
import { updateMercurial } from "../../actions/mercurials/mercurials";
import MercurialStatus from "../../enums/MercurialStatus";
import MenuButton from "../sub/bootstrap/MenuButton";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class MercurialFileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nowDate:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2),
      disabled: false,
      file: null,
      name: this.props.mercurial ? this.props.mercurial.name : "",
      startDate: this.props.mercurial
        ? this.props.mercurial.start_date
        : new Date(),
      endDate: this.props.mercurial ? this.props.mercurial.end_date : "",
      nameError: null,
      dateError: null,
      endDateError: null,
      errorYear: null,
      formVisibility: true,
      progressBarVisibility: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  onDropFile(file) {
    this.setState({ file: file });
  }

  onNameChange(e) {
    var name = e.target.value;

    for (let mercurial of this.props.mercurials) {
      if (mercurial.name === name) {
        this.setState({
          name: name,
          nameError: <FormattedMessage id="Mercurials.Already.Imported" />,
        });
        return;
      }
    }

    this.setState({ name: name, nameError: null });
  }

  onStartDateChange(e) {
    let startDateValue = e.target.value;

    const startDate = new Date(startDateValue);
    const endDate = new Date(this.state.endDate);

    const diffDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));

    var yearsError = null;
    var error = null;
    var endError = null;
    if (
      this.state.endDate &&
      startDateValue &&
      startDateValue > this.state.endDate
    ) {
      error = <FormattedMessage id="StartDate.Superior.EndDate" />;
    } else if (
      this.state.endDate &&
      this.state.endDate !== "" &&
      this.state.endDate < this.state.nowDate
    ) {
      endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
    } else if (diffDays >= 730) {
      yearsError = <FormattedMessage id="Two.Years.Error" />;
    }

    this.setState({
      startDate: startDateValue,
      dateError: error,
      endDateError: endError,
      errorYear: yearsError,
    });
  }

  onEndDateChange(e) {
    let endDateValue = e.target.value;

    const endDate = new Date(endDateValue);
    const startDate = new Date(this.state.startDate);

    const diffDays = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));

    var yearsError = null;
    var error = null;
    var endError = null;
    if (
      this.state.startDate &&
      endDateValue &&
      this.state.startDate > endDateValue
    ) {
      error = <FormattedMessage id="StartDate.Superior.EndDate" />;
    } else if (
      endDateValue &&
      endDateValue !== "" &&
      endDateValue < this.state.nowDate
    ) {
      endError = <FormattedMessage id="EndDate.Inferior.NowDate" />;
    } else if (diffDays >= 730) {
      yearsError = <FormattedMessage id="Two.Years.Error" />;
    }

    this.setState({
      endDate: endDateValue,
      dateError: error,
      endDateError: endError,
      errorYear: yearsError,
    });
  }

  onComplete() {
    if (this.props.mercurial) {
      const data = {
        _id: this.props.mercurial._id,
        name: this.state.name,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        status: MercurialStatus.ACTIVE,
      };

      this.props.onUpdateMercurial(data, () => this.props.closeModal());
    } else {
      this.setState({
        disabled: true,
        formVisibility: false,
        progressBarVisibility: true,
      });

      this.props.onComplete(
        this.state.name,
        this.state.file,
        this.state.startDate,
        this.state.endDate,
      );
    }
  }

  disableButton() {
    const hasStartDateError =
      this.state.endDate &&
      this.state.endDate !== "" &&
      this.state.startDate > this.state.endDate
        ? true
        : false;

    const hasEndDateError =
      this.state.endDate &&
      this.state.endDate !== "" &&
      this.state.endDate < this.state.nowDate
        ? true
        : false;

    return (
      (!this.props.mercurial && !this.state.file) ||
      !this.state.name ||
      !this.state.startDate ||
      hasStartDateError ||
      hasEndDateError ||
      this.state.disabled ||
      this.state.nameError ||
      this.state.errorYear
    );
  }

  render() {
    const isUpdate = this.props.mercurial;
    return (
      <ConfirmationModal
        size="lg"
        title={
          <FormattedMessage
            id={isUpdate ? "Mercurial.Update" : "Mercurials.Add.Some"}
          />
        }
        onAccept={() => this.onComplete()}
        confirmButtonLabel={
          !isUpdate ? (
            <FormattedMessage
              id={!this.state.progressBarVisibility ? "Step.2" : "Plz.Wait"}
            />
          ) : (
            <FormattedMessage
              id={
                this.props.mercurial.status === MercurialStatus.ACTIVE
                  ? "Modify"
                  : "Restore"
              }
            />
          )
        }
        confirmButtonDisabled={this.disableButton()}
        onDecline={() => this.close()}
      >
        {!isUpdate && (
          <h4 className="mb-5 w-100 text-center ">
            <FormattedMessage id="Step.1" />
          </h4>
        )}
        {this.state.progressBarVisibility && (
          <div className={"text-center mb-5 "}>
            <Icon icon="gear" className="fa-spin text-success mb-3" size="3x" />
            <div className="progress" style={{ height: "30px" }}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              >
                <strong>
                  <FormattedMessage id="Import.Mercurial.In.Progress" />
                </strong>
              </div>
            </div>
          </div>
        )}
        {this.state.formVisibility && (
          <div>
            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Mercurials.Name.Of",
                })}
                htmlFor="file-name"
                displayAsCol
                required
              />
              <Col>
                <InputTextElement
                  type="text"
                  className="form-control"
                  maxLength={InputLength.NAME}
                  id="file-name"
                  onChange={(e) => this.onNameChange(e)}
                  value={this.state.name}
                />
                <div className="text-danger">
                  <small>{this.state.nameError}</small>
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Start.Date" })}
                htmlFor="file-start-date"
                required
                displayAsCol
              />
              <Col>
                <InputTextElement
                  type="date"
                  id="file-start-date"
                  onChange={(e) => this.onStartDateChange(e)}
                  value={DateUtil.toyyyyMMdd(this.state.startDate)}
                  min={DateUtil.toyyyyMMdd(new Date())}
                />
                <div className="text-danger">
                  <small>{this.state.dateError}</small>
                </div>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "End.Date" })}
                htmlFor="file-end-date"
                help={<FormattedMessage id="Hint.Mercurial.End.Date" />}
                displayAsCol
              />
              <Col>
                <InputTextElement
                  type="date"
                  id="file-end-date"
                  onChange={(e) => this.onEndDateChange(e)}
                  value={DateUtil.toyyyyMMdd(this.state.endDate)}
                  min={DateUtil.toyyyyMMdd(this.state.startDate)}
                />
                <div className="text-danger">
                  <small>{this.state.endDateError}</small>
                </div>
                <div className="text-danger">
                  <small>{this.state.errorYear}</small>
                </div>
              </Col>
            </Form.Group>

            {!isUpdate && (
              <>
                <Form.Group as={Row} className="align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Import.File.Mercurial",
                    })}
                    htmlFor="name"
                    displayAsCol
                    required
                  />
                  <Col>
                    <FileDropZone
                      onDropFile={(file) => this.onDropFile(file)}
                      acceptedExtensions={["xls", "xlsx"]}
                      multiple={false}
                    />
                  </Col>
                </Form.Group>

                <Row className="align-items-center">
                  <Col md={{ span: 6, offset: 6 }} className="text-center mt-3">
                    <a
                      href="templates/template_catalog.xlsx"
                      download="template_catalog.xlsx"
                    >
                      <MenuButton icon="file-excel" variant="outline-primary">
                        <FormattedMessage id="Download.Template" />
                      </MenuButton>
                    </a>
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateMercurial: (data, successCallback) =>
      dispatch(updateMercurial(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MercurialFileModal));
