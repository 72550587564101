import React from "react";
import { connect } from "react-redux";
import StringUtil from "../../util/StringUtil";
import MercurialsUtil from "../../util/mercurialsUtil.js";
import ArrayUtil from "../../util/ArrayUtil";
import ObjectUtil from "../../util/ObjectUtil";
import Maths from "../../util/Maths";
import Util from "../../util/Util";
import ExcelUtil from "../../util/ExcelUtil";
import FileUtil from "../../util/FileUtil";
import CustomLabel from "../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import Icon from "../sub/Icon";
import AlertModal from "../sub/modals/AlertModal";
import { Row, Col, Alert, Badge, FormSelect, Container } from "react-bootstrap";
import SwitchToggle from "../sub/SwitchToggle";
import MenuButton from "../sub/bootstrap/MenuButton";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";
import ConfirmationModal from "../sub/modals/ConfirmationModal";

class MercurialColumnsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: true,
      autoMatching: false,
      simFamilies: [], // Array of families found by string similarity
      missingRequiredColumns: [],
      errorsFound: [],
      formVisibility: true,
      progressBarVisibility: false,
      maxErrorToDisplay: 50, // Define the number of errors (if any) to be displayed in a parsed file
      allowSameSelectValue: true, // If true, value of the same column can be associated to multiple selects
      submitButtonId: "submit-button",
    };
  }

  // Set mercurial mapping fields for current PSDM
  newMercurialsUtil = new MercurialsUtil(this.props.company);
  MercurialsMapping = this.newMercurialsUtil.getMercurialMapping();

  close() {
    this.props.closeModal();
  }

  formatValue(value) {
    let formattedValue = "" + value;
    if (formattedValue.length > 100)
      formattedValue = formattedValue.substring(0, 100) + "...";

    return formattedValue;
  }

  // Detect similarities to avoid duplication
  checkFamilySimilarity() {
    let families = [];

    // Add only distinct family names to families array to improve performances when detecting similarities below
    for (let key of Object.keys(this.props.fileData)) {
      let currentFamilyKey = this.props.fileData[key]["FAMILLE"];

      if (
        Util.typeOf(currentFamilyKey) !== "Undefined" &&
        families.indexOf(currentFamilyKey) === -1 &&
        currentFamilyKey.toString().trim() !== ""
      ) {
        families.push(currentFamilyKey.toString());
      }
    }

    var simFamilies = [];
    for (let f0 of families) {
      inner: for (let f1 of families) {
        if (f0 === f1) continue;

        for (let s of simFamilies) {
          if ((s[0] === f1 && s[1] === f0) || (s[0] === f0 && s[1] === f1)) {
            continue inner;
          }
        }

        if (StringUtil.similarity(f0, f1) > 0.8) {
          simFamilies.push([
            f0.replace(/\s/g, "[*]"),
            f1.replace(/\s/g, "[*]"),
          ]);
        }
      }
    }

    this.setState({ simFamilies: simFamilies });
  }

  // Build selects to match columns in the file
  buildSelects() {
    let selects = [];
    let firstRowKeys = Object.keys(this.props.fileData[0]);
    let hintIcon = (
      <PopoverHelper>
        <FormattedMessage id="Hint.Mapping" />
      </PopoverHelper>
    );

    for (let mappingKey of Object.keys(this.MercurialsMapping)) {
      // Help user and pre-select the closest column (select default value)
      let matchColumn =
        this.state.missingRequiredColumns.indexOf(
          this.MercurialsMapping[mappingKey],
        ) !== -1
          ? false
          : this.MercurialsMapping[mappingKey];

      if (this.state.autoMatching) {
        if (!matchColumn) {
          hintIcon = (
            <PopoverHelper variant="danger">
              <FormattedMessage
                id="Mercurials.Auto.Detect.No.Match"
                values={{ column: this.MercurialsMapping[mappingKey] }}
              />
            </PopoverHelper>
          );
        } else {
          hintIcon = (
            <PopoverHelper variant="success" icon="circle-check">
              <FormattedMessage
                id="Mercurial.Column.Reference.Name"
                values={{ column: this.MercurialsMapping[mappingKey] }}
              />
            </PopoverHelper>
          );
        }
      }

      var optionsNode = firstRowKeys.map((key) => {
        let value = this.props.fileData[0][key];

        let optionElement;

        if (
          !this.state.autoMatching ||
          (this.state.autoMatching && !matchColumn)
        ) {
          // When autoMatching did not found matching column or autoMatching is disabled, we build a select with the whole list of columns in options
          optionElement = (
            <option key={key + "-" + value} value={key}>
              [{key}] : {this.formatValue(value)}
            </option>
          );
        } else {
          // When autoMatching is enabled and worked well (column found) we don't need to display the whole list of columns.
          // Only displaying the matched one in select options
          if (key === this.MercurialsMapping[mappingKey]) {
            optionElement = (
              <option key={key + "-" + value} value={key}>
                {this.formatValue(value)}
              </option>
            );
          }
        }

        return optionElement;
      });

      selects.push(
        <Row className="align-items-center mb-3" key={mappingKey}>
          <Col className="text-nowrap pe-0">
            <CustomLabel
              label={this.MercurialsMapping[mappingKey]}
              htmlFor={mappingKey}
            />
          </Col>
          <Col id={mappingKey} className="pe-0">
            <FormSelect
              key={Math.random()}
              className="column-select"
              defaultValue={
                this.state.autoMatching && matchColumn && matchColumn
              }
              onChange={(e) => this.manageSelectChange(e, mappingKey + "_hint")}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "Select" })}...
              </option>
              {optionsNode}
            </FormSelect>
          </Col>
          <Col className="col-auto ps-0" id={mappingKey + "_hint"}>
            {hintIcon}
          </Col>
        </Row>,
      );
    }

    return selects;
  }

  getSelColumn(key) {
    let famDiv = document.getElementById(key);
    let select = famDiv.firstChild;
    return select.options[select.selectedIndex].value;
  }

  onComplete() {
    // Prevent post to backend if button to next step is disabled
    // (means that some column matching already needs to be done)
    // if (this.state.disabled) return false;

    /*
    this.setState({
      disabled: true,
    });
    */

    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(this.MercurialsMapping)) {
      columns[key] = this.getSelColumn(key);
    }

    /*
    PERFORM INTEGRITY CHECKS UPON DATA BEFORE SENDING MERCURIAL TO THE BACKEND
    */
    if (this.checkDataIntegrity(columns)) {
      this.setState({
        formVisibility: false,
        progressBarVisibility: true,
      });

      this.props.onComplete(columns);
    }
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  checkDataIntegrity(columnsReferenceList) {
    let currentError;
    let minTva = 0;
    let maxTva = 100;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        let arr = this.state.errorsFound;
        arr.push(error);
        this.setState({ errorsFound: arr });
      }
    };

    // Loop through file rows
    for (let row of this.props.fileData) {
      if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * Compare with required keys to find if any field is missing
       */
      let missingFieldsForCurrentRow = ArrayUtil.difference(
        Object.values(columnsReferenceList),
        currentRowKeys,
      );

      let unusedFieldsForCurrentRow = ArrayUtil.difference(
        currentRowKeys,
        Object.values(columnsReferenceList),
      );

      // If some empty or missing fields are found in the file, we add them to the stack trace
      if (missingFieldsForCurrentRow.length > 0) {
        for (let missingField of missingFieldsForCurrentRow) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: missingField,
            targetField:
              this.MercurialsMapping[
                ObjectUtil.getKeyByValue(columnsReferenceList, missingField)
              ],
            value: "",
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
        }
      }

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (unusedFieldsForCurrentRow.includes(key)) {
          break;
        }

        if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
          break;
        }

        let MercurialsMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key,
        );
        // Convert field to string to evaluate it
        // let fieldValue = row[key].toString();

        switch (true) {
          // Perfom tests on numeric fields
          case MercurialsMappingReferenceKey === "tva":
            if (!Maths.isInt(row[key]) && !Maths.isFloat(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            } else {
              switch (true) {
                case row[key] < minTva || row[key] > maxTva:
                  currentError = {
                    numRow: row.__rowNum__ + 1,
                    field: key,
                    targetField:
                      this.MercurialsMapping[MercurialsMappingReferenceKey],
                    value: row[key],
                    hint: (
                      <FormattedMessage
                        id="Value.Must.Be.Between"
                        values={{ value: row[key], min: minTva, max: maxTva }}
                      />
                    ),
                  };
                  addError(currentError);
                  break;
                case row[key] >= 1:
                  this.props.fileData[row.__rowNum__ - 1][key] = row[key] / 100;
                  break;
                default:
                  break;
              }
            }

            break;

          case MercurialsMappingReferenceKey === "prix_u_ht_emera":
            if (!Maths.isInt(row[key]) && !Maths.isFloat(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case MercurialsMappingReferenceKey === "prix_achat_ht":
            if (
              !Util.emptyString(row[key].toString()) &&
              isNaN(parseInt(row[key])) &&
              isNaN(parseFloat(row[key]))
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case MercurialsMappingReferenceKey === "min_cde":
            if (!Maths.isInt(row[key])) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            } else if (row[key] < 1) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Value.Less.Than.1"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case MercurialsMappingReferenceKey === "lpp_amount":
            if (
              Util.emptyString(row[key].toString()) &&
              !Util.emptyString(row[this.MercurialsMapping["lpp_code"]])
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }

            if (
              !Util.emptyString(row[key].toString()) &&
              !Util.emptyString(row[this.MercurialsMapping["lpp_code"]]) &&
              isNaN(parseInt(row[key])) &&
              isNaN(parseFloat(row[key]))
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: "",
                hint: (
                  <FormattedMessage
                    id="Value.Is.Not.Int.Or.Float"
                    values={{ value: row[key] }}
                  />
                ),
              };

              addError(currentError);
            }
            break;

          case MercurialsMappingReferenceKey === "caracteristiques":
          case MercurialsMappingReferenceKey === "lpp_code":
          case MercurialsMappingReferenceKey === "ref_product_desk":
          case MercurialsMappingReferenceKey === "prix_limite_ttc":
            // Allow required field to be empty
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField:
                  this.MercurialsMapping[MercurialsMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    // If some errors have been detected on fields values while parsing file (and after column matching), we abort the import process
    // And we display a list of found errors
    if (this.state.errorsFound.length > 0) {
      return this.openDataIntegrityModal(
        this.state.errorsFound,
        columnsReferenceList,
      );
    }

    return true;
  }

  // When an error occurs, allow download of generated import file
  downloadGeneratedImportFile(columns) {
    let products = this.props.fileData;
    let newProducts = [];

    for (let p of products) {
      var newProduct = {};
      let colValue;

      for (let col of Object.keys(columns)) {
        colValue =
          Util.typeOf(p[columns[col]]) !== "Undefined" ? p[columns[col]] : "";
        newProduct[col] = colValue.toString().trim() !== "" ? colValue : "";
      }

      newProducts.push(newProduct);
    }

    // Convert data to Excel format
    let excelData = ExcelUtil.toExcel(newProducts, this.MercurialsMapping, [
      "_id",
      "__v",
      "mercurial_id",
    ]);

    // Sanitize the file name
    let fileName = FileUtil.toFileName("export");

    // Save the file
    ExcelUtil.save(excelData, fileName);
  }

  openDataIntegrityModal(dataProblems, columns) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <Alert variant="danger">
          <div>
            <FormattedMessage id="File.Missing.Data" />
          </div>
          {Object.values(dataProblems).length ===
            this.state.maxErrorToDisplay && (
            <div>
              <FormattedMessage
                id="File.Error.Count"
                values={{ count: Object.values(dataProblems).length }}
              />
            </div>
          )}
          <div className="text-center">
            <MenuButton
              icon="download"
              variant="danger"
              onClick={() => {
                this.downloadGeneratedImportFile(columns);
              }}
            >
              <FormattedMessage id="Export.Mercurial" />
            </MenuButton>
          </div>
        </Alert>
        <table className="table table-striped tablee4mad">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col-1">
                <FormattedMessage id="Line" />
              </th>
              <th scope="col" className="col-3">
                <FormattedMessage id="Column.In.File" />
              </th>
              <th scope="col" className="col-3">
                <FormattedMessage id="Target.Field" />
              </th>
              <th scope="col" className="col-5">
                <FormattedMessage id="Hint" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProblems.map((row, index) => {
              return (
                <tr key={index} className="d-flex">
                  <td className="col-1">{row.numRow}</td>
                  <td className="col-3">{row.field}</td>
                  <td className="col-3">{row.targetField}</td>
                  <td className="col-5">{row.hint}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    this.setState({
      modal: (
        <AlertModal
          title={errorModalTitle}
          content={errorModalContent}
          variant="danger"
          size="xl"
          onClose={() => this.close()}
          buttonLabel={<FormattedMessage id="Cancel" />}
        />
      ),
    });
  }

  manageSelectChange(e, selectHint) {
    let hintElement = document.getElementById(selectHint);

    if (e.target.value !== "") {
      hintElement.classList.remove("text-danger");
      hintElement.classList.add("text-success");
    } else {
      hintElement.classList.add("text-danger");
      hintElement.classList.remove("text-success");
    }
    this.updateSelectOptions();
  }

  componentDidMount() {
    this.updateSelectOptions();
  }

  updateSelectOptions() {
    const selects = document.getElementsByClassName("column-select");
    const submitButton = document.getElementById(this.state.submitButtonId);
    let error = false;

    // if allowSameSelectValue is true in state (default) we allow the user to choose the same select multiple times for different values (ex: client ref and manufacturer ref linked to the same col in file)
    if (!this.state.allowSameSelectValue) {
      for (let s of selects) {
        for (let i = 0; i < s.length; i++) {
          s.options[i].disabled = false;
        }
      }

      for (let s1 of selects) {
        var value1 = s1.value;

        for (let s2 of selects) {
          if (s1 === s2) continue;

          for (let i = 0; i < s2.length; i++) {
            if (s2.options[i].value !== "" && s2.options[i].value === value1)
              s2.options[i].disabled = true;
          }
        }
      }
    } else {
      for (let s of selects) {
        for (let i = 0; i < s.length; i++) {
          if (s.value === "") {
            error = true;
            continue;
          }
        }
      }
    }
    submitButton.disabled = error;
  }

  updateAutoMatching() {
    if (!this.state.autoMatching) {
      // When automatching is enabled, we check if all the required columns are found in the provided file
      let firstRowKeys = Object.keys(this.props.fileData[0]);
      this.setState({
        missingRequiredColumns: ArrayUtil.difference(
          Object.values(this.MercurialsMapping),
          firstRowKeys,
        ),
      });

      // Also, enable family similarities detection only when automatching is set to true (still set to false when updateAutoMatching() is called)
      this.checkFamilySimilarity();
    } else {
      // We reset the columns to check if automatching is disabled
      this.setState({ missingRequiredColumns: [] });
      // We reset the families similarity detection too
      this.setState({ simFamilies: [] });
    }

    this.setState(
      { autoMatching: !this.state.autoMatching },
      this.updateSelectOptions,
    );
  }

  render() {
    // Prepare as many selects as required for the mapping
    let selects = this.buildSelects();

    // Split the selects on 2 displayed columns
    let selects1stHalf = [];
    let selects2ndHalf = [];
    for (let i = 0; i < selects.length; i++) {
      if (i <= selects.length / 2) selects1stHalf.push(selects[i]);
      else selects2ndHalf.push(selects[i]);
    }

    return (
      <>
        <ConfirmationModal
          size="xl"
          title={
            <FormattedMessage
              id={
                this.props.mode === "update"
                  ? "Mercurials.Update"
                  : "Mercurials.Add.Some"
              }
            />
          }
          onAccept={() => this.onComplete()}
          confirmButtonId={this.state.submitButtonId}
          confirmButtonLabel={
            <FormattedMessage
              id={this.props.mode === "update" ? "Update" : "Step.3"}
            />
          }
          onDecline={() => this.close()}
        >
          <h4 className="w-100 text-center">
            <FormattedMessage id="Mercurials.Step2.Desc" />
          </h4>

          {this.state.progressBarVisibility && (
            <div className="text-center mb-5 ">
              <Icon
                icon="gear"
                className="fa-spin text-success mb-3"
                size="3x"
              />
              <div className="progress" style={{ height: "30px" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                  role="progressbar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: "100%" }}
                >
                  <strong>
                    <FormattedMessage id="Import.Save.Data" />
                  </strong>
                </div>
              </div>
            </div>
          )}

          {this.state.formVisibility && (
            <Container>
              <h5 className="mb-4 w-100 text-center font-weight-light">
                <FormattedMessage id="Mercurials.Step2.Read.1st.Line" />
              </h5>
              <Alert variant="warning" className="pt-1 pb-0">
                <Col md={12} className="d-flex justify-content-center">
                  <Row>
                    <Col className="text-end pe-0">
                      <FormattedMessage id="File.Auto.Detect.Columns" />
                    </Col>
                    <Col sm={1}>
                      <SwitchToggle
                        id="auto-matching"
                        onChange={() => this.updateAutoMatching()}
                        checked={this.state.autoMatching}
                      />
                    </Col>
                  </Row>
                </Col>
              </Alert>
              <Row>
                <Col md={6}>{selects1stHalf}</Col>
                <Col md={6}>{selects2ndHalf}</Col>
              </Row>
              <Row>
                <Col>
                  {this.state.simFamilies.length > 0 && (
                    <Alert variant="danger">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <Icon icon="triangle-exclamation" size="3x" />
                        </Col>
                        <Col>
                          <FormattedMessage id="Mercurials.Families.Similarities" />
                          {" :"}
                          <ul className="pb-0 mb-2">
                            {this.state.simFamilies.map((family, index) => (
                              <li key={index}>
                                <Badge bg="success">{family[0]}</Badge>/{" "}
                                <Badge bg="danger">{family[1]}</Badge>
                              </li>
                            ))}
                          </ul>
                          <FormattedMessage id="Mercurials.Families.Excel.Fix" />
                        </Col>
                      </Row>
                    </Alert>
                  )}
                  {this.state.missingRequiredColumns.length > 0 && (
                    <Alert variant="danger">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <Icon icon="triangle-exclamation" size="3x" />
                        </Col>
                        <Col>
                          <FormattedMessage id="File.Auto.Detect.Columns.No.Match.1" />
                          <ul className="pb-0 mb-2">
                            {this.state.missingRequiredColumns.map(
                              (requiredColumn, index) => (
                                <li key={index}>
                                  <Badge bg="danger">{requiredColumn}</Badge>
                                </li>
                              ),
                            )}
                          </ul>
                          <FormattedMessage id="File.Auto.Detect.Columns.No.Match.2" />
                        </Col>
                      </Row>
                    </Alert>
                  )}
                </Col>
              </Row>
            </Container>
          )}
        </ConfirmationModal>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

export default connect(mapStateToProps)(injectIntl(MercurialColumnsModal));
