import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientDocument,
  addPatientDocumentFile,
} from "../../actions/patients/patients";
import { Tab, Tabs } from "react-bootstrap";
import { injectIntl } from "react-intl";
import PatientTechnicalSheets from "./PatientTechnicalSheets";
import PatientEstimates from "./PatientEstimates";
import PatientBills from "./PatientBills";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";
import PatientDocumentTypes from "../../enums/PatientDocumentTypes";

class PatientDocuments extends React.Component {
  render() {
    const {
      patient,
      user,
      intl,
      onUpdPatientAdmin,
      onAddPatientDocumentFile,
      onDeletePatientDocument,
    } = this.props;

    return (
      <Tabs id="patientsTabs" mountOnEnter>
        <Tab
          eventKey="tech-sheets"
          title={intl.formatMessage({ id: "Tech.Sheets" })}
        >
          <PatientTechnicalSheets patient={patient} />
        </Tab>

        <Tab
          eventKey="estimates"
          title={intl.formatMessage({ id: "Estimates" })}
        >
          <PatientEstimates patient={patient} />
        </Tab>

        <Tab eventKey="bills" title={intl.formatMessage({ id: "Bills" })}>
          <PatientBills patient={patient} />
        </Tab>

        <Tab eventKey="documents" title={intl.formatMessage({ id: "Various" })}>
          {patient && (
            <Document
              data={patient}
              documentType={PatientDocumentTypes.DOCUMENTS}
              addDataDocument={(
                patientId,
                documentType,
                file,
                successCallback,
              ) =>
                onAddPatientDocumentFile(
                  patientId,
                  documentType,
                  file,
                  successCallback,
                )
              }
              updateData={(patient, successCallback) =>
                onUpdPatientAdmin(patient, successCallback)
              }
              deleteDocument={(
                patientId,
                documentType,
                sheetId,
                successCallback,
              ) =>
                onDeletePatientDocument(
                  patientId,
                  documentType,
                  sheetId,
                  successCallback,
                )
              }
              messageId={"Patient.Document.Add"}
              adminEmptyMessageId={"Empty.Patient.Document"}
              clientEmptyMessageId={"Client.Empty.Patient.Document"}
              user={user}
              url={APIUrl.getPatientDocumentFile}
            />
          )}
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPatientDocumentFile: (
      patientId,
      documentType,
      file,
      successCallback,
    ) =>
      dispatch(
        addPatientDocumentFile(patientId, documentType, file, successCallback),
      ),
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onDeletePatientDocument: (
      patientId,
      documentType,
      documentId,
      successCallback,
    ) =>
      dispatch(
        deletePatientDocument(
          patientId,
          documentType,
          documentId,
          successCallback,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientDocuments));
