import React from "react";
import { FormattedMessage } from "react-intl";

const Footer = ({ generalSettings }) => {
  // Destructuring des valeurs JSON
  const {
    commercial_sign,
    contact_information,
    Siret,
    phone,
    email,
    website,
    vatNumber,
  } = generalSettings;

  return (
    <div className="footer">
      <div className="hr"></div>
      <div>
        {commercial_sign}
        {contact_information && <> - {contact_information}</>}
        {Siret && (
          <>
            {" "}
            - <FormattedMessage id="SIRET" /> : {Siret}
          </>
        )}
        <br />
        {phone && (
          <>
            <FormattedMessage id="Phone" /> : {phone}
          </>
        )}
        {email && (
          <>
            {" "}
            - <FormattedMessage id="Email" /> : {email}
          </>
        )}
        {website && (
          <>
            {" "}
            - <FormattedMessage id="Website" /> : {website}
          </>
        )}
        {vatNumber && (
          <>
            {" "}
            - <FormattedMessage id="VAT" /> : {vatNumber}
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
