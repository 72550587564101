import { GET_COORDINATION } from "../../actions/coordination/coordination";

export default function coordination(state = [], action) {
  switch (action.type) {
    case GET_COORDINATION:
      return action.coordination;
    default:
      return state;
  }
}
