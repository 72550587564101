import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../../util/DateUtil";
import APIUrl from "../../../APIUrl";
import { FormattedMessage, injectIntl } from "react-intl";
import StringUtil from "../../../util/StringUtil";
import Footer from "../../template/Footer";
import Util from "../../../util/Util";

class DeskTemplatePDF extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      prescriber: "",
      helper: "",
      user: "",
    };
  }

  render() {
    const {
      user,
      id,
      helper_id,
      auditSettings,
      prescriber_id,
      patient,
      patient_analysis,
      prescribers,
      helpers,
      deskStore,
      deskProducts,
    } = this.props;

    const prepare_analysis = () => {
      const age = DateUtil.calculateAge(patient.birth_date);
      let gender_and_age_info = "";
      let height_info = "";
      let weight_info = "";
      let patient_name = "";
      let shoesize = "";

      switch (patient_analysis.gender) {
        // Female
        case "2":
          gender_and_age_info = (
            <>
              <strong>
                <FormattedMessage id="A.Woman" />
              </strong>{" "}
              <FormattedMessage id="Old.Female" />{" "}
              <strong>
                {age} {this.props.intl.formatMessage({ id: "Years" })}
              </strong>
            </>
          );
          break;
        // Male
        case "1":
          gender_and_age_info = (
            <>
              <strong>
                <FormattedMessage id="A.Man" />
              </strong>{" "}
              <FormattedMessage id="Old.Male" />{" "}
              <strong>
                {age} {this.props.intl.formatMessage({ id: "Years" })}
              </strong>
            </>
          );
          break;
        // Default
        default:
          gender_and_age_info = (
            <>
              <FormattedMessage id="Old.Male" />{" "}
              <strong>
                {age} {this.props.intl.formatMessage({ id: "Years" })}
              </strong>
            </>
          );
          break;
      }

      height_info =
        patient_analysis.height !== 0 ? (
          <FormattedMessage
            id="And.Measuring"
            values={{
              height: (
                <strong>
                  {StringUtil.convertHeight(patient_analysis.height)}
                </strong>
              ),
            }}
          />
        ) : (
          ""
        );

      weight_info =
        patient_analysis.weight !== 0 ? (
          <FormattedMessage
            id="And.Weight"
            values={{
              weight: (
                <strong>
                  {patient_analysis.weight}{" "}
                  {this.props.intl.formatMessage({ id: "Kg" })}
                </strong>
              ),
            }}
          />
        ) : (
          ""
        );

      shoesize =
        patient_analysis.shoesize !== 0 ? (
          <FormattedMessage
            id="And.Shoe"
            values={{ shoesize: <strong>{patient_analysis.shoesize}</strong> }}
          />
        ) : (
          ""
        );

      patient_name = (
        <>{Util.formatFullName(patient.first_name, patient.name)}</>
      );

      let detailed_analysis_list = "";
      if (
        patient_analysis.incontinence !== "" ||
        patient_analysis.bedsores !== "" ||
        patient_analysis.homecare_workers !== "" ||
        patient_analysis.home_accessibility !== ""
      ) {
        let incontinence_sentence = "";
        let bedsores_sentence = "";
        let homecare_workers_sentence = "";

        incontinence_sentence =
          patient_analysis.incontinence !== "" ? (
            <li>
              <FormattedMessage
                id={
                  "Urinary.Incontinence.Sentence." +
                  patient_analysis.incontinence
                }
              />
            </li>
          ) : (
            ""
          );
        bedsores_sentence =
          patient_analysis.bedsores !== "" ? (
            <li>
              <FormattedMessage
                id={"Bedsore.Risk.Sentence." + patient_analysis.bedsores}
              />
            </li>
          ) : (
            ""
          );
        homecare_workers_sentence =
          patient_analysis.homecare_workers !== "" ? (
            <li>
              <FormattedMessage
                id={
                  "Homecare.Workers.Sentence." +
                  patient_analysis.homecare_workers
                }
              />
            </li>
          ) : (
            ""
          );
        detailed_analysis_list = (
          <p>
            <FormattedMessage id="Patient.Analysis.Sentence.2" />
            <ul>
              {incontinence_sentence}
              {bedsores_sentence}
              {homecare_workers_sentence}
            </ul>
          </p>
        );
      }

      return (
        <>
          <div className="analysis">
            <h4>
              <FormattedMessage id="Patient.About" />
            </h4>
            <p>
              <FormattedMessage
                id="Patient.Analysis.Sentence.1"
                values={{
                  gender_and_age: gender_and_age_info,
                  height: height_info,
                  weight: weight_info,
                  shoesize: shoesize,
                }}
              />
            </p>
            {detailed_analysis_list}
            <p>
              <FormattedMessage
                id="Patient.Analysis.Sentence.3"
                values={{ patient_name: patient_name }}
              />
            </p>
          </div>
        </>
      );
    };

    var helper = helpers.find((h) => h._id === helper_id);
    var prescriber = prescribers.find((p) => p._id === prescriber_id);

    var imgError = false;
    // Add / Reformat DATAS product info + relatedProducts

    var products = deskStore.map((ele, idx) => {
      ele.info = deskProducts.find((p) => {
        return p._id === ele.product_id;
      });
      ele.relatedProducts = [];
      if (
        ele.info &&
        ele.info.relatedProducts &&
        ele.info.relatedProducts.length
      ) {
        for (let i in ele.info.relatedProducts) {
          ele.relatedProducts.push(
            deskProducts.find((p) => {
              return p._id === ele.info.relatedProducts[i];
            }),
          );
        }
      }

      return ele;
    });

    // ELEMENTS / PRODUCTS TO RENDER

    let elementsNodes;

    if (products && products.length > 0) {
      let pIndex = 0;

      elementsNodes = products.map((ele, idx) => {
        var imgError = false;
        var imgSrc =
          APIUrl.getDeskProductImg +
          ele.product_id +
          "?token=" +
          APIUrl.jwtToken;

        var imgProduct = (
          <img
            src={imgSrc}
            alt={"desk_product_img" + Math.random()}
            onError={(e) => {
              if (imgError) return;
              e.target.onerror = null;
              imgError = true;
              e.target.src = "/images/placeholder_120x120.png";
            }}
          />
        );

        let logotrefundable = "";
        if (ele.info.refundable === true) {
          logotrefundable = <div className="pictogram avis_medical"></div>;
        }

        if (ele.vte_loc === "Location") {
          var codeline = <div className="pictogram location"></div>;
        } else {
          codeline = <div className="pictogram vente"></div>;
        }

        if (pIndex === 0) {
          pIndex++;
          return (
            <div key={idx} className="main_product">
              <div className="title">
                <FormattedMessage id="Selection.Mandatory" /> : {ele.info.name}
              </div>
              <div style={{ padding: "3mm" }}>
                <div className="image">
                  {logotrefundable}
                  {codeline}
                  {imgProduct}
                </div>
                <div className="description">
                  {ele.info.description_text}
                  {ele.comment && ele.comment !== "" && (
                    <div className="comment">
                      <strong>
                        <FormattedMessage id="Comment" /> :{" "}
                      </strong>
                      {ele.comment}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="product" key={"pdfproduct" + ele.product_id}>
              <div className="title">{ele.info.name}</div>
              <div className="image">
                {logotrefundable}
                {codeline}
                {imgProduct}
              </div>
              <div className="description">
                {ele.info.description_text}
                {ele.comment && ele.comment !== "" && (
                  <div className="comment">
                    <strong>
                      <FormattedMessage id="Comment" /> :{" "}
                    </strong>
                    {ele.comment}
                  </div>
                )}
              </div>
            </div>
          );
        }
      });
    }

    let mainProduct = elementsNodes;
    let otherProducts;

    if (elementsNodes && elementsNodes.length > 1) {
      mainProduct = elementsNodes[0];
      otherProducts = elementsNodes.slice(1);
      otherProducts = (
        <div className="related_products">
          <h2 className="related_title">
            <FormattedMessage id="Other.Recommended.Products" />
          </h2>
          {otherProducts}
        </div>
      );
    }

    // RENDER PDF TEMPLATE
    return (
      <div id={id}>
        <div id="header">
          <table>
            <tbody>
              <tr>
                <td id="pharma_logo_td">
                  {this.props.generalSettings.logo && (
                    <img
                      id="pharma_logo"
                      src={`${APIUrl.getGeneralSettingsLogo}${this.props.user.company_id}/${this.props.generalSettings._id}`}
                      alt="logo client"
                      onError={(e) => {
                        if (imgError) return;
                        e.target.onerror = null;
                        imgError = true;
                        e.target.src = "/images/placeholder_120x120.png";
                      }}
                    />
                  )}
                  <div className="titulaire">
                    <p>
                      <FormattedMessage id="Date" /> :{" "}
                      {DateUtil.toDate(Date.now())}
                      <br />
                      <FormattedMessage id="Audit.Made.By" /> :{" "}
                      {Util.formatFullName(user.first_name, user.name)}
                    </p>
                  </div>
                </td>
                <td id="app_logo_td">
                  <img
                    id="app_logo"
                    src={APIUrl.rootUrl + "/img/pharmamad_logo.svg"}
                    alt=""
                  />
                  <div id="audit_infos">
                    <p>
                      <FormattedMessage id="Patient" /> :{" "}
                      {Util.formatFullName(patient.first_name, patient.name)}
                      <br />
                      {patient.address}
                      <br />
                      {patient.postal_code || ""} {patient.city || ""}
                    </p>
                    {helper?.name && (
                      <p>
                        <FormattedMessage id="Helper" /> :{" "}
                        {Util.formatFullName(helper.first_name, helper.name)}
                        <br />
                      </p>
                    )}
                    {prescriber?.name && (
                      <p>
                        <FormattedMessage id="Prescriber" /> :{" "}
                        {Util.formatFullName(
                          prescriber.first_name,
                          prescriber.name,
                        )}
                        <br />
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="no-print">
          <img src={APIUrl.rootUrl + "/img/medical_advice.svg"} alt="" />
          <img src={APIUrl.rootUrl + "/img/picto_location.svg"} alt="" />
          <img src={APIUrl.rootUrl + "/img/picto_vente.svg"} alt="" />
        </div>

        {auditSettings.title ? (
          <h1 id="audit_title">{auditSettings.title.toUpperCase()}</h1>
        ) : (
          ""
        )}

        {prepare_analysis()}

        <div id="warning">{auditSettings.rationale}</div>
        {mainProduct}
        {otherProducts}

        <Footer generalSettings={this.props.generalSettings} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskTemplatePDF));
