import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import Maths from "../../util/Maths";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import PriceUtil from "../../util/PriceUtil";

import { Alert, Col, Container, Row } from "react-bootstrap";

class OverTimeBudget extends React.Component {
  sortMap(map) {
    return Object.keys(map)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .reduce(
        (_sortedObj, key) => ({
          ..._sortedObj,
          [key]: map[key],
        }),
        {},
      );
  }

  groupByMonth(ordersData) {
    let newMap = {};

    for (let id of Object.keys(ordersData)) {
      var toFirstDayOfMonth = new Date(
        new Date(ordersData[id].date).getFullYear(),
        new Date(ordersData[id].date).getMonth(),
        1,
      );
      if (!newMap[toFirstDayOfMonth]) newMap[toFirstDayOfMonth] = 0;
      newMap[toFirstDayOfMonth] = Maths.round(
        newMap[toFirstDayOfMonth] + ordersData[id].amount,
      );
    }

    return newMap;
  }

  buildChartData(orders) {
    let ordersData = {};
    for (let id of Object.keys(orders)) {
      switch (this.props.graphData) {
        case "totalHT":
          ordersData[id] = {
            date: orders[id].date,
            amount: orders[id].totalHt,
          };
          break;
        case "totalTTC":
          ordersData[id] = {
            date: orders[id].date,
            amount: orders[id].totalTtc,
          };
          break;
        default:
          break;
      }
    }

    // Group by month...
    ordersData = this.groupByMonth(ordersData);
    // And sort
    ordersData = this.sortMap(ordersData);

    let data = {
      labels: [],
      datasets: [
        {
          label: this.props.intl.formatMessage({ id: "Expenses" }),
          fill: false,
          lineTension: 0,
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],

          borderColor: "rgba(75,192,192,1)",
          borderWidth: 3,
          borderDashOffset: 0,
          borderJoinStyle: "miter",
          pointBorderWidth: 1,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
        },
        {
          label: this.props.intl.formatMessage({ id: "Budget" }),
          fill: false,
          lineTension: 0,
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],

          borderColor: "red",
          borderWidth: 3,
          borderDashOffset: 0,
          borderJoinStyle: "miter",
          pointBorderWidth: 1,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
        },
      ],
    };

    for (let date of Object.keys(ordersData)) {
      // Label
      data.labels.push(DateUtil.toyyyyMMdd(date));

      // Data
      data.datasets[0].data.push(ordersData[date]);
    }

    return data;
  }

  render() {
    if (!this.props.orders) return null;

    // Gather up all orders from targeted dates
    let orders = {};
    for (let order of this.props.orders) {
      // Careful with start & end dates
      if (
        new Date(order.creation_date) < new Date(this.props.startDate) ||
        new Date(order.creation_date) > new Date(this.props.endDate)
      ) {
        continue;
      }

      orders[order._id] = {
        date: order.creation_date,
        totalHt: order.total_ht + order.shipping_costs + order.urgent_costs,
        totalTtc:
          order.total_ttc +
          order.shipping_costs * 1.2 +
          order.urgent_costs * 1.2,
      };
    }

    let userLang = this.props.lang;

    let options = {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: [
          {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                const month = new Date(value).toLocaleString(userLang, {
                  month: "long",
                });
                return month.charAt(0).toUpperCase() + month.slice(1);
              },
            },
          },
        ],
        y: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    if (
      this.props.graphData === "totalHT" ||
      this.props.graphData === "totalTTC"
    ) {
      options.tooltips = {
        enabled: true,
        mode: "index",
        callbacks: {
          label: (tooltipItems, data) => {
            let value = tooltipItems.yLabel;
            if (!value) value = data.datasets[0].data[tooltipItems.index];

            if (!tooltipItems.label)
              return (
                " " +
                data.labels[tooltipItems.index] +
                " : " +
                PriceUtil.formatEuro(value)
              );
            return (
              " " +
              data.datasets[tooltipItems.datasetIndex].label +
              ": " +
              PriceUtil.formatEuro(value)
            );
          },
        },
      };
    }

    return (
      <Container fluid>
        {Object.keys(orders).length === 0 ? (
          <Row>
            <Col xs={12} md={8}>
              <Alert variant="secondary">
                <FormattedMessage id="Empty.Stats.Orders" />
              </Alert>
            </Col>
          </Row>
        ) : (
          <Row className="pt-3 justify-content-center">
            <Col xs={12} md={6} className="text-center">
              <Line
                key={Math.random()}
                options={options}
                data={this.buildChartData(orders)}
              />
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OverTimeBudget));
