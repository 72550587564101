import APIUrl from "../../../../APIUrl";
import axios from "axios";
//import { getAuditDMs } from '../../auditDM/admin/auditDM';

export const GET_DESK_CATEGORIES = "GET_DESK_CATEGORIES";

function getDeskCategoriesAction(deskCategories) {
  return { type: GET_DESK_CATEGORIES, deskCategories: deskCategories };
}

export const getDeskCategories = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskCategories)
      .then(function (response) {
        dispatch(getDeskCategoriesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getDeskCategory = function (name, successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getDeskCategory + name)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskCategory = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskCategory, data)
      .then(function (response) {
        dispatch(getDeskCategories());
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDeskCategoryFile = function (
  categoryId,
  data,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addDeskCategoryFile + categoryId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function () {
        dispatch(getDeskCategories());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateDeskCategory = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateDeskCategory, data)
      .then(function () {
        dispatch(getDeskCategories());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDeskCategory = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDeskCategory, data)
      .then(function () {
        dispatch(getDeskCategories());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sortCategory = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sortCategory, data)
      .then(function (response) {
        dispatch(getDeskCategories(() => successCallback()));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
