import axios from "axios";
import ApiURL from "../APIUrl";
import Util from "./Util";

export default class PDFUtil {
  static getFooterTxt(generalSettings, company) {
    let footerTxt = "";

    if (generalSettings.commercial_sign) {
      footerTxt += generalSettings.commercial_sign;
    } else footerTxt += company.name;
    if (generalSettings.contact_information)
      footerTxt += " - " + generalSettings.contact_information;
    if (generalSettings.Siret)
      footerTxt += " - SIRET : " + generalSettings.Siret;
    footerTxt +=
      '</p><p style="color: black; font-style: italic; font-size: 12px; line-height: 0px; margin-top: 20px; width: 100%;">';
    if (generalSettings.phone) footerTxt += " Tel : " + generalSettings.phone;
    if (generalSettings.email) footerTxt += " - " + generalSettings.email;
    if (generalSettings.website)
      footerTxt += " - Site : " + generalSettings.website;
    if (generalSettings.vatNumber)
      footerTxt += " - TVA : " + generalSettings.vatNumber;

    return footerTxt;
  }

  static toPDFWithPuppeteer(
    pdfComponentId, // HTML tag where the generated PDF is stored on the front side
    fileName, // Filename of the PDF
    cssFile, // File name of a css stylesheet stored on the backend
    successCallback,
    option,
    download = false,
  ) {
    // Prepare form data
    let data = {
      filename: fileName,
      html: document.getElementById(pdfComponentId).innerHTML,
      css: cssFile,
    };

    var sendToBE = function () {
      var url = "";

      if (!Util.emptyObject(option)) {
        url = option.url ? ApiURL[option.url] : url;
        data.patient_id = option.patientId;
        data.file_name = fileName;
      }

      axios
        .post(url, data, { responseType: "arraybuffer" })
        .then(function (result) {
          if (download) {
            // Save received data into a PDF file
            let pdf = new Blob([result.data], { type: "application/pdf" });

            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(pdf);
            link.download = fileName + ".pdf";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          // Success callback
          if (successCallback) successCallback();
        })
        .catch(function (error) {
          throw error;
        });
    };

    sendToBE();
  }
}
