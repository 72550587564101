import React from "react";

const Fieldset = ({ children }) => {
  return (
    <fieldset className="border border-secondary rounded-3 p-3 mt-3 mb-3">
      {children}
    </fieldset>
  );
};

export default Fieldset;
