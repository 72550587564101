import React from "react";

const Legend = ({ children }) => {
  return (
    <legend className="float-none w-auto px-2">
      <strong>{children}</strong>
    </legend>
  );
};

export default Legend;
