import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Form, FormSelect, Row, Tab, Tabs } from "react-bootstrap";

import Roles from "../../enums/Roles";

import ProductsBudget from "./ProductsBudget";
import OverTimeBudget from "./OverTimeBudget";
import TopProductsBudget from "./TopProductsBudget";
import Icon from "../sub/Icon.js";
import Select from "react-select";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import CustomLabel from "../sub/CustomLabel";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import MenuButton from "../sub/bootstrap/MenuButton";
import InputTextElement from "../sub/InputTextElement";

class Budget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientId: null,
      selectedClient: null,
      selectedTab: "ProductsBudget",
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
      graphData: "totalHT",
      graphType: "pie",
    };
  }

  currentMonth() {
    let date = new Date();
    this.setState({
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: Date.now(),
    });
  }

  currentYear() {
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
    });
  }

  getClient(clientId) {
    for (let client of this.props.clients) {
      if (client._id === clientId) return client;
    }
  }

  getOrders(clientId) {
    let orders = [];
    for (let order of this.props.orders) {
      let client = this.getClient(order.id_client);
      if (!client) continue;
      if (client._id === clientId) {
        orders.push(order);
      }
    }
    return orders;
  }

  getSelectOptions() {
    const options = [];
    for (const c of this.props.clients) {
      options.push({
        value: c._id,
        label: Util.formatFullName(c.first_name, c.name),
      });
    }
    return options;
  }

  render() {
    const { user, intl } = this.props;
    const isClient = user.role === Roles.CLIENT;
    const orders = isClient
      ? this.props.orders
      : this.getOrders(this.state.clientId);

    return (
      <React.Fragment>
        {!isClient && (
          <TableToolbar>
            <Row className="w-100 align-items-center">
              <Col className="text-end">
                <Icon icon="user" className="me-2" />
              </Col>
              <Col md={7}>
                <Select
                  className="text-start w-50"
                  placeholder={<FormattedMessage id={"Select.Client"} />}
                  options={this.getSelectOptions()}
                  isClearable
                  noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                  onChange={(option) =>
                    this.setState({
                      selectedClient: option,
                      clientId: option && option.value,
                    })
                  }
                  value={this.state.selectedClient}
                />
              </Col>
            </Row>
          </TableToolbar>
        )}

        {((!isClient && this.state.clientId) || isClient) && (
          <>
            <TableToolbar>
              <Form.Group as={Row}>
                <Col className="col-auto">
                  <div className="d-grid gap-2">
                    <MenuButton
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => this.currentYear()}
                    >
                      <FormattedMessage id="Current.Year" />
                    </MenuButton>
                    <MenuButton
                      size="sm"
                      variant="outline-secondary"
                      onClick={() => this.currentMonth()}
                    >
                      <FormattedMessage id="Current.Month" />
                    </MenuButton>
                  </div>
                </Col>
                <Col>
                  <Form.Group>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Start.Date",
                      })}
                      htmlFor="startDate"
                    />
                    <InputTextElement
                      id="startDate"
                      type="date"
                      onChange={(e) => {
                        this.setState({ startDate: e.target.value });
                      }}
                      value={DateUtil.toyyyyMMdd(this.state.startDate)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "End.Date" })}
                      htmlFor="endDate"
                    />
                    <InputTextElement
                      id="endDate"
                      type="date"
                      onChange={(e) => {
                        this.setState({ endDate: e.target.value });
                      }}
                      value={DateUtil.toyyyyMMdd(this.state.endDate)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Indicator" })}
                      htmlFor="graphData"
                    />
                    <FormSelect
                      id="graphData"
                      onChange={(e) => {
                        this.setState({ graphData: e.target.value });
                      }}
                      value={this.state.graphData}
                    >
                      <option value="totalHT">
                        {this.props.intl.formatMessage({
                          id: "Total.Excl.Tax",
                        })}
                      </option>
                      <option value="totalTTC">
                        {this.props.intl.formatMessage({
                          id: "Total.Incl.Tax",
                        })}
                      </option>
                      {this.state.selectedTab !== "OverTimeBudget" && (
                        <option value="quantity">
                          {this.props.intl.formatMessage({ id: "Quantity" })}
                        </option>
                      )}
                    </FormSelect>
                  </Form.Group>
                </Col>

                {this.state.selectedTab !== "OverTimeBudget" && (
                  <>
                    <Col>
                      <Form.Group>
                        <CustomLabel
                          label={this.props.intl.formatMessage({
                            id: "Graph.Type",
                          })}
                          htmlFor="graphType"
                        />
                        <FormSelect
                          id="graphType"
                          onChange={(e) => {
                            this.setState({ graphType: e.target.value });
                          }}
                          value={this.state.graphType}
                        >
                          <option value="pie">
                            {this.props.intl.formatMessage({ id: "Graph.Pie" })}
                          </option>
                          <option value="bar">
                            {this.props.intl.formatMessage({ id: "Graph.Bar" })}
                          </option>
                          <option value="line">
                            {this.props.intl.formatMessage({
                              id: "Graph.Line",
                            })}
                          </option>
                        </FormSelect>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Form.Group>
            </TableToolbar>
            <Tabs defaultActiveKey="budget-purchases" id="budgetTabs">
              <Tab
                eventKey="budget-purchases"
                title={intl.formatMessage({ id: "Purchases" })}
                onEnter={() => this.setState({ selectedTab: "ProductsBudget" })}
              >
                <ProductsBudget
                  orders={orders}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  graphData={this.state.graphData}
                  graphType={this.state.graphType}
                />
              </Tab>

              <Tab
                eventKey="budget-overtime"
                title={intl.formatMessage({ id: "Overview" })}
                onEnter={() => {
                  this.setState({ selectedTab: "OverTimeBudget" });
                  this.state.graphData === "quantity" &&
                    this.setState({ graphData: "totalHT" });
                }}
              >
                <OverTimeBudget
                  orders={orders}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  graphData={this.state.graphData}
                />
              </Tab>

              <Tab
                eventKey="budget-topproducts"
                title={intl.formatMessage({ id: "Top.Products" })}
                onEnter={() =>
                  this.setState({ selectedTab: "TopProductsBudget" })
                }
              >
                <TopProductsBudget
                  orders={orders}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  graphData={this.state.graphData}
                  graphType={this.state.graphType}
                />
              </Tab>
            </Tabs>
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Budget));
