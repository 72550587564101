import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import AddPatientDeskModal from "./desk/AddPatientDeskModal";
import Roles from "../../enums/Roles";
import {
  deleteDeskAudit,
  getDeskAudits,
} from "../../actions/deskaudit/deskaudit";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import EmailModal from "../sub/modals/EmailModal";
import { sendDeskAudit } from "../../actions/deskaudit/deskaudit";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import FileUtil from "../../util/FileUtil";
import Util from "../../util/Util";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import ActionMenu from "../sub/ActionMenu";
import MenuButton from "../sub/bootstrap/MenuButton";

class PatientDesk extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      savedAudit: {},
    };
  }

  componentDidMount() {
    if (this.props.patient !== undefined) {
      Util.getPatientAuditFromLocalStorage(
        this.props.patient._id,
        "saved_desk_audit",
      ).then((savedAudit) => {
        this.setState({ savedAudit: savedAudit });
      });
    }
  }

  getAuditsPatient(audits, patient) {
    return audits.filter((p) => p.patient_id === patient._id);
  }

  closeModal() {
    Util.deleteAuditInLocalStorage(this.props.patient._id, "saved_desk_audit");
    this.setState({ modal: null, savedAudit: {} });
  }

  openModal(patient) {
    this.setState({
      modal: (
        <AddPatientDeskModal
          patient={patient}
          savedAudit={this.state.savedAudit}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openConfirmAuditContinue() {
    const onAccept = () => {
      this.openModal(this.props.patient);
    };

    const onDecline = () => {
      this.setState({ savedAudit: {} });
      Util.deleteAuditInLocalStorage(
        this.props.patient._id,
        "saved_desk_audit",
      );
    };

    this.setState({
      modal: (
        <ConfirmationModal
          centered
          variant="warning"
          title={<FormattedMessage id="Automatic.Recovery" />}
          onAccept={() => onAccept()}
          onDecline={() => onDecline()}
        >
          <p>
            <FormattedMessage id="Confirm.Audit.Continue" />
          </p>
          <p>
            <strong>
              <FormattedMessage id="Confirm.Audit.Delete.Temp" />
            </strong>
          </p>
        </ConfirmationModal>
      ),
    });
  }

  sendEmails(emails, audit) {
    if (emails.length > 0) {
      const data = {
        patientId: this.props.patient._id,
        auditId: audit._id,
        emailsToSend: emails,
      };

      this.setState({
        disabled: true,
      });

      // Close the modal

      var successCallback = (response) => {
        let title = <FormattedMessage id="Mail.Sent.Audit" />;
        let content = <FormattedMessage id="Mail.Sent.Success" />;
        let variant = "success";

        if (response.data !== "OK") {
          content = <FormattedMessage id="Mail.Sent.Error" />;
          variant = "danger";
        }

        this.setState({
          modal: (
            <ConfirmationModal
              variant={variant}
              title={title}
              cancelButtonDisabled
              confirmButtonLabel={this.props.intl.formatMessage({
                id: "Close",
              })}
              onAccept={() => {
                this.closeModal();
              }}
            >
              {content}
            </ConfirmationModal>
          ),
        });
      };

      this.props.onSendDeskAudit(data, successCallback);
    } else {
      this.props.close();
    }
  }

  emailModal(audit) {
    this.setState({ modal: null }, () =>
      this.setState({
        modal: (
          <ModalBlock
            audit={audit ? audit : null}
            patient={this.props.patient}
            prescribers={this.props.prescribers}
            helpers={this.props.helpers}
            clients={this.props.clients}
            sendEmails={(emails, audit) => this.sendEmails(emails, audit)}
            close={() => this.closeModal()}
          />
        ),
      }),
    );
  }

  // DELETE AN AUDIT

  _deleteAudit(deskAuditId) {
    const onConfirm = () => {
      this.props.onDeleteAudit({ deskAuditId: deskAuditId });
    };

    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Warning" />}
          onAccept={onConfirm}
          onDecline={() => this.setState({ modal: null })}
          mandatoryConfirmation
        >
          <FormattedMessage id="Confirm.Audit.Removal" />
        </ConfirmationModal>
      ),
    });
  }

  render() {
    const { audits, patient } = this.props;
    var patientAuditsNode = null;

    if (!patient || !audits) return null;

    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;

    const isEstimateEnabled = user.access_module_store;

    let fileNameAudit =
      "audit_" +
      this.props.patient.first_name +
      "_" +
      this.props.patient.name +
      "_";

    // FILTER ONLY DESK AUDITS

    var deskAudits = audits.filter((ele) => {
      if (ele.products && ele.patient_id === this.props.patient._id) {
        return true;
      } else {
        return false;
      }
    });

    //let auditsPatient = this.getAuditsPatient(deskAudits, patient);

    if (deskAudits.length > 0) {
      patientAuditsNode = deskAudits.map((audit) => {
        let failureCallback = () => {
          this.setState({
            modal: (
              <ConfirmationModal
                variant="danger"
                title={<FormattedMessage id="Error" />}
                cancelButtonDisabled
                confirmButtonLabel={this.props.intl.formatMessage({
                  id: "Close",
                })}
                onAccept={() => {
                  this.closeModal();
                }}
              >
                <FormattedMessage id="Documents.NotFound.Content" />
              </ConfirmationModal>
            ),
          });
        };
        const menuItems = [];
        menuItems.push(
          {
            icon: "download",
            action: () =>
              FileUtil.dowloadFileHack(
                APIUrl.getAuditsPDF + audit.patient_id + "/" + audit._id,
                fileNameAudit + DateUtil.toyyyyMMdd(audit.createdAt),
                "pdf",
                (error) => failureCallback(error),
              ),
            text: <FormattedMessage id="Download.Audit" />,
          },
          {
            icon: "download",
            action: () =>
              FileUtil.dowloadFileHack(
                APIUrl.getAuditsPDF +
                  audit.patient_id +
                  "/cerfa_" +
                  audit._id +
                  "?token=" +
                  APIUrl.jwtToken,
                "cerfa_" + audit._id,
                "pdf",
                (error) => failureCallback(error),
              ),
            text: <FormattedMessage id="Download.Cerfa" />,
          },
          isEstimateEnabled && {
            icon: "download",
            action: () =>
              FileUtil.dowloadFileHack(
                APIUrl.getAuditsPDF +
                  audit.patient_id +
                  "/estimate_" +
                  audit._id +
                  "?token=" +
                  APIUrl.jwtToken,
                "estimate_" + audit._id,
                "pdf",
                (error) => failureCallback(error),
              ),
            text: <FormattedMessage id="Download.Estimate" />,
          },
          role !== Roles.CLIENT && {
            icon: "paper-plane",
            action: () => this.emailModal(audit),
            text: <FormattedMessage id="Mail.Resend" />,
          },
          role !== Roles.CLIENT && {
            id: "delete" + audit._id,
            icon: "trash",
            action: () => this._deleteAudit(audit._id),
            text: <FormattedMessage id="Delete" />,
          },
        );
        const menuAction = <ActionMenu items={menuItems} />;

        return (
          <tr key={"audit-" + audit._id}>
            <td className="align-middle">
              {audit && DateUtil.toDate(audit.createdAt)}
            </td>
            <td className="align-middle">{audit.principal_need_name}</td>
            <td className="col-1 align-middle text-center">{menuAction}</td>
          </tr>
        );
      });
    }

    return (
      <React.Fragment>
        {user.access_module_desk && role !== Roles.CLIENT && (
          <TableToolbar>
            <Row>
              <Col className="text-end">
                {Util.emptyObject(this.state.savedAudit) && (
                  <MenuButton onClick={(e) => this.openModal(patient)}>
                    <FormattedMessage id={"Patient.Audit.Add"} />
                  </MenuButton>
                )}

                {!Util.emptyObject(this.state.savedAudit) && (
                  <MenuButton
                    variant="danger"
                    onClick={(e) => this.openConfirmAuditContinue(patient)}
                  >
                    <FormattedMessage id="Patient.Audit.Continue" />
                  </MenuButton>
                )}
              </Col>
            </Row>
          </TableToolbar>
        )}

        {patient && deskAudits && deskAudits.length <= 0 && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id={"Empty.Audit.Done"} />
          </Alert>
        )}

        {patient && deskAudits && deskAudits.length > 0 && (
          <table className="table table-striped tablee4mad">
            <thead>
              <tr className="tablerowhead">
                <th>
                  <FormattedMessage id="Creation.Date" />
                </th>
                <th>
                  <FormattedMessage id="Selection.Mandatory" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{patientAuditsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

class ModalBlock extends React.Component {
  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Send.Report.To" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EmailModal
            patient={this.props.patient}
            prescribers={this.props.prescribers}
            helpers={this.props.helpers}
            clients={this.props.clients}
            audit={this.props.audit}
            origin={"list"}
            sendEmails={(emails, audit) => this.props.sendEmails(emails, audit)}
            close={() => this.props.close()}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    clients: state.clients,
    helpers: Util.filterClients(state.clients, "helper"),
    prescribers: Util.filterClients(state.clients, "prescriber"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAudit: (data) => dispatch(deleteDeskAudit(data)),
    onGetAuditsAdmin: () => dispatch(getDeskAudits()),
    onSendDeskAudit: (data, successCallback) =>
      dispatch(sendDeskAudit(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientDesk));
