import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import InputTextElement from "../InputTextElement";
import MenuButton from "../bootstrap/MenuButton";

class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      mandatoryConfirmation: this.props.mandatoryConfirmation
        ? this.generateCode()
        : false,
      disabled: false,
      inputConfirmationData: "",
    };
  }

  /**
   * The component is not destroyed anymore on each call.
   * Instead, we use the regular hide function of Modal bootstrap (which means also that the Modal will close smoothly from now on)
   * But if we need to display the modal again (after it have been close once), then we need to check what is the previous recorded state
   * That is the reason of this componentDidUpdate call.
   * BENEFIT : The close method is not required anymore to manage the confirm dialog.
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (!prevState.show) {
      this.setState({
        show: true,
      });
    }
  }

  generateCode() {
    return Math.floor(
      Math.random() * (999999 - 100001 + 1) + 100001,
    ).toString();
  }

  switchTextColor(variant) {
    let color;
    switch (variant) {
      case "danger":
      case "secondary":
      case "dark":
      case "success":
        color = "text-white";
        break;

      default:
        break;
    }

    return color;
  }

  onAccept() {
    // Little hack there. Should have used proper callbacks, but the modal was used everywhere,
    // so used async/await no to break the existing code.
    let callback = async () => {
      await this.props.onAccept();
    };

    this.setState({ show: false }, () => {
      // Add a timeout to allow smooth closing of the modal before executing the callback
      setTimeout(callback, 100);
    });
  }

  onDecline() {
    if (this.props.onDecline) {
      // Little hack there. Should have used proper callbacks, but the modal was used everywhere,
      // so used async/await no to break the existing code.
      let callback = async () => {
        await this.props.onDecline();
      };

      this.setState({ show: false }, () => {
        // Add a timeout to allow smooth closing of the modal before executing the callback
        setTimeout(callback, 100);
      });
    } else {
      this.setState({ show: false });
    }
  }

  onClose(preventDeclineOnClose) {
    if (!preventDeclineOnClose) {
      this.onDecline();
    } else {
      this.setState({ show: false });
    }
  }

  verifyRequiredInput(data) {
    if (data.length <= 6 && data <= 999999) {
      this.setState({ inputConfirmationData: data });
    }
  }

  render() {
    const {
      size,
      title,
      confirmButtonId,
      confirmButtonLabel,
      confirmButtonIcon,
      confirmButtonDisabled,
      cancelButtonId,
      cancelButtonLabel,
      cancelButtonIcon,
      cancelButtonDisabled,
      mandatoryConfirmation,
      preventClose,
      preventDeclineOnClose,
      fullscreen,
    } = this.props;

    // Force danger if mandatory required
    let variant = mandatoryConfirmation
      ? "danger"
      : this.props.variant || "primary";

    return (
      <Modal
        show={this.state.show}
        onHide={() => this.onClose(preventDeclineOnClose)}
        size={size}
        backdrop="static"
        keyboard={false}
        centered={this.props.centered || false}
        variant={variant}
        fullscreen={fullscreen}
      >
        <Modal.Header className={"bg-" + variant} closeButton={!preventClose}>
          <Modal.Title className={this.switchTextColor(variant)}>
            {title ? title : <FormattedMessage id="Required.Confirmation" />}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.children}

          {this.state.mandatoryConfirmation && (
            <p className="text-danger mt-2">
              <strong>
                <FormattedMessage
                  id="Mandatory.Confirmation.Message"
                  values={{ code: this.state.mandatoryConfirmation }}
                />
              </strong>
              <br />
              <InputTextElement
                type="number"
                className="border-danger mt-1"
                placeholder={this.props.intl.formatMessage({ id: "Code" })}
                value={this.state.inputConfirmationData}
                onChange={(e) => this.verifyRequiredInput(e.target.value)}
              />
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!cancelButtonDisabled && (
            <MenuButton
              id={cancelButtonId}
              icon={cancelButtonIcon || "circle-xmark"}
              variant="secondary"
              onClick={() => this.onDecline() || this.setState({ show: false })}
              disabled={cancelButtonDisabled}
            >
              {cancelButtonLabel || <FormattedMessage id="Cancel" />}
            </MenuButton>
          )}
          <MenuButton
            id={confirmButtonId}
            variant={variant}
            icon={confirmButtonIcon || "circle-check"}
            onClick={() => this.onAccept()}
            disabled={
              this.state.mandatoryConfirmation
                ? this.state.inputConfirmationData !==
                  this.state.mandatoryConfirmation
                : confirmButtonDisabled || this.state.disabled
            }
          >
            {confirmButtonLabel ? (
              confirmButtonLabel
            ) : (
              <FormattedMessage id="Confirm" />
            )}
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(ConfirmationModal);
