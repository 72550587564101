import React from "react";
import { connect } from "react-redux";
import PriceUtil from "../../util/PriceUtil";
import {
  updateUrgent,
  increaseQuantity,
  decreaseQuantity,
  deleteCartById,
} from "../../actions/cart/cart";
import { FormattedMessage, injectIntl } from "react-intl";
import MenuButton from "../sub/bootstrap/MenuButton";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { Col, Row } from "react-bootstrap";

class CartRow extends React.Component {
  updateUrgent(e, cartId, urgent) {
    var data = { cartId: cartId, urgent: !urgent };
    this.props.onUpdateUrgent(data);
  }

  increaseQuantity(e, cartId) {
    var data = { cartId: cartId };
    this.props.onIncreaseQuantity(data);
  }

  decreaseQuantity(e, cartId) {
    var data = { cartId: cartId };
    this.props.onDecreaseQuantity(data);
  }

  deleteCartById(e, cartId) {
    var data = { cartId: cartId };
    this.props.onDeleteCartById(data);
  }

  render() {
    if (
      this.props.product === undefined ||
      this.props.product === null ||
      !this.props.product
    )
      return null;

    // 'Delete article' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = (
      <FormattedMessage
        id="Product.Remove.Confirmation"
        values={{
          designation: ProductsParserUtil.swapDesignationDelimiter(
            this.props.product.designation,
          ),
        }}
      />
    );
    var successCallback = (e) => this.deleteCartById(e, this.props.cart._id);

    return (
      <tr key={this.props.cart._id}>
        <td className="align-middle">{this.props.product.ref}</td>
        <td className="align-middle">{this.props.product.famille}</td>
        <td className="align-middle col-3">
          {ProductsParserUtil.swapDesignationDelimiter(
            this.props.product.designation,
          )}
        </td>
        <td className="align-middle">{this.props.product.unite_de_vente}</td>
        <td className="align-middle">
          {PriceUtil.formatEuro(PriceUtil.priceHt(this.props.product, 1, 2))}
        </td>
        <td className="align-middle">{this.props.product.tva * 100}%</td>
        <td className="align-middle">
          {PriceUtil.formatEuro(PriceUtil.priceTtc(this.props.product, 1, 2))}
        </td>
        <td className="align-middle">
          {PriceUtil.formatEuro(
            PriceUtil.priceHt(this.props.product, this.props.cart.quantity, 2),
          )}
        </td>
        <td className="align-middle">
          {PriceUtil.formatEuro(
            PriceUtil.priceTtc(this.props.product, this.props.cart.quantity, 2),
          )}
        </td>
        <td className="align-middle text-center">
          <Row>
            <Col md={12} className="text-center">
              <MenuButton
                icon="minus"
                size="sm"
                disabled={this.props.cart.quantity === 1}
                variant="outline-secondary"
                onClick={(e) => this.decreaseQuantity(e, this.props.cart._id)}
              />
              <label className="me-3 ms-3">
                <strong>{this.props.cart.quantity}</strong>
              </label>
              <MenuButton
                icon="plus"
                size="sm"
                variant="outline-secondary"
                onClick={
                  this.props.cart.quantity >= 1000
                    ? ""
                    : (e) => this.increaseQuantity(e, this.props.cart._id)
                }
              />
            </Col>
          </Row>
        </td>
        <td className="align-middle text-center">
          <MenuButton
            icon="bell"
            size="sm"
            className="me-2"
            onClick={(e) =>
              this.updateUrgent(e, this.props.cart._id, this.props.cart.urgent)
            }
            variant={this.props.cart.urgent && "warning"}
            hover={<FormattedMessage id="Order.Urgent" />}
          />

          <MenuButton
            icon="trash"
            size="sm"
            onClick={(e) =>
              this.props.openConfModal(
                modalTitle,
                modalContent,
                successCallback,
              )
            }
            hover={<FormattedMessage id="Delete" />}
          />
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUrgent: (data) => dispatch(updateUrgent(data)),
    onIncreaseQuantity: (data) => dispatch(increaseQuantity(data)),
    onDecreaseQuantity: (data) => dispatch(decreaseQuantity(data)),
    onDeleteCartById: (data) => dispatch(deleteCartById(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CartRow));
