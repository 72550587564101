import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TextareaCounter from "../sub/TextareaCounter";
import Util from "../../util/Util";
import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import { createRequest } from "../../actions/requests/requests";
import CustomLabel from "../sub/CustomLabel";
import InputLength from "../../enums/InputLength";
import { Col, Form, Row, Modal } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";

class NewMaterialRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      precisions: "",
      disabled: false,
      disabledInputs: false,
    };

    if (this.props.request) {
      this.state = {
        method: this.props.request.method,
        precisions: this.props.request.precisions,
        disabled: false,
        disabledInputs: true,
      };
    }
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    let data = {
      request_type: RequestTypes.NEW_MATERIAL,
      method: parseInt(this.state.method),
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    this.setState({ disabled: true });

    this.props.onCreateRequest(data, () => this.props.close());
  }

  hasEmptyField() {
    return Util.emptyString(this.state.precisions);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    const { close } = this.props;

    return (
      <React.Fragment>
        <Form.Group as={Row} className="align-items-center">
          <Col>
            <TextareaCounter
              countLimit={InputLength.TEXT_LONG}
              id="precisions"
              rows={8}
              value={this.state.precisions}
              onChange={(e) => this.onChange("precisions", e.target.value)}
              disabled={this.state.disabledInputs}
            />
          </Col>
        </Form.Group>

        {!this.state.disabledInputs && (
          <Modal.Footer>
            <MenuButton
              variant="secondary"
              data-dismiss="modal"
              onClick={() => close()}
            >
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Create" />
            </MenuButton>
          </Modal.Footer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(NewMaterialRequest));
