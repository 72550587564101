import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../util/Util";
import DateUtil from "../../../util/DateUtil";
import { Modal } from "react-bootstrap";
import { updClient } from "../../../actions/clients/clients";
import { CrmProviders } from "../../../enums/CrmProviders";
import MenuButton from "../../sub/bootstrap/MenuButton";

class FindCliModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apiData: this.props.apiData || "",
      crmId: this.props.crmId || "",
      linkedAccount: this.props.linkedAccount || false,
      disabled: false,
    };
  }

  close() {
    this.props.closeModal();
  }

  updateLink(crmId) {
    this.setState({ disabled: true });

    let callback = () => {
      this.props.onComplete(crmId);
    };

    // client already exists, update field value
    if (Util.getNested(this.props.client, "_id")) {
      this.props.onUpdatePrescriber(
        {
          _id: this.props.client._id,
          updatedField: "crm_id",
          updatedValue: crmId,
        },
        callback,
      );
    }
  }

  // Specific to LOMACO
  toClient(data) {
    return {
      Numero: this.state.crmId,
      Nom: data.nom,
      CodePostal: data.codePostal,
      Ville: data.ville,
      Actif: data.actif ? data.actif : <FormattedMessage id="Unknown" />,
      CodeType: data.categorie,
      CodeEtablissement: data.agences[0].id,
      CodeUsrCreation: data.createdBy ? (
        data.createdBy
      ) : (
        <FormattedMessage id="Unknown" />
      ),
      DateCreation: data.dateMiseAJour,
    };
  }

  render() {
    let client;
    let mustError;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { software } = this.props.company.crm ? this.props.company.crm : {};

    if (this.state.apiData) {
      if (software === CrmProviders.MUST.software) {
        client = Util.getNested(
          this.state.apiData,
          "FindClisResult",
          "Clients",
          "Client",
          0,
        );
      } else {
        client = this.toClient(this.state.apiData);
      }
    } else {
      mustError = Util.getNested(
        this.state.apiData,
        "FindClisResult",
        "Erreur",
      );
    }

    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="API.CRM.Link.Client.Account" /> {software}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {client ? (
            <table className="table table-striped table-bordered tablee4mad mt-3">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage id="Field" />
                  </th>
                  <th>
                    <FormattedMessage id="Value" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Client.Code" />
                  </td>
                  <td className="align-middle">{client["Numero"]}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Name" />
                  </td>
                  <td className="align-middle">{client["Nom"]}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Postal.Code" /> &amp;{" "}
                    <FormattedMessage id="City" />
                  </td>
                  <td className="align-middle">
                    {client["CodePostal"]} {client["Ville"]}
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Active" />
                  </td>
                  <td className="align-middle">{client["Actif"]}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Client.Type" />
                  </td>
                  <td className="align-middle">{client["CodeType"]}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Establishment.Code" />
                  </td>
                  <td className="align-middle">
                    {client["CodeEtablissement"]}
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Created.By" />
                  </td>
                  <td className="align-middle">{client["CodeUsrCreation"]}</td>
                </tr>
                <tr>
                  <td className="align-middle">
                    <FormattedMessage id="Creation.Date" />
                  </td>
                  <td className="align-middle">
                    {DateUtil.toDateWithHour(client["DateCreation"])}
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            mustError["Message"]
          )}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          {
            /** Check if client is already associated to MUST or not */
            client ? (
              !this.state.linkedAccount ? (
                <MenuButton onClick={() => this.updateLink(client["Numero"])}>
                  <FormattedMessage id="Confirm" />
                </MenuButton>
              ) : (
                <MenuButton
                  variant="danger"
                  onClick={() => this.updateLink("")}
                  disabled={this.state.crmId === ""}
                >
                  <FormattedMessage id="Separate" />
                </MenuButton>
              )
            ) : (
              ""
            )
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdatePrescriber: (data, successCallback) =>
      dispatch(updClient(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(FindCliModal));
