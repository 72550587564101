import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";

import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import Roles from "../../enums/Roles";

import Paginator from "../sub/Paginator";

import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";

import RequestRow from "../requests/RequestRow";
import RequestModal from "../requests/RequestModal";
import EstimateModalAdmin from "./EstimateModalAdmin";
import { Col, Form, FormSelect, Row } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import InputTextElement from "../sub/InputTextElement";
import Select from "react-select";

class Estimates extends React.Component {
  constructor(props) {
    super(props);

    this.defaultClientSelected = {
      value: "",
      label: `${this.props.intl.formatMessage({ id: "Client" })}: ${this.props.intl.formatMessage({ id: "All" })}`,
    };

    this.state = {
      modal: null,
      selectedClient: this.defaultClientSelected,
      dateFilter: "",
      statusFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  openRequestModalAdmin(request) {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          close={() => this.closeModal()}
          requestType={request.request_type}
          requestId={request._id}
        />
      ),
    });
  }

  openRequestModal() {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          requestType={RequestTypes.NEW_MATERIAL}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openRequestDetailModal(request) {
    this.setState({
      modal: (
        <RequestModal
          isOpen={true}
          close={() => this.closeModal()}
          requestType={request.request_type}
          requestId={request._id}
        />
      ),
    });
  }

  openEstimateModalAdmin() {
    this.setState({
      modal: (
        <EstimateModalAdmin
          isOpen={true}
          close={() => this.closeModal()}
          requestType={RequestTypes.NEW_MATERIAL}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  sortByDateDesc(order1, order2) {
    let result = 0;
    if (order1.creation_date > order2.creation_date) result--;
    if (order1.creation_date < order2.creation_date) result++;
    return result;
  }

  countMaterial(requests) {
    let tot = 0;
    for (let request of requests)
      if (request.request_type === RequestTypes.NEW_MATERIAL) tot++;
    return tot;
  }

  setDateFilter = (date) => {
    this.setState({
      dateFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  areResultsFiltered = () => {
    let result = false;
    if (
      !Util.emptyString(this.state.selectedClient.value) ||
      !Util.emptyString(this.state.dateFilter) ||
      !Util.emptyString(this.state.statusFilter)
    ) {
      result = true;
    }
    return result;
  };

  resetSearchFields() {
    this.setState({
      selectedClient: this.defaultClientSelected,
      dateFilter: "",
      statusFilter: "",
    });
  }

  getSelectOptions() {
    const options = [];
    const clients = this.props.clients.filter((c) =>
      this.props.requests.find((r) => r.client_id === c._id),
    );

    options.push(this.defaultClientSelected);

    for (const c of clients) {
      options.push({
        value: c._id,
        label: Util.formatFullName(c.first_name, c.name),
      });
    }
    return options;
  }

  render() {
    let isClient = this.props.user.role === Roles.CLIENT;
    let materialNbr = this.countMaterial(this.props.requests);

    this.paginator.init(materialNbr);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    let i = 0;
    let requestRowNode = this.props.requests.map((request) => {
      if (request.request_type !== RequestTypes.NEW_MATERIAL) return null;

      if (this.state.selectedClient && this.state.selectedClient.value !== "") {
        if (request.client_id !== this.state.selectedClient.value) return null;
      }

      if (this.state.dateFilter && this.state.dateFilter !== "") {
        if (
          DateUtil.toDate(request.creation_date) !==
          DateUtil.toDate(this.state.dateFilter)
        )
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (parseInt(request.status) !== parseInt(this.state.statusFilter))
          return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <RequestRow
          includeType={false}
          key={request._id}
          request={request}
          clientName={request.client_name}
          openRequestDetailModal={(request) =>
            this.openRequestDetailModal(request)
          }
          openRequestModalAdmin={(request) =>
            this.openRequestModalAdmin(request)
          }
          limit={this.props.limit && true}
        />
      );
    });

    let statusFilterNode = Object.values(EstimateStatus).map((status) => {
      return (
        <option key={status} value={status}>
          {this.props.intl.formatMessage({ id: "Estimate.Status." + status })}
        </option>
      );
    });

    return (
      <React.Fragment>
        {materialNbr === 0 && (
          <TableToolbar message={<FormattedMessage id="Empty.Requests" />}>
            {!this.props.limit && (
              <MenuButton
                className="ms-auto"
                onClick={(e) =>
                  isClient
                    ? this.openRequestModal()
                    : this.openEstimateModalAdmin()
                }
              >
                <FormattedMessage id="Create.Request" />
              </MenuButton>
            )}
          </TableToolbar>
        )}
        {materialNbr > 0 && (
          <React.Fragment>
            {!this.props.limit && (
              <TableToolbar>
                <Form.Group as={Row} className="align-items-center">
                  {!isClient && (
                    <Col>
                      <Select
                        className={
                          this.state.selectedClient.value && "filtered-result"
                        }
                        classNamePrefix={
                          this.state.selectedClient.value && "react-select"
                        }
                        placeholder={this.props.intl.formatMessage({
                          id: "Client",
                        })}
                        options={this.getSelectOptions()}
                        noOptionsMessage={() => (
                          <FormattedMessage id={"No.Result"} />
                        )}
                        onChange={(option) =>
                          this.setState({
                            selectedClient: option,
                          })
                        }
                        value={this.state.selectedClient}
                      />
                    </Col>
                  )}
                  <Col>
                    <InputTextElement
                      id="search_date"
                      type="date"
                      className={this.state.dateFilter && "filtered-result"}
                      onChange={(e) => {
                        this.setState({ dateFilter: e.target.value });
                      }}
                      value={this.state.dateFilter}
                      disabled={disableFormInput}
                      placeholder={this.props.intl.formatMessage({
                        id: "Date",
                      })}
                    />
                  </Col>
                  <Col>
                    <FormSelect
                      id="search_status"
                      className={this.state.statusFilter && "filtered-result"}
                      onChange={(e) => {
                        this.setState({ statusFilter: e.target.value });
                      }}
                      value={this.state.statusFilter}
                      disabled={disableFormInput}
                    >
                      <option value="">
                        <>
                          {this.props.intl.formatMessage({ id: "Status" })}
                          {": "}
                          {this.props.intl.formatMessage({ id: "All" })}
                        </>
                      </option>
                      {statusFilterNode}
                    </FormSelect>
                  </Col>
                  <Col>
                    <MenuButton
                      onClick={() => this.resetSearchFields()}
                      hover={
                        this.areResultsFiltered() &&
                        !disableFormInput && (
                          <FormattedMessage id="Remove.Filter" />
                        )
                      }
                      variant={
                        this.areResultsFiltered() ? "info" : "outline-secondary"
                      }
                      icon="filter"
                      disabled={!this.areResultsFiltered() || disableFormInput}
                    />
                  </Col>
                  <Col className="text-end">
                    <MenuButton
                      onClick={(e) =>
                        isClient
                          ? this.openRequestModal()
                          : this.openEstimateModalAdmin()
                      }
                    >
                      <FormattedMessage id="Create.Request" />
                    </MenuButton>
                  </Col>
                </Form.Group>
              </TableToolbar>
            )}

            <table className="table table-striped tablee4mad">
              <thead>
                <tr>
                  {!isClient && (
                    <th scope="col">
                      <FormattedMessage id="Client" />
                    </th>
                  )}
                  <th scope="col" className={isClient ? "col" : ""}>
                    <FormattedMessage id="Estimate.Request" />
                  </th>
                  <th scope="col" className="col-2">
                    <FormattedMessage id="Date" />
                  </th>
                  <th scope="col" className="col-2">
                    <FormattedMessage id="Status" />
                  </th>
                  {!this.props.limit && (
                    <th scope="col" className="col-1 text-center">
                      <FormattedMessage id="Actions" />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{requestRowNode}</tbody>
            </table>

            {!this.props.limit && this.paginator.render()}
          </React.Fragment>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requests: state.requests,
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Estimates));
