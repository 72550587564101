import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import OrderStatus from "../../../enums/OrderStatus";
import Accessibilities from "../../../enums/MenuAccessibilities";
import { getProducts } from "../../../actions/products/products";
import { passOrder } from "../../../actions/orders/orders";

import Menu from "./menu/Menu";
import Roles from "../../../enums/Roles";

class MenuMAD extends React.Component {
  countNewOrders() {
    let nbr = 0;
    for (let order of this.props.orders) {
      if (order.status === OrderStatus.NEW) nbr++;
    }
    return nbr;
  }

  render() {
    const { user, coordinationNotifications } = this.props;

    let newOrdersNbr = this.countNewOrders();

    let menuItems = [
      {
        link: "/home",
        formattedMessageId: "Home",
        accessibility: Accessibilities.COMMON,
        modules: ["DESK", "STORE", "HOME"],
      },
      user.role === Roles.CLIENT &&
        user.client_type !== "patient" && {
          link: "/home/patients",
          formattedMessageId: "Patients.Monitoring",
          accessibility: Accessibilities.COMMON,
          modules: ["DESK", "STORE", "HOME"],
        },
      {
        link: "/home/mercuriales",
        formattedMessageId: "Mercurials",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["STORE"],
      },
      {
        link: "/home/families",
        formattedMessageId: "Mercurial",
        accessibility: Accessibilities.CLIENT_ONLY,
        modules: ["STORE"],
      },
      {
        link: "/home/orders",
        formattedMessageId: "Orders",
        isCountBadge: true,
        countValue: newOrdersNbr,
        accessibility: Accessibilities.COMMON,
        modules: ["STORE"],
      },
      user.role !== Roles.CLIENT && {
        link: "/home/patients",
        formattedMessageId: "Patients.Monitoring",
        accessibility: Accessibilities.COMMON,
        modules: ["DESK", "STORE", "HOME"],
      },
      {
        link: "/home/clients",
        formattedMessageId: "Client.Monitoring",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["DESK", "STORE", "HOME"],
      },
      {
        link: "/home/documents",
        formattedMessageId: "Documents",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["DESK", "STORE", "HOME"],
      },
      {
        link: "/home/estimates",
        formattedMessageId: "Estimates",
        accessibility: Accessibilities.COMMON,
        modules: ["DESK", "STORE", "HOME"],
      },
      {
        link: "/home/budget",
        formattedMessageId: "Budget",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP,
        modules: ["STORE"],
      },
      {
        link: "/home/coordination",
        formattedMessageId: "Coordination",
        accessibility: Accessibilities.ADMIN_AND_SALES_REP_AND_PRESCRIBER,
        modules: ["DESK", "STORE", "HOME"],
        isCountBadge: true,
        countValue: coordinationNotifications,
      },
      {
        link: "/home/settings",
        formattedMessageId: "Settings",
        accessibility: Accessibilities.ADMIN_ONLY,
        modules: ["DESK", "STORE", "HOME"],
      },
    ];

    let menuItemLogo = {
      imgSrc: "./images/svg/logo_v2_cut.svg",
      imgAlt: "logo pharma-mad",
    };

    return <Menu menuItems={menuItems} menuItemLogo={menuItemLogo} />;
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    user: state.user,
    coordinationNotifications: state.coordinationNotifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPassOrder: (data, successCallback) =>
      dispatch(passOrder(data, successCallback)),
    onGetProducts: () => dispatch(getProducts()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuMAD),
);
