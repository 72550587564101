import { GET_SOCKET } from "../../actions/socket/socket";

export default function socket(state = {}, action) {
  switch (action.type) {
    case GET_SOCKET:
      return action.socket;
    default:
      return state;
  }
}
