import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_FAMILIES = "GET_FAMILIES";

function getFamiliesAction(families) {
  return { type: GET_FAMILIES, families: families };
}

export const getFamilies = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getFamilies, data)
      .then(function (response) {
        dispatch(getFamiliesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================================
// ================================== ADMIN =========================================
// ==================================================================================

export const addFamilyAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addFamily, data)
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: data.mercurial_id }));
        if (successCallback) successCallback(response.data[0]);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateFamilyAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateFamily, data)
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: data.mercurial_id }));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteFamilyAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteFamily, data)
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: data.mercurial_id }));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sortFamily = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sortFamily, data)
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: data.mercurial_id }));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const checkIfFamilyNameExists = function (
  data,
  existsCallback,
  noExistsCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.checkIfFamilyNameExists, data)
      .then(function (response) {
        if (response && response.status === 200) {
          if (existsCallback) existsCallback();
        } else {
          if (noExistsCallback) noExistsCallback();
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addFamilyImg = function (mercurialId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addFamilyImg + mercurialId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: mercurialId }));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const removeFamilyImg = function (mercurialId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.removeFamilyImg + mercurialId, data)
      .then(function (response) {
        dispatch(getFamilies({ mercurialId: mercurialId }));
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
