import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderRow from "./OrderRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import OrderStatus from "../../enums/OrderStatus";
import Paginator from "../sub/Paginator";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import MenuButton from "../sub/bootstrap/MenuButton";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Alert, Col, Container, FormSelect, Row } from "react-bootstrap";
import InputTextElement from "../sub/InputTextElement";
import { withRouter } from "react-router-dom";

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    };

    this.paginator = new Paginator(this);
  }

  handleCatalogRedirect = () => {
    // Utiliser this.props.history.push pour rediriger
    this.props.history.push("/home/families");
  };

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  sortByDateDesc(order1, order2) {
    if (order1.creation_date > order2.creation_date) return -1;
    if (order1.creation_date < order2.creation_date) return 1;
    return 0;
  }

  setStartDateFilter = (date) => {
    this.setState({
      dateStartFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        0,
        0,
        0,
        0,
      ),
    });
  };

  setEndDateFilter = (date) => {
    this.setState({
      dateEndFilter: new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate(),
        23,
        59,
        59,
        999,
      ),
    });
  };

  areResultsFiltered = () => {
    if (
      !Util.emptyString(this.state.referenceFilter) ||
      !Util.emptyString(this.state.dateStartFilter) ||
      !Util.emptyString(this.state.dateEndFilter) ||
      !Util.emptyString(this.state.statusFilter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetSearchFields() {
    this.setState({
      referenceFilter: "",
      dateStartFilter: "",
      dateEndFilter: "",
      statusFilter: "",
    });
  }

  render() {
    if (this.props.orders.length === 0) {
      return (
        <TableToolbar>
          <Row className="align-items-center">
            <Col>
              <div className="form-inline">
                <Alert variant="info" className="w-100 mt-2 mb-2">
                  <FormattedMessage id="Empty.Orders" />
                </Alert>

                {!this.props.limit && (
                  <MenuButton onClick={this.handleCatalogRedirect}>
                    <FormattedMessage id="Order.Pass.Some" />
                  </MenuButton>
                )}
              </div>
            </Col>
          </Row>
        </TableToolbar>
      );
    }

    let orders = this.props.orders.sort(this.sortByDateDesc);

    this.paginator.init(this.props.orders.length);

    let disableFormInput = this.paginator.paginationIndex !== 1 ? true : false;

    var i = 0;
    let ordersNode = orders.map((order) => {
      if (this.state.referenceFilter && this.state.referenceFilter !== "") {
        if (order.ref.indexOf(this.state.referenceFilter) === -1) return null;
      }

      if (this.state.dateStartFilter && this.state.dateStartFilter !== "") {
        if (
          new Date(order.creation_date) < new Date(this.state.dateStartFilter)
        )
          return null;
      }

      if (this.state.dateEndFilter && this.state.dateEndFilter !== "") {
        if (new Date(order.creation_date) >= new Date(this.state.dateEndFilter))
          return null;
      }

      if (this.state.statusFilter && this.state.statusFilter !== "") {
        if (order.status !== Number(this.state.statusFilter)) return null;
      }

      if (this.props.limit && ++i > this.props.limit) return null;

      if (!this.paginator.keep()) return null;

      return (
        <OrderRow
          key={order._id}
          order={order}
          admin={this.props.admin}
          client={this.props.user}
          products={order.products}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
          limit={this.props.limit && true}
        />
      );
    });

    var statusNode = Object.values(OrderStatus).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "Order.Status." + option })}
        </option>
      );
    });

    return (
      <Container fluid>
        {this.state.modal}

        {!this.props.limit && (
          <TableToolbar>
            <Row className="align-items-center">
              <Col>
                <InputTextElement
                  id="search_reference"
                  type="text"
                  className={this.state.referenceFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ referenceFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.referenceFilter}
                  placeholder={this.props.intl.formatMessage({
                    id: "Reference",
                  })}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_date"
                  type="date"
                  className={this.state.dateStartFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setStartDateFilter(e.target.value);
                  }}
                  disabled={disableFormInput}
                  value={DateUtil.toyyyyMMdd(this.state.dateStartFilter)}
                  placeholder={this.props.intl.formatMessage({ id: "Date" })}
                />
              </Col>
              <Col>
                <InputTextElement
                  id="search_delivery_date"
                  className={this.state.dateEndFilter && "filtered-result"}
                  type="date"
                  onChange={(e) => {
                    this.setEndDateFilter(e.target.value);
                  }}
                  value={DateUtil.toyyyyMMdd(this.state.dateEndFilter)}
                  disabled={disableFormInput}
                  placeholder={this.props.intl.formatMessage({
                    id: "Delivery.Date",
                  })}
                />
              </Col>
              <Col>
                <FormSelect
                  id="search_status"
                  className={this.state.statusFilter && "filtered-result"}
                  onChange={(e) => {
                    this.setState({ statusFilter: e.target.value });
                  }}
                  disabled={disableFormInput}
                  value={this.state.statusFilter}
                >
                  <option value="">
                    <>
                      {this.props.intl.formatMessage({ id: "Status" })}
                      {": "}
                      {this.props.intl.formatMessage({ id: "All" })}
                    </>
                  </option>
                  {statusNode}
                </FormSelect>
              </Col>
              <Col>
                <MenuButton
                  onClick={() => this.resetSearchFields()}
                  hover={
                    this.areResultsFiltered() &&
                    !disableFormInput && <FormattedMessage id="Remove.Filter" />
                  }
                  variant={
                    this.areResultsFiltered() ? "info" : "outline-secondary"
                  }
                  icon="filter"
                  disabled={!this.areResultsFiltered() || disableFormInput}
                />
              </Col>
              {!this.props.limit && (
                <Col className="text-end">
                  <MenuButton onClick={this.handleCatalogRedirect}>
                    <FormattedMessage id="Go.To.Mercurial" />
                  </MenuButton>
                </Col>
              )}
            </Row>
          </TableToolbar>
        )}

        <table className="table table-striped tablee4mad">
          <thead>
            <tr>
              <th scope="col" className="text-nowrap">
                <FormattedMessage id="Reference" />
              </th>
              <th scope="col" className="text-nowrap">
                <FormattedMessage id="Date" />
              </th>
              <th scope="col" className="text-nowrap">
                <FormattedMessage id="Order.Estimated.Delivery.Date" />
              </th>
              <th scope="col" className="text-nowrap">
                <FormattedMessage id="Total.Incl.Tax" />
              </th>
              <th
                scope="col"
                className={
                  !this.props.limit
                    ? "col-2 align-middle"
                    : "col-3 align-middle"
                }
              >
                <FormattedMessage id="Order.Status" />
              </th>
              {!this.props.limit && (
                <th scope="col" className="col-1 text-center">
                  <FormattedMessage id="Actions" />
                </th>
              )}
              {/* <th scope="col" className="col-1 text-center"><FormattedMessage id="Urgent" /></th> */}
            </tr>
          </thead>
          <tbody>{ordersNode}</tbody>
        </table>

        {!this.props.limit && this.paginator.render()}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    user: state.user,
    admin: state.admin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(Orders)),
);
