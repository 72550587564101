import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";

import AuditSettings from "../auditSettings/AuditSettings";
import AuditRooms from "../auditRooms/AuditRooms";

class Audit extends React.Component {
  render() {
    const { intl, auditSettings, auditRooms } = this.props;

    return (
      <React.Fragment>
        <Tabs defaultActiveKey="audit-settings" id="auditTabs">
          <Tab
            eventKey="audit-settings"
            title={intl.formatMessage({ id: "PDF.Params" })}
          >
            <AuditSettings auditSettings={auditSettings} />
          </Tab>

          <Tab
            eventKey="audit-audit-rooms"
            title={intl.formatMessage({ id: "Audit.Rooms" })}
          >
            <AuditRooms auditRooms={auditRooms} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditSettings: state.auditSettings,
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Audit));
