import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { updateShippingCostsSetting } from "../../../../actions/settings/ordersSettings/admin/ordersSettings";
import ActionMenu from "../../../sub/ActionMenu";

class ShippingCostSettingAdminRow extends React.Component {
  updateShippingCostsSetting(shippingCosts) {
    // Send to BE
    this.props.onUpdateShippingCostsSetting({ shippingCosts: shippingCosts });
  }

  isLast() {
    return this.props.index === this.props.shippingCosts.length - 1;
  }

  openShippingModal(index, successCallbackUpdate) {
    if (!this.isLast()) return;

    this.props.openShippingModal(index, successCallbackUpdate);
  }

  openConfModal(modalTitle, modalContent, successCallbackDelete) {
    if (!this.isLast()) return;

    this.props.openConfModal(modalTitle, modalContent, successCallbackDelete);
  }

  splice(array, index) {
    array.splice(index, 1);
    return array;
  }

  render() {
    // 'Delete shipping setting' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Remove.Row.Confirmation" />;
    var successCallbackDelete = () =>
      this.updateShippingCostsSetting(
        this.splice(this.props.shippingCosts, this.props.index),
      );

    // Shipping modal set up
    var successCallbackUpdate = (shippingCosts) =>
      this.updateShippingCostsSetting(shippingCosts);

    var max = !this.props.shippingCost.max ? (
      <FormattedMessage id="Infinity" />
    ) : (
      <span>{this.props.shippingCost.max}</span>
    );

    let menuItems = [];

    menuItems.push(
      // edit
      {
        icon: "pen-to-square",
        action: () =>
          this.openShippingModal(this.props.index, successCallbackUpdate),
        text: <FormattedMessage id="Modify" />,
        disabled: !this.isLast(),
      },
      // Delete
      {
        icon: "trash",
        action: () =>
          this.openConfModal(modalTitle, modalContent, successCallbackDelete),
        text: <FormattedMessage id="Delete" />,
        disabled: !this.isLast(),
      },
    );

    let menuAction = <ActionMenu items={menuItems} disabled={!this.isLast()} />;

    return (
      <tr key={this.props.key} className="tre4mad">
        <td className="align-middle">{this.props.shippingCost.min}</td>
        <td className="align-middle">{max}</td>
        <td className="align-middle">{this.props.shippingCost.cost}</td>
        <td className="align-middle text-center">{menuAction}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateShippingCostsSetting: (data) =>
      dispatch(updateShippingCostsSetting(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingCostSettingAdminRow);
