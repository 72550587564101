import React from "react";
import PropTypes from "prop-types";
import { Image, Navbar } from "react-bootstrap";

const propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

/**
 * Logo item for user navbar
 * @param {String} link navlink to redirect
 * @param {String} imgSrc source link to display image
 * @param {String} imgAlt alternative text if image not displayed
 * @param {Function} onClick action to do on image click
 */
class MenuItemLogo extends React.Component {
  render() {
    const { imgSrc, imgAlt, onClick } = this.props;
    return (
      <Navbar.Brand onClick={onClick}>
        <Image
          className="logo-header"
          height={"64em"}
          alt={imgAlt}
          src={imgSrc}
        />
      </Navbar.Brand>
    );
  }
}

MenuItemLogo.propTypes = propTypes;

export default MenuItemLogo;
