import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Icon from "../../../sub/Icon.js";
import { Badge, Card, Col, Nav, Row } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton.js";
import PriceUtil from "../../../../util/PriceUtil.js";

const propTypes = {
  link: PropTypes.string.isRequired,
  countValue: PropTypes.number.isRequired,
  totalCart: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

/**
 * NavLink item for user Navbar
 * @param {String} link navlink to redirect on cart
 * @param {Number} countValue value to display on badge danger
 * @param {Number} totalCart total price of cart
 * @param {Boolean} disabled disabled link if necessary
 */
class MenuItemCart extends React.Component {
  render() {
    const { link, countValue, totalCart, disabled } = this.props;
    return (
      <Nav className="mx-auto w-100">
        <Nav.Item className="mx-auto w-100">
          {countValue > 0 && (
            <Card bg="light ms-auto me-3" style={{ width: "fit-content" }}>
              <Card.Body className="p-1 px-2 text-nowrap">
                <Row>
                  <Col>
                    <Icon icon="shopping-cart" className="me-1" />
                    {countValue > 0 && (
                      <Badge bg="success" pill>
                        {countValue}
                      </Badge>
                    )}
                  </Col>
                  <Col className="text-end">
                    <FormattedMessage id="Total" className="ms-auto" />:{" "}
                    <strong className="text-dark">
                      {PriceUtil.formatEuro(totalCart)}
                    </strong>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col className="text-center">
                    <NavLink
                      to={link}
                      className="cart-item"
                      disabled={disabled}
                    >
                      <MenuButton size="sm">
                        <FormattedMessage id="Go.To.Cart" />
                      </MenuButton>
                    </NavLink>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Nav.Item>
      </Nav>
    );
  }
}

MenuItemCart.propTypes = propTypes;

export default withRouter(MenuItemCart);
