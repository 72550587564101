import { injectIntl } from "react-intl";
import Select from "./Select";

const genderList = {
  Man: 1,
  Woman: 2,
};

const SelectGender = ({ id, selectedOption, onOptionChange }) => {
  return (
    <Select
      id={id}
      options={genderList}
      selectedOption={selectedOption}
      onOptionChange={onOptionChange}
    />
  );
};

export default injectIntl(SelectGender);
