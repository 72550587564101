import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Tab, Tabs } from "react-bootstrap";

import AuditDeskSettings from "../auditSettings/AuditDeskSettings";
import DeskCategories from "../auditDesk/DeskCategories";

class AuditDesk extends React.Component {
  render() {
    const { intl, auditDeskSettings } = this.props;

    return (
      <Tabs defaultActiveKey="desk-param" id="auditDeskTabs">
        <Tab
          eventKey="desk-param"
          title={intl.formatMessage({ id: "PDF.Params" })}
        >
          <AuditDeskSettings auditDeskSettings={auditDeskSettings} />
        </Tab>
        <Tab
          eventKey="desk-categ"
          title={intl.formatMessage({ id: "Categories.Desk" })}
        >
          <DeskCategories />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditDeskSettings: state.auditDeskSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditDesk));
