const HomeProductMapping = {
  name: "NOM",
  ref: "REF",
  refundable: "REMBOURSABLE",
  type: "TYPE",
  prescription_type_vte_text: "PRESCRIPTION TYPE VTE",
  prescription_type_loc_text: "PRESCRIPTION TYPE LOC",
  purchase_renewal: "RENOUVELLEMENT A LA VENTE",
  is_renewal_obligatory: "RENOUVELLEMENT OBLIGATOIRE",
  text: "DESCRIPTIF",
  room_1: "P1",
  room_2: "P2",
  room_3: "P3",
  room_4: "P4",
  room_5: "P5",
  room_6: "P6",
  room_7: "P7",
  room_8: "P8",
  room_9: "P9",
  room_10: "P10",
  room_11: "P11",
  room_12: "P12",
  room_13: "P13",
  room_14: "P14",
  room_15: "P15",
  checkpoint_1: "C1",
  checkpoint_2: "C2",
  checkpoint_3: "C3",
  checkpoint_4: "C4",
  checkpoint_5: "C5",
  checkpoint_6: "C6",
  checkpoint_7: "C7",
  checkpoint_8: "C8",
  checkpoint_9: "C9",
  checkpoint_10: "C10",
  checkpoint_11: "C11",
  checkpoint_12: "C12",
  checkpoint_13: "C13",
  checkpoint_14: "C14",
  checkpoint_15: "C15",
};

export default HomeProductMapping;
