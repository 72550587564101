import React from "react";
import InputLength from "../../enums/InputLength";
import { Form } from "react-bootstrap";

export default class InputTextElement extends React.Component {
  maxLengthFinder(value) {
    switch (value) {
      case "email":
        return InputLength.EMAIL;
      case "password":
        return InputLength.NAME;
      case "search":
        return InputLength.TEXT;
      case "tel":
        return InputLength.PHONE;
      case "text":
        return InputLength.TEXT;
      case "url":
        return InputLength.URL;
      default:
        return InputLength.TEXT;
    }
  }

  render() {
    const {
      type,
      className,
      placeholder,
      autoComplete,
      rows,
      id,
      onChange,
      onBlur,
      pattern,
      maxLength,
      disabled,
      value,
      defaultValue,
      minLength,
      name,
      size,
      required,
      hidden,
      autoCapitalize,
      min,
      max,
    } = this.props;
    const inputTextTypes = [
      "email",
      "password",
      "search",
      "tel",
      "text",
      "date",
      "number",
      "url",
    ];
    const typeMaxLength = inputTextTypes.includes(type)
      ? maxLength
        ? maxLength
        : this.maxLengthFinder(type)
      : null;

    return (
      <React.Fragment>
        {inputTextTypes.includes(type) && (
          <Form.Control
            type={type}
            className={className}
            autoComplete={autoComplete}
            rows={rows}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            maxLength={typeMaxLength}
            pattern={pattern}
            placeholder={placeholder}
            autoCapitalize={autoCapitalize}
            required={required}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            minLength={minLength}
            name={name}
            size={size}
            hidden={hidden}
            min={min}
            max={max}
          ></Form.Control>
        )}
      </React.Fragment>
    );
  }
}
