const PatientMapping = {
  name: "NOM",
  first_name: "PRENOM",
  birth_date: "DATE DE NAISSANCE",
  address: "ADRESSE",
  addressAdditionnal: "COMPL. ADRESSE",
  postal_code: "CODE POSTAL",
  city: "VILLE",
  phone: "TELEPHONE",
  mobile: "TEL. PORTABLE",
  email: "EMAIL",
  accessPHARMA: "ACCES PHARMA MAD",
  notifyByEmail: "NOTIFIER PAR EMAIL",
  codeCRM: "CODE CRM",
};

export default PatientMapping;
