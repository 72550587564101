import React from "react";

import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";

import { FormattedMessage } from "react-intl";
import { NotificationManager } from "react-notifications";
import Icon from "../Icon";

export default class MenuButton extends React.Component {
  setAdditionalClasses() {
    return this.props.className
      ? "text-nowrap " + this.props.className
      : "text-nowrap";
  }

  // Avoid Warning like "Expected `onClick` listener to be a function, instead got `false`" if onClick no set for MenuButton.
  onClickVoid() {}

  setOnclick() {
    return this.props.onClick || this.onClickVoid();
  }

  setDisabled() {
    return this.props.disabled || false;
  }

  render() {
    /**
     * icon : The name like provided by fontawesome : https://fontawesome.com/docs/web/use-with/react/
     * className : Additionnal bootstrap className
     * disabled : icon looks and acts as disabled
     * id : icon (element) id
     * onClick : icon onclik action
     * variant : Bootstrap variant
     * size : Bootstrap button size
     */
    const { id, icon, size, processing, hover, placement, type } = this.props;

    let variant = this.props.variant || "primary";

    const iconElement = icon ? (
      <Icon
        icon={processing ? "fa-solid fa-circle-notch" : icon}
        spin={processing}
        fixedWidth={true}
      />
    ) : (
      ""
    );

    processing &&
      NotificationManager.info(
        <FormattedMessage id="Plz.Wait" />,
        <FormattedMessage id="Processing" />,
        2000,
      );

    return (
      <React.Fragment>
        {hover && (
          <OverlayTrigger
            delay={{ hide: 0, show: 0 }}
            overlay={(props) => <Tooltip {...props}>{hover}</Tooltip>}
            placement={placement || "top"}
          >
            <Button
              variant={variant}
              className={this.setAdditionalClasses()}
              onClick={this.setOnclick()}
              disabled={this.setDisabled()}
              size={size || ""}
            >
              {iconElement}
              {this.props.children !== undefined ? " " : ""}
              {this.props.children}
            </Button>
          </OverlayTrigger>
        )}

        {!hover && (
          <Button
            id={id}
            variant={variant}
            className={this.setAdditionalClasses()}
            onClick={this.setOnclick()}
            disabled={this.setDisabled()}
            size={size || ""}
            type={type}
          >
            {iconElement}
            {this.props.children !== undefined ? " " : ""}
            {this.props.children}
          </Button>
        )}
      </React.Fragment>
    );
  }
}
