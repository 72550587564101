// Configuration for defined CRM providers
const CrmProvidersConfig = [
  {
    name: "MUST INFORMATIQUE",
    software: "Must G5",
    mercurialFields: [],
  },
  {
    name: "LOMACO",
    software: "Lomaco",
    mercurialFields: [],
  },
  {
    name: "HABY",
    software: "Haby",
    mercurialFields: [{ test_haby: "TEST HABY" }],
  },
];

// Configuration for defined CRM providers
const CrmProviders = {
  MUST: CrmProvidersConfig[0],
  LOMACO: CrmProvidersConfig[1],
  HABY: CrmProvidersConfig[2],
};

module.exports = { CrmProviders, CrmProvidersConfig };
