import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addTypeofControl,
  updateTypeofControl,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import { Col, Form, Modal, Row } from "react-bootstrap";
import TextareaCounter from "../../../sub/TextareaCounter";
import CustomLabel from "../../../sub/CustomLabel";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskProductsControlPointsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      description:
        this.props.index !== undefined
          ? this.props.controlPoints[this.props.index]
          : "",
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  confirm() {
    let data = {
      deskProductId: this.props.deskProductId,
      type: this.props.controlPointType,
      description: this.state.description,
    };

    if (this.props.index !== undefined) {
      data.index = this.props.index;
      this.props.onUpdateTypeOfControl(data, () => this.props.closeModal());
    } else {
      this.props.onAddTypeOfControl(data, () => this.props.closeModal());
    }
  }

  getModalTitle(index) {
    let id = "";
    if (index !== undefined) {
      id =
        this.props.controlPointType === "question"
          ? "Update.Question"
          : "Update.Recommendation";
    } else {
      id =
        this.props.controlPointType === "question"
          ? "Add.Question"
          : "Add.Recommendation";
    }

    return <FormattedMessage id={id} />;
  }

  render() {
    const { index } = this.props;

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.getModalTitle(index)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row}>
            <Col sm={2}>
              <CustomLabel
                label={<FormattedMessage id="Titled" />}
                required
                htmlFor="description"
              />
            </Col>
            <Col>
              <TextareaCounter
                countLimit={200}
                rows={3}
                id="description"
                value={this.state.description}
                onChange={(e) => this.onChange("description", e.target.value)}
              />
            </Col>
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.confirm()}
            disabled={this.state.disabled}
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddTypeOfControl: (data, successCallback) =>
      dispatch(addTypeofControl(data, successCallback)),
    onUpdateTypeOfControl: (data, successCallback) =>
      dispatch(updateTypeofControl(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsControlPointsModal));
