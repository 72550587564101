import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../../../util/Util";
import CustomLabel from "../../../sub/CustomLabel";
import { Modal, Form, Row } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton";

function CreateRoomAssociationModal({
  auditDMId,
  auditRooms,
  addRoom,
  close,
  intl,
}) {
  const [roomId, setRoomId] = useState("");

  const add = () => {
    addRoom(roomId.trim());
  };

  const onChange = (value) => {
    setRoomId(value);
  };

  const roomSelectOptions =
    auditRooms &&
    auditRooms.map((auditRoom) => {
      return (
        <option key={auditRoom._id} value={auditRoom._id}>
          {auditRoom.name}{" "}
        </option>
      );
    });

  return (
    <Modal
      show={true}
      onHide={() => close()}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="Add" />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group as={Row} className="align-items-center">
          <div id="room" className="col-sm-4">
            <CustomLabel
              label={intl.formatMessage({ id: "Description" })}
              displayAsCol
              htmlFor="room"
              required={true}
            />
          </div>
          <div id="room" className="col-sm-8">
            <select
              id="room-id-material-dm"
              className="form-control w-100"
              value={roomId}
              onChange={(e) => onChange(e.target.value)}
            >
              <option value="">{intl.formatMessage({ id: "Select" })}</option>
              {roomSelectOptions}
            </select>
          </div>
        </Form.Group>
      </Modal.Body>

      {!auditDMId && (
        <Modal.Footer>
          <MenuButton variant="secondary" onClick={() => close()}>
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton onClick={() => add()} disabled={Util.emptyString(roomId)}>
            <FormattedMessage id="Add" />
          </MenuButton>
        </Modal.Footer>
      )}
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CreateRoomAssociationModal));
