import { GET_DESK_CATEGORIES } from "../../../actions/settings/deskCategories/admin/deskCategories";

export default function deskCategories(state = [], action) {
  switch (action.type) {
    case GET_DESK_CATEGORIES:
      return action.deskCategories;
    default:
      return state;
  }
}
