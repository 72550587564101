import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addAuditRoom,
  updateAuditRoom,
  getAuditRoom,
  addAuditRoomFile,
} from "../../../../actions/settings/auditRooms/admin/auditRooms";
import TextareaCounter from "../../../sub/TextareaCounter";
import CustomLabel from "../../../sub/CustomLabel";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import InputLength from "../../../../enums/InputLength";
import InputTextElement from "../../../sub/InputTextElement";
import SwitchToggle from "../../../sub/SwitchToggle";
import AuditDM from "../auditDM/AuditDM";
import { Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import Util from "../../../../util/Util";
import APIUrl from "../../../../APIUrl";
import FileDropZone from "../../../sub/FileDropZone";

class AuditRoomsModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      default_text: "",
      nameError: null,
      hidden: false,
      disabled: false,
      modal: null,
    };

    if (this.props.auditRoomId) {
      const room = this.props.auditRooms.find(
        (r) => r._id === this.props.auditRoomId,
      );

      if (room) {
        this.state = {
          name: room.name,
          default_text: room.default_text,
          hidden: room.hidden || false,

          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  close() {
    this.props.closeModal();
  }

  stopEvent(e) {
    // e.preventDefault();
    e.stopPropagation();
  }

  onBlur(updatedField, updatedValue) {
    if (updatedField !== "hidden") {
      if (Util.emptyString(updatedValue)) return;
    }

    const data = {
      auditRoomId: this.props.auditRoomId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    console.log(data);

    if (updatedField === "name") {
      const successCallback = (room) => {
        if (room)
          return this.setState({
            nameError: <FormattedMessage id="Room.Exist" />,
          });

        this.setState({ nameError: null });
        if (this.props.auditRoomId) this.props.onUpdateAuditRoom(data);
      };

      if (this.props.auditRoomId) {
        const room = this.getAuditRoom(this.props.auditRoomId);
        if (room && updatedValue === room.name)
          return this.setState({ nameError: null });
      }
      this.props.onGetAuditRoom(updatedValue, successCallback);
    } else {
      if (!this.props.auditRoomId) return;
      this.props.onUpdateAuditRoom(data);
    }
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  getAuditRoom(auditRoomId) {
    for (let auditRoom of this.props.auditRooms) {
      if (auditRoom._id === auditRoomId) return auditRoom;
    }
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.auditRoomId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      let successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddAuditRoomFile(
        this.props.auditRoomId,
        formData,
        successCallback,
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    if (this.checkErrors()) return;

    let sendfile = (auditRoom) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddAuditRoomFile(auditRoom._id, formData, () =>
        this.props.closeModal(),
      );
    };

    let imageExist = !!this.state.file;

    let data = {
      name: this.state.name.toUpperCase().trim(),
      default_text: this.state.default_text,
      image: imageExist,
      hidden: this.state.hidden,
      sortIndex: this.props.auditRooms.length + 1,
    };

    this.setState({ disabled: true, disabledFileUpload: true });

    this.props.onAddAuditRoom(data, sendfile);
  }

  render() {
    const { intl, auditRoomId } = this.props;

    let target = this.props.target ? this.props.target : "general";

    let img, auditRoom;
    const { auditRooms } = this.props;

    if (auditRoomId) {
      auditRoom = auditRooms.find((r) => r._id === auditRoomId);
      if (!auditRoom) return null;

      let imgSrc =
        APIUrl.getAuditRoomImg +
        auditRoom._id +
        "/" +
        Math.random() +
        "?token=" +
        APIUrl.jwtToken;
      img = (
        <img
          src={imgSrc}
          className="col-form-label m-3"
          alt={"audit_Room_img" + Math.random()}
          height="150"
          width="150"
        />
      );
    }

    return (
      <ConfirmationModal
        size="lg"
        title={
          auditRoomId ? (
            <FormattedMessage id="Audit.Room.Detail" />
          ) : (
            <FormattedMessage id="Add.Room" />
          )
        }
        onAccept={() => (!auditRoomId ? this.onSubmit() : this.close())}
        confirmButtonLabel={
          auditRoomId ? (
            <FormattedMessage id="Close" />
          ) : (
            <FormattedMessage id="Create" />
          )
        }
        confirmButtonDisabled={this.checkErrors()}
        onDecline={() => this.close()}
        cancelButtonDisabled={auditRoomId}
      >
        <Tabs defaultActiveKey={"settings-" + target} id="settingsTabs">
          <Tab
            eventKey="settings-general"
            title={intl.formatMessage({ id: "General.Informations" })}
          >
            {auditRoom && (
              <Row className="align-items-center mb-3">
                <Col className="text-center">
                  <Card>
                    <Card.Body>
                      {img}
                      {auditRoom.image && (
                        <MenuButton
                          icon="trash"
                          variant="outline-dark"
                          onClick={() => this.onBlur("image", false)}
                          hover={
                            <FormattedMessage id="Restore.Default.Image" />
                          }
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={<FormattedMessage id="Logo" />}
                displayAsCol
              />
              <Col md={7}>
                <FileDropZone
                  disabled={this.state.disabledDropZone}
                  onDropFile={(file, clearCallback) =>
                    this.onDropFile(file, clearCallback)
                  }
                  acceptedExtensions={["jpg", "png", "jpeg"]}
                  multiple={false}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={<FormattedMessage id="Name" />}
                displayAsCol
              />
              <Col md={7}>
                <InputTextElement
                  className="form-control"
                  type="text"
                  id="name"
                  autoComplete="off"
                  maxLength={InputLength.NAME}
                  value={this.state.name}
                  onChange={(e) =>
                    this.onChange("name", e.target.value.toUpperCase())
                  }
                  onBlur={(e) =>
                    this.onBlur("name", this.state.name.toUpperCase())
                  }
                />
                <small className="text-danger">{this.state.nameError}</small>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={<FormattedMessage id="Default.Text" />}
                displayAsCol
              />
              <Col md={7}>
                <TextareaCounter
                  countLimit={InputLength.TEXT_LONG}
                  value={this.state.default_text}
                  id="defaultText"
                  rows="10"
                  placeholder={this.default_text}
                  onChange={(e) =>
                    this.onChange("default_text", e.target.value)
                  }
                  onBlur={() =>
                    this.onBlur("default_text", this.state.default_text)
                  }
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="align-items-center mb-3">
              <CustomLabel
                label={<FormattedMessage id="Audit.Room.Hidden" />}
                htmlFor={"hidden-switch"}
                displayAsCol
              />
              <Col md={7}>
                <SwitchToggle
                  id="hidden-switch"
                  checked={this.state.hidden}
                  onChange={(e) => {
                    this.onChange("hidden", !this.state.hidden);
                    this.onBlur("hidden", !this.state.hidden);
                  }}
                />
              </Col>
            </Form.Group>
          </Tab>
          <Tab
            eventKey="settings-dmassociated"
            disabled={!auditRoomId}
            title={<FormattedMessage id="Audit.Dm.list" />}
          >
            {auditRoomId && <AuditDM auditRoomId={auditRoomId} />}
          </Tab>
        </Tabs>
      </ConfirmationModal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditRooms: state.auditRooms,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddAuditRoom: (data, successCallback) =>
      dispatch(addAuditRoom(data, successCallback)),
    onGetAuditRoom: (data, successCallback) =>
      dispatch(getAuditRoom(data, successCallback)),
    onUpdateAuditRoom: (data) => dispatch(updateAuditRoom(data)),
    onAddAuditRoomFile: (auditRoomId, data, successCallback) =>
      dispatch(addAuditRoomFile(auditRoomId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditRoomsModalAdmin));
