import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Col, InputGroup, Modal, Row } from "react-bootstrap";
import CustomLabel from "../sub/CustomLabel";
import { connect } from "react-redux";
import Maths from "../../util/Maths";
import InputNumber from "../sub/fields/input/InputNumber";
import { NotificationManager } from "react-notifications";
import { productsMarginUpdate } from "../../actions/products/products";
import Icon from "../sub/Icon";
import MenuButton from "../sub/bootstrap/MenuButton";

class ProductsMarginModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      taux_marge: "",
      taux_marque: "",
      margeError: "",
    };
  }

  onSubmit() {
    if (this.disabled()) return;

    const displayNotification = () => {
      NotificationManager.success(<FormattedMessage id="Products.Updated" />);
    };

    const successCallback = () => {
      displayNotification();
      this.props.closeModal();
    };

    const marginValue =
      this.props.productsSettingsAdmin.margin_selected === "margin"
        ? this.state.taux_marge
        : this.state.taux_marque;

    const data = {
      mercurialId: this.props.mercurialId,
      familyId: this.props.famille._id,
      marginSelected: this.props.productsSettingsAdmin.margin_selected,
      margin: marginValue,
    };

    this.props.onProductsMarginUpdate(data, successCallback);
  }

  disabled() {
    return (
      (this.props.productsSettingsAdmin.margin_selected === "margin" &&
        this.state.taux_marge === "") ||
      (this.props.productsSettingsAdmin.margin_selected !== "margin" &&
        this.state.taux_marque === "")
    );
  }

  checkMargin(margin, marginMin, marginMax, messageId) {
    if (margin < marginMin || margin > marginMax) {
      this.setState({
        margeError: (
          <FormattedMessage
            id={messageId}
            values={{
              min: marginMin,
              max: marginMax,
            }}
          />
        ),
      });
    } else {
      this.setState({ margeError: "" });
    }
  }

  checkMarginsRate(marginRate, grossMarginRate) {
    let marginRateToCheck = marginRate;
    let rateMin = this.props.productsSettingsAdmin.margin_rate_min;
    let rateMax = this.props.productsSettingsAdmin.margin_rate_max;
    let errorMsg = "Margin.Rate.Out.Of.Bounds";

    if (this.props.productsSettingsAdmin.margin_selected !== "margin") {
      marginRateToCheck = grossMarginRate;
      rateMin = this.props.productsSettingsAdmin.gross_margin_rate_min;
      rateMax = this.props.productsSettingsAdmin.gross_margin_rate_max;
      errorMsg = "Gross.Margin.Rate.Out.Of.Bounds";
    }

    this.checkMargin(marginRateToCheck, rateMin, rateMax, errorMsg);
  }

  onChange(field, value) {
    this.setState({ [field]: Maths.round(value, 2) }, () => {
      this.checkMarginsRate(this.state.taux_marge, this.state.taux_marque);
    });
  }

  render() {
    const getAlert = (context, title, error) => (
      <Row className="mt-3 mb-1">
        <Col>
          <Alert variant={context}>
            <p>
              <strong>
                <FormattedMessage id={title} />
              </strong>
            </p>
            {error}
          </Alert>
        </Col>
      </Row>
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop="static"
        size="md"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Products.Update" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="mt-1 mb-2">
            <Col>
              <Alert variant="warning">
                <h5>
                  <Icon icon="triangle-exclamation" className="me-2" />
                  <FormattedMessage id="Warning" />
                </h5>
                <p>
                  <FormattedMessage id="Products.Update.Info" />
                </p>
              </Alert>
            </Col>
          </Row>

          {this.props.productsSettingsAdmin.margin_selected === "margin" && (
            <Row>
              <Col>
                <CustomLabel
                  label={<FormattedMessage id="Margin.Rate" />}
                  htmlFor="margin_rate"
                />
              </Col>
              <Col>
                <InputGroup style={{ borderBottom: 0 }} id="margin_rate">
                  <InputNumber
                    id={"inputGroupMarginRate"}
                    min={1}
                    defaultValue={this.state.taux_marge}
                    onChange={(value) => this.onChange("taux_marge", value)}
                  />
                  <InputGroup.Text id="inputGroupMarginRate">%</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          )}

          {this.props.productsSettingsAdmin.margin_selected !== "margin" && (
            <Row>
              <Col>
                <CustomLabel
                  label={<FormattedMessage id="Gross.Margin.Rate" />}
                  htmlFor="gross_margin_rate"
                />
              </Col>
              <Col>
                <InputGroup style={{ borderBottom: 0 }} id="gross_margin_rate">
                  <InputNumber
                    id={"inputGroupGrossMarginRate"}
                    min={1}
                    max={99}
                    defaultValue={this.state.taux_marque}
                    onChange={(value) => this.onChange("taux_marque", value)}
                  />
                  <InputGroup.Text id="inputGroupGrossMarginRate">
                    %
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
          )}

          {this.state.margeError &&
            getAlert("warning", "Warning", this.state.margeError)}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </MenuButton>
          <MenuButton
            onClick={() => this.onSubmit()}
            disabled={this.disabled()}
          >
            <FormattedMessage id="Confirm" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productsSettingsAdmin: state.productsSettingsAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onProductsMarginUpdate: (data, successCallback) =>
      dispatch(productsMarginUpdate(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductsMarginModal));
