import React from "react";
import { FormattedMessage } from "react-intl";
import { withRouter, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Badge, Nav } from "react-bootstrap";

const propTypes = {
  link: PropTypes.string.isRequired,
  formattedMessageId: PropTypes.string.isRequired,
  isCountBadge: PropTypes.bool,
  countValue: PropTypes.number,
};

const defaultProps = {
  isCountBadge: false,
  countValue: -1,
};

/**
 * NavLink item for user Navbar
 * @param {String} link navlink to redirect
 * @param {String} formattedMessageId id to get formatted message
 * @param {Boolean} isCountBadge set true/false to activate badge danger
 * @param {Number} countValue value to display on badge danger
 */
class MenuItem extends React.Component {
  render() {
    const { link, formattedMessageId, isCountBadge, countValue, event } =
      this.props;
    return (
      <Nav.Item className="me-1">
        <NavLink
          className="nav-link p-2 pt-3 pb-3"
          to={link}
          exact
          activeClassName="selected"
          onClick={event}
        >
          <FormattedMessage id={formattedMessageId} />
          {isCountBadge && countValue > 0 && (
            <Badge pill bg="info" className="ms-1 border border-white">
              {countValue}
            </Badge>
          )}
        </NavLink>
      </Nav.Item>
    );
  }
}

MenuItem.propTypes = propTypes;
MenuItem.defaultProps = defaultProps;

export default withRouter(MenuItem);
