import { FormSelect } from "react-bootstrap";
import { injectIntl } from "react-intl";

const Select = ({ id, options, selectedOption, onOptionChange, intl }) => {
  // Function to handle the change in select list
  const handleSelectChange = (event) => {
    const newOption = event.target.value;
    onOptionChange(newOption);
  };

  // Convert JSON option to array
  const optionsArray = Object.entries(options).map(([label, value]) => ({
    label: intl.formatMessage({ id: label }),
    value,
  }));

  return (
    <FormSelect id={id} value={selectedOption} onChange={handleSelectChange}>
      <option value="">{intl.formatMessage({ id: "Select" })}</option>
      {optionsArray.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </FormSelect>
  );
};

export default injectIntl(Select);
