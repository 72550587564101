import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COORDINATION = "GET_COORDINATION";
export const GET_COORDINATION_NOTIF = "GET_COORDINATION_NOTIF";

export function getCoordinationAction(coordination) {
  return { type: GET_COORDINATION, coordination: coordination };
}

export function getCoordinationNotifAction(coordination) {
  return { type: GET_COORDINATION_NOTIF, coordination: coordination };
}
export const createMessage = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.createMessage, data)
      .then(function (response) {
        dispatch(getMessages({ patient_id: response.patient_id }));
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getMessages = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMessages, data)
      .then(function (response) {
        dispatch(getCoordinationAction(response.data));
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateMessage = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateMessage, data)
      .then(function (response) {
        dispatch(getMessages(response.patient_id));
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteMessage = function (data) {
  return async function () {
    try {
      return await axios.post(APIUrl.deleteMessage, data);
    } catch (err) {
      throw err;
    }
  };
};

//** Coordination Notification */

export const messagesNotifsPatients = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getMessageNotificationByPatientIds)
      .then(function (response) {
        return dispatch(getCoordinationNotifAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const messageNotifPatientById = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMessageNotificationByPatientId, data)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateSeen = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateSeenMessages, data)
      .then(function (response) {})
      .catch(function (err) {
        throw err;
      });
  };
};
