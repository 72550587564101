import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import OperationPromotionalModal from "./OperationPromotionalModal";
import PromotionalOperationsRow from "./PromotionalOperationsRow";
import { deletePromoOperation } from "../../../../actions/promoOperations/promoOperations";
// import OperationPromotionalModal from './PrescriberTypeModalAdmin';
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import { Col, Row } from "react-bootstrap";
// import { deletePrescriberType, updatePrescriberType } from "../../../../actions/settings/prescriberType/admin/prescriberType";

class PromotionalOperations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  editPromotionalOperationModal(promoOperation) {
    // Destroy previous opened modal before to avoid data persistance
    this.closeModal();

    this.setState({
      modal: (
        <OperationPromotionalModal
          promoOperationId={promoOperation._id}
          promoOperation={promoOperation}
          mercurial_id={promoOperation.mercurial_id}
          concernedProduct={this.getProduct(
            promoOperation.product_id,
            promoOperation.mercurial_id,
          )}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  deletePromotionalOperationModal(operationPromotionalId) {
    const deleteCbk = () =>
      this.props.onDeletePromoOperation({
        operationPromotionalId: operationPromotionalId,
      });

    this.setState({
      modal: (
        <ConfirmationModal
          variant="warning"
          onAccept={deleteCbk}
          onDecline={() => this.closeModal()}
        >
          <FormattedMessage id={"Promotional.Operation.Remove.Confirmation"} />
        </ConfirmationModal>
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openOperationPromotionalModal() {
    // Destroy previous opened modal before to avoid data persistance
    this.closeModal();

    this.setState({
      modal: (
        <OperationPromotionalModal
          required={true}
          editPromotionalOperationModal={(operationPromotional) =>
            this.editPromotionalOperationModal(operationPromotional)
          }
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  getProduct(productId, mercurialId) {
    for (var product of this.props.products) {
      if (product._id === productId && product.mercurial_id === mercurialId) {
        return product;
      }
    }
  }

  render() {
    if (this.props.promoOperations.length === 0) {
      return (
        <TableToolbar message={<FormattedMessage id="No.Promo" />}>
          <Row>
            <Col className="text-end">
              <MenuButton
                onClick={(e) => this.openOperationPromotionalModal(e)}
              >
                <FormattedMessage id="Add.Promotional.Operation" />
              </MenuButton>
            </Col>
          </Row>
          {this.state.modal}
        </TableToolbar>
      );
    }

    var PromotionalOperationsRowNode = this.props.promoOperations.map(
      (promoOperation, index) => {
        var product = this.getProduct(
          promoOperation.product_id,
          promoOperation.mercurial_id,
        );
        var mercurial;
        if (this.props.mercurials) {
          mercurial = this.props.mercurials.find(
            (p) => p._id === promoOperation.mercurial_id,
          );
        }
        return (
          <PromotionalOperationsRow
            key={index}
            keyId={promoOperation._id}
            product={product}
            mercurial={mercurial}
            promoOperation={promoOperation}
            editPromotionalOperationModal={(operationPromotional) =>
              this.editPromotionalOperationModal(operationPromotional)
            }
            deletePromotionalOperationModal={(operationPromotionalId) =>
              this.deletePromotionalOperationModal(operationPromotionalId)
            }
          />
        );
      },
    );

    return (
      <React.Fragment>
        {this.state.modal}

        <TableToolbar>
          <Row>
            <Col className="text-end">
              <MenuButton
                onClick={(e) => this.openOperationPromotionalModal(e)}
              >
                <FormattedMessage id="Add.Promotional.Operation" />
              </MenuButton>
            </Col>
          </Row>
        </TableToolbar>

        <table className="table table-striped tablee4mad">
          <thead>
            <tr>
              <th scope="col" className="col-4">
                <FormattedMessage id="Operation.Name" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Start.Date" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="End.Date" />
              </th>
              <th scope="col" className="col">
                <FormattedMessage id="Documentation" />
              </th>
              <th className="col-1 text-center">
                <FormattedMessage id="Actions" />
              </th>
            </tr>
          </thead>
          <tbody>{PromotionalOperationsRowNode}</tbody>
        </table>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeletePromoOperation: (operationPromotionalId) =>
      dispatch(deletePromoOperation(operationPromotionalId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromotionalOperations);
