import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { Doughnut, Bar, Line } from "react-chartjs-2";
import "chart.js/auto";

import PriceUtil from "../../util/PriceUtil";
import { Alert, Col, Container, Row } from "react-bootstrap";
import ProductsParserUtil from "../../util/ProductsParserUtil";

class TopProductsBudget extends React.Component {
  buildChartData(priceUtilPerProduct, isLine = false) {
    let priceUtilData = [];
    for (let id of Object.keys(priceUtilPerProduct)) {
      if (this.props.graphData === "quantity")
        priceUtilData.push({
          id: id,
          product: priceUtilPerProduct[id].store[0].product,
          nbr: priceUtilPerProduct[id].quantity(),
        });
      else if (this.props.graphData === "totalHT")
        priceUtilData.push({
          id: id,
          product: priceUtilPerProduct[id].store[0].product,
          nbr: priceUtilPerProduct[id].totalHt(),
        });
      else if (this.props.graphData === "totalTTC")
        priceUtilData.push({
          id: id,
          product: priceUtilPerProduct[id].store[0].product,
          nbr: priceUtilPerProduct[id].totalTtc(),
        });
    }

    priceUtilData = priceUtilData.sort(this.sortByNbr);
    priceUtilData = priceUtilData.slice(0, 10);

    let data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],
          borderColor: [],
          borderWidth: [],
        },
      ],
    };

    for (let product of priceUtilData) {
      // Label
      data.labels.push(
        "[" +
          product.product.famille +
          "] " +
          ProductsParserUtil.swapDesignationDelimiter(
            product.product.designation,
          ),
      );

      // Data
      data.datasets[0].data.push(product.nbr);

      // BG color
      let color = Util.stringToColor(product.id);
      if (!isLine) data.datasets[0].backgroundColor.push(color);
      else {
        data.datasets[0].pointBackgroundColor.push(color);
        data.datasets[0].pointBorderColor.push(color);
        data.datasets[0].backgroundColor.push("rgba(0, 0, 0, 0)");
        data.datasets[0].borderColor.push(color);
        data.datasets[0].borderWidth.push(5);
      }
    }

    return data;
  }

  sortByNbr(obj1, obj2) {
    if (obj1.nbr > obj2.nbr) return -1;
    if (obj1.nbr < obj2.nbr) return 1;
    return 0;
  }

  render() {
    if (!this.props.orders) return null;

    // Gather up all products from all targeted orders
    let orderProducts = [];
    for (let order of this.props.orders) {
      // Careful with start & end dates
      if (
        new Date(order.creation_date) < new Date(this.props.startDate) ||
        new Date(order.creation_date) > new Date(this.props.endDate)
      )
        continue;

      for (let product of order.products) orderProducts.push(product);
    }

    let priceUtilPerProduct = {};

    for (let orderProduct of orderProducts) {
      if (!priceUtilPerProduct[orderProduct.designation])
        priceUtilPerProduct[orderProduct.designation] = new PriceUtil();
      priceUtilPerProduct[orderProduct.designation].add(
        orderProduct,
        orderProduct.quantity,
      );
    }

    let options = {
      legend: {
        display: this.props.graphType === "pie",
      },
    };

    if (this.props.graphType !== "pie") {
      options.scales = {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      };
    }

    if (
      this.props.graphData === "totalHT" ||
      this.props.graphData === "totalTTC"
    ) {
      options.tooltips = {
        enabled: true,
        mode: "single",
        callbacks: {
          label: function (tooltipItems, data) {
            let value = tooltipItems.yLabel;
            if (!value) value = data.datasets[0].data[tooltipItems.index];

            if (!tooltipItems.label)
              return (
                " " +
                data.labels[tooltipItems.index] +
                " : " +
                PriceUtil.formatEuro(value)
              );
            return PriceUtil.formatEuro(value);
          },
        },
      };
    }

    return (
      <Container fluid>
        {Object.keys(priceUtilPerProduct).length === 0 ? (
          <Row>
            <Col>
              <Alert variant="secondary">
                <FormattedMessage id="Empty.Stats.Orders" />
              </Alert>
            </Col>
          </Row>
        ) : (
          <Row className="pt-3 justify-content-center">
            <Col md={6} className="text-center">
              {this.props.graphType === "pie" && (
                <Doughnut
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerProduct)}
                />
              )}
              {this.props.graphType === "bar" && (
                <Bar
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerProduct)}
                />
              )}
              {this.props.graphType === "line" && (
                <Line
                  key={Math.random()}
                  options={options}
                  data={this.buildChartData(priceUtilPerProduct, true)}
                />
              )}
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

export default injectIntl(TopProductsBudget);
