import { GET_ADMIN_PRODUCTS_SETTINGS } from "../../../../actions/settings/productsSettings/admin/productsSettings";

export default function productsSettingsAdmin(state = [], action) {
  switch (action.type) {
    case GET_ADMIN_PRODUCTS_SETTINGS:
      return action.productsSettingsAdmin;
    default:
      return state;
  }
}
