import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
class AuditCommentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  render() {
    const { audit, auditDMs } = this.props;

    let comments = [];
    let rawcomments = [];

    if (audit.private_comments && audit.private_comments.length) {
      audit.private_comments.map((cmt) => {
        if (cmt && cmt.audit_dm_id && cmt.comment) {
          //if (cmt && cmt.audit_dm_id) {
          let DM = auditDMs.find((ele) => {
            return (
              ele._id === cmt.audit_dm_id && ele.currentRoom._id === cmt.room_id
            );
          });

          if (DM.currentRoom && DM.currentRoom.sortIndex) {
            if (!rawcomments[DM.currentRoom.sortIndex]) {
              rawcomments[DM.currentRoom.sortIndex] = {
                name: DM.currentRoom.name,
                dms: [],
              };
            }
            rawcomments[DM.currentRoom.sortIndex].dms.push({
              name: DM.name,
              comment: cmt.comment,
            });
          }
        }
        return [];
      });

      for (let roomref in rawcomments) {
        for (let dm in rawcomments[roomref].dms) {
          comments.push({
            room: rawcomments[roomref].name,
            dm: rawcomments[roomref].dms[dm].name,
            comment: rawcomments[roomref].dms[dm].comment,
          });
        }
      }
    }

    let commentsAuditsNode = null;

    if (comments.length > 0) {
      commentsAuditsNode = comments.map((cmt, idx) => {
        return (
          <tr key={"comment-" + idx}>
            <td className="align-middle">{cmt.room}</td>
            <td className="align-middle">{cmt.dm}</td>
            <td className="align-middle">{cmt.comment}</td>
          </tr>
        );
      });
    }
    return (
      <Modal
        show={this.state.show}
        size="xl"
        backdrop="static"
        onHide={() => this.props.close()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Internal.Comment" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-striped tablee4mad">
            <thead>
              <tr className="tablerowhead">
                <th>
                  <FormattedMessage id="Audit.Rooms" />
                </th>
                <th>
                  <FormattedMessage id="Medical.Dm" />
                </th>
                <th className="col-6">
                  <FormattedMessage id="Internal.Comment" />
                </th>
              </tr>
            </thead>
            <tbody>{commentsAuditsNode}</tbody>
          </table>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditDMs: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuditCommentModal);
