import { Pagination } from "react-bootstrap";
import Icon from "./Icon.js";

const CustomPagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onChange,
  variant = "primary",
}) => {
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);
  const pageItems = [];

  const handleChange = (pageNumber) => {
    onChange(pageNumber);
  };

  for (let i = 1; i <= totalPages; i++) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={i === activePage}
        onClick={() => handleChange(i)}
        className={`page-item-${variant} ${i === activePage ? `bg-${variant} text-white` : `text-${variant}`}`}
      >
        {i}
      </Pagination.Item>,
    );
  }

  return (
    <Pagination className={`justify-content-center pagination-${variant}`}>
      <Pagination.First
        onClick={() => handleChange(1)}
        className={`text-${variant}`}
      >
        <Icon icon="caret-left" />
        <Icon icon="caret-left" />
      </Pagination.First>
      <Pagination.Prev
        onClick={() => handleChange(Math.max(activePage - 1, 1))}
        className={`text-${variant}`}
      >
        <Icon icon="caret-left" />
      </Pagination.Prev>
      {pageItems.slice(
        Math.max(0, activePage - Math.floor(pageRangeDisplayed / 2)),
        Math.min(totalPages, activePage + Math.floor(pageRangeDisplayed / 2)),
      )}
      <Pagination.Next
        onClick={() => handleChange(Math.min(activePage + 1, totalPages))}
        className={`text-${variant}`}
      >
        <Icon icon="caret-right" />
      </Pagination.Next>
      <Pagination.Last
        onClick={() => handleChange(totalPages)}
        className={`text-${variant}`}
      >
        <Icon icon="caret-right" />
        <Icon icon="caret-right" />
      </Pagination.Last>
    </Pagination>
  );
};

export default CustomPagination;
