import React from "react";
import { connect } from "react-redux";
import {
  getMaintenances,
  deleteMaintenance,
} from "../../actions/maintenances/maintenances";
import DateUtil from "../../util/DateUtil";
import EqState from "../../enums/EqState";
import { Badge } from "react-bootstrap";
import { injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import Role from "../../enums/Roles";
import { FormattedMessage } from "react-intl";
import ActionMenu from "../sub/ActionMenu";
class MaintenanceRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disableExport: false,
    };
  }

  deleteMaintenance(maintenance, patientId) {
    let data = { maintenance: maintenance, patient_id: patientId };
    const successCallback = () => {
      this.props.onDeleteMaintenance(data, this.props.closeModal());
    };
    this.props.setModal(
      <ConfirmationModal
        title={<FormattedMessage id="Warning" />}
        onAccept={successCallback}
        onDecline={() => this.props.closeModal()}
        mandatoryConfirmation
      >
        <FormattedMessage id="Confirm.Maintenance.Removal" />
      </ConfirmationModal>,
    );
  }

  render() {
    var maintenance = this.props.maintenance;
    var maintenanceDate = null;

    let alert = (
      <Badge bg="success" className="w-100">
        {this.props.intl.formatMessage({
          id: "EqState." + maintenance.general_state,
        })}
      </Badge>
    );

    switch (true) {
      case maintenance.general_state === EqState.AVERAGE:
        alert = (
          <Badge bg="warning" className="w-100">
            {this.props.intl.formatMessage({
              id: "EqState." + maintenance.general_state,
            })}
          </Badge>
        );
        break;
      case maintenance.general_state === EqState.BAD:
        alert = (
          <Badge bg="danger" className="w-100">
            {this.props.intl.formatMessage({
              id: "EqState." + maintenance.general_state,
            })}
          </Badge>
        );
        break;
      default:
        break;
    }

    maintenanceDate =
      maintenance.signature_date === undefined
        ? DateUtil.toDate(maintenance.creation_date)
        : DateUtil.toDate(maintenance.signature_date);

    let menuItems = [];

    menuItems.push(
      {
        icon: "file-pdf",
        action: () =>
          window.open(
            encodeURI(
              APIUrl.getMaintenancesPDF +
                this.props.patient._id +
                "/" +
                maintenance._id +
                "?token=" +
                APIUrl.jwtToken,
            ),
            "_system",
            "transitionstyle=fliphorizontal",
          ),
        text: <FormattedMessage id="Display.PDF" />,
        disabled: this.state.disableExport,
      },
      this.props.user.role !== Role.CLIENT && {
        icon: "trash",
        text: <FormattedMessage id="Delete" />,
        action: () =>
          this.deleteMaintenance(maintenance, this.props.patient._id),
        disabled: this.state.disableExport,
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <tr key={maintenance._id}>
        <td className="align-middle">{maintenanceDate}</td>
        <td className="align-middle">{alert}</td>
        <td className=" text-center">{menuAction}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMaintenance: (data, successCallback) =>
      dispatch(deleteMaintenance(data, successCallback)),
    onGetMaintenances: (equipmentId) => dispatch(getMaintenances(equipmentId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(MaintenanceRow));
