import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import TextareaCounter from "../../sub/TextareaCounter";
import FileDropZone from "../../sub/FileDropZone";
import {
  updateGeneralSettings,
  addLogo,
} from "../../../actions/settings/generalSettings/generalSettings";
import APIUrl from "../../../APIUrl";
import CustomLabel from "../../sub/CustomLabel";
import InputLength from "../../../enums/InputLength";
import InputTextElement from "../../sub/InputTextElement";
import { Card, Col, Container, Form, Image, Row } from "react-bootstrap";
import Fieldset from "../../sub/Fieldset";
import Legend from "../../sub/Legend";
import { FormattedMessage } from "react-intl";

class GeneralSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commercial_sign: this.props.generalSettings.commercial_sign || "",
      contact_information: this.props.generalSettings.contact_information || "",
      phone: this.props.generalSettings.phone || "",
      email: this.props.generalSettings.email || "",
      website: this.props.generalSettings.website || "",
      vatNumber: this.props.generalSettings.vatNumber || "",
      Siret: this.props.generalSettings.Siret || "",
      disabledDropZone: false,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  update(field, value) {
    var data = {
      generalSettingsId: this.props.generalSettings._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateGeneralSettings(data);
  }

  onDropFile(file, clearCallback) {
    let formData = new FormData();
    formData.append(file.name, file);

    this.setState({ disabledDropZone: true });

    var successCallback = () => {
      clearCallback();
      this.setState({ disabledDropZone: false });
    };

    this.props.onAddLogo(
      this.props.generalSettings._id,
      formData,
      successCallback,
    );
  }

  render() {
    if (this.props.generalSettings.length === 0) return null;

    var img = null;
    if (this.props.generalSettings.logo) {
      img = (
        <Image
          thumbnail
          style={{ maxHeight: "10em", maxWidth: "50em" }}
          src={`${APIUrl.getGeneralSettingsLogo}${this.props.user.company_id}/${this.props.generalSettings._id}/${Math.random()}`}
          alt="logo"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/images/no_image_512.png";
          }}
        />
      );
    }
    return (
      <Container className="w-75">
        <Row className="align-items-center mb-3">
          <Col className="text-center">
            <Card>
              <Card.Body>{img}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Identity" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Commercial.Sign" })}
              htmlFor="commercialSign"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="commercialSign"
                maxLength={InputLength.NAME}
                value={this.state.commercial_sign}
                onChange={(e) =>
                  this.onChange("commercial_sign", e.target.value)
                }
                onBlur={(e) =>
                  this.update("commercial_sign", this.state.commercial_sign)
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Logo" })}
              htmlFor="logo"
              displayAsCol
            />
            <Col>
              <FileDropZone
                disabled={this.state.disabledDropZone}
                onDropFile={(file, clearCallback) =>
                  this.onDropFile(file, clearCallback)
                }
                acceptedExtensions={["jpg", "png", "jpeg"]}
                multiple={false}
              />
            </Col>
          </Form.Group>
        </Fieldset>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Contact.Information" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Mailing.Address",
              })}
              htmlFor="contactInformation"
              displayAsCol
            />
            <Col>
              <TextareaCounter
                countLimit={InputLength.TEXT_LONG}
                id="contactInformation"
                name="contactInformation"
                value={this.state.contact_information}
                onChange={(e) =>
                  this.onChange("contact_information", e.target.value)
                }
                onBlur={(e) =>
                  this.update(
                    "contact_information",
                    this.state.contact_information,
                  )
                }
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Phone" })}
              htmlFor="phone"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="phone"
                maxLength={InputLength.PHONE}
                value={this.state.phone}
                onChange={(e) => this.onChange("phone", e.target.value)}
                onBlur={(e) => this.update("phone", this.state.phone)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Email" })}
              htmlFor="email"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="email"
                maxLength={InputLength.EMAIL}
                value={this.state.email}
                onChange={(e) => this.onChange("email", e.target.value)}
                onBlur={(e) => this.update("email", this.state.email)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Website" })}
              htmlFor="website"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="website"
                maxLength={InputLength.URL}
                value={this.state.website}
                onChange={(e) => this.onChange("website", e.target.value)}
                onBlur={(e) => this.update("website", this.state.website)}
              />
            </Col>
          </Form.Group>
        </Fieldset>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Various" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "VAT.Number" })}
              htmlFor="vatNumber"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="vatNumber"
                maxLength={InputLength.ID}
                value={this.state.vatNumber}
                onChange={(e) => this.onChange("vatNumber", e.target.value)}
                onBlur={(e) => this.update("vatNumber", this.state.vatNumber)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "SIRET" })}
              htmlFor="Siret"
              displayAsCol
            />
            <Col>
              <InputTextElement
                type="text"
                className="form-control"
                id="Siret"
                maxLength={InputLength.TITLE}
                value={this.state.Siret}
                onChange={(e) => this.onChange("Siret", e.target.value)}
                onBlur={(e) => this.update("Siret", this.state.Siret)}
              />
            </Col>
          </Form.Group>
        </Fieldset>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateGeneralSettings: (data) => dispatch(updateGeneralSettings(data)),
    onAddLogo: (generalSettingsId, data, successCallback) =>
      dispatch(addLogo(generalSettingsId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GeneralSettingsAdmin));
