import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Alert, Badge, Col, Row, Tab, Tabs } from "react-bootstrap";
import CollaboratorsModal from "./CollaboratorsModal";
import { removeCollaborator } from "../../../../actions/collaborators/collaborators";
import Paginator from "../../../sub/Paginator";
import CollaboratorTypes from "./collaboratorTypes/CollaboratorTypes";
import Roles from "../../../../enums/Roles";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import ActionMenu from "../../../sub/ActionMenu";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import Util from "../../../../util/Util";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class Collaborators extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };

    this.paginator = new Paginator(this);
  }

  openCollabsModal(collaborator) {
    var collaboratorId = collaborator ? collaborator._id : null;

    this.setState({
      modal: (
        <CollaboratorsModal
          close={() => this.closeModal()}
          collaboratorId={collaboratorId}
          onCollaboratorCreated={(collaborator) =>
            this.onCollaboratorCreated(collaborator)
          }
        />
      ),
    });
  }

  openConfModal(user) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Warning" />}
          mandatoryConfirmation
          onAccept={() =>
            this.props.onRemoveCollaborators({ collaboratorId: user._id }, () =>
              this.closeModal(),
            )
          }
          onDecline={() => this.closeModal()}
        >
          <Alert variant="warning">
            <FormattedMessage id="User.Remove.Confirmation1" />
          </Alert>
          <p>
            <FormattedMessage
              id="User.Remove.Confirmation2"
              values={{
                name: Util.formatFullName(user.first_name, user.name),
              }}
            />
          </p>
        </ConfirmationModal>
      ),
    });
  }

  onCollaboratorCreated(collaborator) {
    this.setState({ modal: null }, () => this.openCollabsModal(collaborator));
  }

  roleBadge(roleId) {
    let badgeClass = "success";

    switch (roleId) {
      case Roles.ADMIN:
      case Roles.SUPER_ADMIN:
        badgeClass = "danger";
        break;
      default:
        break;
    }

    return (
      <Badge bg={badgeClass}>
        <FormattedMessage id={"Role." + roleId} />
      </Badge>
    );
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    const { collaborators } = this.props;

    if (collaborators.length === 0) {
      return (
        <TableToolbar message={<FormattedMessage id="Empty.Collaborators" />}>
          <MenuButton onClick={() => this.openCollabsModal()}>
            <FormattedMessage id="Add.Collaborator" />
          </MenuButton>

          {this.state.modal}
        </TableToolbar>
      );
    }

    this.paginator.init(collaborators.length);

    const collaboratorsNode = collaborators.map((collaborator) => {
      if (!this.paginator.keep()) return null;

      const menuItems = [];

      menuItems.push(
        {
          icon: "pen-to-square",
          action: () => this.openCollabsModal(collaborator),
          text: <FormattedMessage id="Modify" />,
          disabled:
            collaborator.role === Roles.ADMIN ||
            collaborator.role === Roles.SUPER_ADMIN,
        },
        {
          icon: "trash",
          action: () => this.openConfModal(collaborator),
          text: <FormattedMessage id="Delete" />,
          disabled:
            collaborator.role === Roles.ADMIN ||
            collaborator.role === Roles.SUPER_ADMIN,
        },
      );

      const menuAction = (
        <ActionMenu
          items={menuItems}
          disabled={
            collaborator.role === Roles.ADMIN ||
            collaborator.role === Roles.SUPER_ADMIN
          }
        />
      );

      return (
        <tr key={collaborator._id}>
          <td className="align-middle">
            {Util.formatFullName(collaborator.first_name, collaborator.name)}
          </td>
          <td className="align-middle">{collaborator.username}</td>
          <td className="align-middle">{this.roleBadge(collaborator.role)}</td>
          <td className="text-end">{menuAction}</td>
        </tr>
      );
    });

    return (
      <React.Fragment>
        <Tabs defaultActiveKey="collaborators" id="collaboratorsTabs">
          <Tab
            eventKey="collaborators"
            title={this.props.intl.formatMessage({ id: "List" })}
          >
            {!this.props.limit && (
              <TableToolbar>
                <Row>
                  <Col className="text-end">
                    <MenuButton onClick={() => this.openCollabsModal()}>
                      <FormattedMessage id="Add.Collaborator" />
                    </MenuButton>
                  </Col>
                </Row>
              </TableToolbar>
            )}

            <table className="table table-striped tablee4mad">
              <thead>
                <tr>
                  <th scope="col" className="col">
                    <FormattedMessage id="Name" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Username" />
                  </th>
                  <th scope="col" className="col">
                    <FormattedMessage id="Role" />
                  </th>
                  <th scope="col" className="text-end">
                    <FormattedMessage id="Actions" />
                  </th>
                </tr>
              </thead>
              <tbody>{collaboratorsNode}</tbody>
            </table>

            {this.paginator.render()}

            {this.state.modal}
          </Tab>
          <Tab
            eventKey="collaboratorTypes"
            title={this.props.intl.formatMessage({ id: "Collaborators.Types" })}
          >
            <CollaboratorTypes collaborators={collaborators} />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRemoveCollaborators: (data, successCallback) =>
      dispatch(removeCollaborator(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Collaborators));
