import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TextareaCounter from "../../../sub/TextareaCounter";
import FileDropZone from "../../../sub/FileDropZone";
import {
  updateAuditSettings,
  addLogo,
} from "../../../../actions/settings/auditSettings/auditSettings";
import APIUrl from "../../../../APIUrl";
import CustomLabel from "../../../sub/CustomLabel";
import InputLength from "../../../../enums/InputLength";
import InputTextElement from "../../../sub/InputTextElement";
import SwitchToggle from "../../../sub/SwitchToggle";
import { Form, Row, Col, Image, Card, Container } from "react-bootstrap";

class AuditSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      subtitle: "",
      rationale: "",
      title_pdf: "",
      originaudit: "home",
      enable_cerfa_specimen: false,
      error_title_pdf: null,
      error_subtitle: null,
    };

    if (this.props.auditSettings) {
      this.state = {
        title: this.props.auditSettings.title || "",
        subtitle: this.props.auditSettings.subtitle || "",
        rationale: this.props.auditSettings.rationale || "",
        title_pdf: this.props.auditSettings.title_pdf || "",
        enable_cerfa_specimen: this.props.auditSettings.enable_cerfa_specimen,
        originaudit: "home",
      };
    }
  }

  onChange(key, value) {
    this.setState({ [key]: value });
    if (key === "title_pdf") {
      if (value.length > 45) {
        this.setState({
          error_title_pdf: <FormattedMessage id="Character.Limit" />,
        });
      } else {
        this.setState({ error_title_pdf: null });
      }
    }
    if (key === "subtitle") {
      if (value.length > 45) {
        this.setState({
          error_subtitle: <FormattedMessage id="Character.Limit" />,
        });
      } else {
        this.setState({ error_subtitle: null });
      }
    }
  }

  update(field, value) {
    if (field === "title_pdf" && this.state.error_title_pdf !== null) return;
    if (field === "subtitle" && this.state.error_subtitle !== null) return;

    var data = {
      auditSettingsId: this.props.auditSettings._id,
      updatedField: field,
      updatedValue: value,
      originaudit: this.state.originaudit,
    };
    this.props.onUpdateAuditSettings(data);
  }

  onDropFile(file, clearCallback) {
    let formData = new FormData();
    formData.append(file.name, file);

    this.setState({ disabledDropZone: true });

    var successCallback = () => {
      clearCallback();
      this.setState({ disabledDropZone: false });
    };

    this.props.onAddLogo(
      this.props.auditSettings._id,
      formData,
      successCallback,
    );
  }

  render() {
    if (this.props.auditSettings.length === 0) return null;

    var img = null;
    if (this.props.auditSettings.logo) {
      img = (
        <Image
          src={
            APIUrl.getAuditSettingsLogo +
            this.props.auditSettings._id +
            "/" +
            Math.random() +
            "?token=" +
            APIUrl.jwtToken
          }
          thumbnail
          style={{ maxHeight: "10em", maxWidth: "50em" }}
          alt="logo"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/images/placeholder_120x120.png";
          }}
        />
      );
    }

    return (
      <Container className="w-75">
        <Row className="align-items-center mb-3">
          <Col className="text-center">
            <Card>
              <Card.Body>{img}</Card.Body>
            </Card>
          </Col>
        </Row>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Logo" })}
            htmlFor="logo"
            displayAsCol
          />
          <Col md={7}>
            <FileDropZone
              disabled={this.state.disabledDropZone}
              onDropFile={(file, clearCallback) =>
                this.onDropFile(file, clearCallback)
              }
              acceptedExtensions={["jpg", "png", "jpeg"]}
              multiple={false}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Title" })}
            htmlFor="title"
            displayAsCol
          />
          <Col md={7}>
            <InputTextElement
              type="text"
              className="form-control"
              maxLength={InputLength.TITLE}
              value={this.state.title}
              onChange={(e) => this.onChange("title", e.target.value)}
              onBlur={(e) => this.update("title", this.state.title)}
            />
            <input
              type="hidden"
              name="originaudit"
              value={this.state.originaudit}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Subtitle" })}
            htmlFor="subtitle"
            displayAsCol
          />
          <Col md={7}>
            <InputTextElement
              type="text"
              className="form-control"
              value={this.state.subtitle}
              maxLength={InputLength.TITLE}
              onChange={(e) => this.onChange("subtitle", e.target.value)}
              onBlur={(e) => this.update("subtitle", this.state.subtitle)}
            />
            <div className="text-danger">
              <small>{this.state.error_subtitle}</small>
            </div>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Rationale" })}
            htmlFor="rationale"
            displayAsCol
          />
          <Col md={7}>
            <TextareaCounter
              countLimit={InputLength.TEXT_LONG}
              id="rationale"
              name="rationale"
              rows={10}
              value={this.state.rationale}
              onChange={(e) => this.onChange("rationale", e.target.value)}
              onBlur={(e) => this.update("rationale", this.state.rationale)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Title.PDF" })}
            htmlFor="title_pdf"
            displayAsCol
          />
          <Col md={7}>
            <InputTextElement
              type="text"
              className="form-control"
              maxLength={InputLength.TITLE}
              value={this.state.title_pdf}
              onChange={(e) => this.onChange("title_pdf", e.target.value)}
              onBlur={(e) => this.update("title_pdf", this.state.title_pdf)}
            />
            <div className="text-danger">
              <small>{this.state.error_title_pdf}</small>
            </div>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Use.Cerfa" })}
            htmlFor="title_pdf"
            displayAsCol
          />
          <Col md={7}>
            <SwitchToggle
              id={"cerfa-theme-switch-home"}
              onChange={(e) => {
                this.onChange(
                  "enable_cerfa_specimen",
                  !this.state.enable_cerfa_specimen,
                );
                this.update(
                  "enable_cerfa_specimen",
                  !this.state.enable_cerfa_specimen,
                );
              }}
              checked={this.state.enable_cerfa_specimen}
            />
          </Col>
        </Form.Group>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auditSettings: state.auditSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateAuditSettings: (data) => dispatch(updateAuditSettings(data)),
    onAddLogo: (auditSettingsId, data, successCallback) =>
      dispatch(addLogo(auditSettingsId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditSettings));
