import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import DateUtil from "../../util/DateUtil";
import Util from "../../util/Util";
import EqSellModes from "../../enums/EqSellModes";
import { updPatientAdmin, updPatient } from "../../actions/patients/patients";
import Icon from "../sub/Icon.js";
import ActionMenu from "../sub/ActionMenu";
import { Alert, Badge } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";
class PatientRow extends React.Component {
  onChange(field, value) {
    let { patient } = this.props;
    if (!patient) return null;
    patient = {
      _id: this.props.patient._id,
      [field]: value,
    };
    const successCallback = () => {
      this.setState({ [field]: value });
    };
    if (
      this.props.user.role === Roles.ADMIN ||
      this.props.user.role === Roles.SALES_REP
    )
      this.props.onUpdPatientAdmin(patient, successCallback);
    if (this.props.user.role === Roles.CLIENT)
      this.props.onUpdPatient(patient, successCallback);
  }

  getAuditEquipment(equipmentId) {
    const { auditEquipments } = this.props;
    const equipment = auditEquipments.find((e) => e._id === equipmentId);
    return equipment;
  }

  formatAuditDetail(request) {
    if (request.hasOwnProperty("requestedBy")) {
      const { date, requestedBy } = request;

      let prescriber = this.props.clients.filter(
        (client) => client._id === requestedBy,
      );
      return prescriber.length ? (
        <FormattedMessage
          id="Audit.Request.Detail.Full"
          values={{
            date: DateUtil.toDateWithHour(date),
            requestedBy: prescriber[0].name,
          }}
        />
      ) : (
        <FormattedMessage
          id="Audit.Request.Detail.Date"
          values={{ date: DateUtil.toDateWithHour(date) }}
        />
      );
    } else {
      return <FormattedMessage id="No.Info.Available" />;
    }
  }

  displayRenewalAlert(patient, renewableNow, renewableSoon, notRenewableNow) {
    let renewClass = null;
    let displayedAlertCount = 0;
    let isDisabled = false;

    switch (true) {
      case renewableNow > 0:
        renewClass = "danger";
        displayedAlertCount = renewableNow;
        break;
      case renewableSoon > 0 && renewableNow === 0:
        renewClass = "warning";
        displayedAlertCount = renewableSoon;
        break;
      case notRenewableNow > 0 && renewableSoon === 0 && renewableNow === 0:
        renewClass = "success";
        break;
      default:
        renewClass = "dark disabled";
        isDisabled = true;
        break;
    }

    return (
      <h5>
        <Badge
          bg={renewClass}
          disabled={isDisabled}
          id={"renawal-alert-" + patient._id}
          onClick={() => this.props.displaysEquipmentModal(patient)}
          role="button"
        >
          {displayedAlertCount}
        </Badge>
      </h5>
    );
  }

  renewalDate() {
    const { patient } = this.props;

    let renewableNow = 0;
    let renewableSoon = 0;
    let notRenewableNow = 0;

    if (!patient) return null;
    const { equipments } = patient;
    if (!equipments) return null;

    equipments.map((equipment) => {
      let date = new Date(equipment.last_prescription_date);

      const auditEquipment = this.getAuditEquipment(
        equipment.audit_equipment_id,
      );

      if (!auditEquipment) return null;

      if (equipment.mode === EqSellModes.SALE) {
        date = new Date(
          date.setFullYear(
            date.getFullYear() + parseInt(auditEquipment.purchase_renewal),
          ),
        );
      } else if (equipment.mode === EqSellModes.LEASING) {
        date = new Date(
          date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal)),
        );
      }

      let today = new Date();

      switch (true) {
        case date && date.getTime() < today.setDate(today.getDate() + 30):
          if (date.getTime() < today.setDate(today.getDate() - 30)) {
            return renewableNow++;
          } else {
            return renewableSoon++;
          }

        default:
          return notRenewableNow++;
      }
    });

    return this.displayRenewalAlert(
      patient,
      renewableNow,
      renewableSoon,
      notRenewableNow,
    );
  }

  getDateLastAudit = () => {
    const { patient, audits } = this.props;
    if (!audits) return null;
    let auditsPatient = audits.find((p) => p.patient_id === patient._id);
    if (auditsPatient) return DateUtil.toDate(auditsPatient.createdAt);
    return "-";
  };

  render() {
    const { patient, clients } = this.props;

    const { user } = this.props;
    const { role } = user;

    if (role === undefined) return null;
    if (!patient) return null;

    let prescriberLead = clients.find((p) => patient.prescriber_lead === p._id);
    let helperLead = clients.find((h) => patient.helper_lead === h._id);

    const contentConfModalMakeInactive = (
      <Alert variant="warning">
        <FormattedMessage id={"Confirm.Patient.Inactive"} />
      </Alert>
    );
    const contentConfModalAskNewAudit = (
      <FormattedMessage id={"Confirm.Patient.New.Audit"} />
    );
    const contentConfModalCancelNewAudit = (
      <FormattedMessage id={"Confirm.Patient.Cancel.New.Audit"} />
    );

    const auditRequested =
      Util.typeOf(patient.new_audit_request) !== "Undefined" &&
      patient.new_audit_request.hasOwnProperty("status")
        ? patient.new_audit_request.status
        : false;

    let menuItems = [];

    if (this.props.isAssignModal) {
      menuItems.push(
        // Assign patient to current client
        {
          id: "show" + patient._id,
          icon: "circle-plus",
          action: () => this.props.addClient(patient),
          text: <FormattedMessage id="Assign" />,
        },
      );
    } else {
      menuItems.push(
        // Open patient modal
        {
          id: "show" + patient._id,
          icon: "folder-open",
          action: () => this.props.edit(patient),
          text: <FormattedMessage id="Patient.Show" />,
        },
        // Make patient active / inactive
        user.client_type !== "patient" &&
          !this.props.limitInfoAssociatedPatients && {
            id: "status" + patient._id,
            icon: !patient.active ? "eye" : "eye-slash",
            action: !patient.active
              ? () => this.onChange("active", !patient.active)
              : () =>
                  this.props.openConfModal(
                    <FormattedMessage id="Define.As.Inactive" />,
                    contentConfModalMakeInactive,
                    () => this.onChange("active", !patient.active),
                    true,
                  ),
            text: !patient.active ? (
              <FormattedMessage id="Define.As.Active" />
            ) : (
              <FormattedMessage id="Define.As.Inactive" />
            ),
          },
        // Request new audit
        patient.active !== undefined &&
          patient.active &&
          !this.props.limitInfoAssociatedPatients && {
            id: "new_audit" + patient._id,
            icon: "clipboard-list",
            action: !auditRequested
              ? () =>
                  this.props.openConfModal(
                    <FormattedMessage id="Request.New.Audit" />,
                    contentConfModalAskNewAudit,
                    () => this.onChange("new_audit_request", !auditRequested),
                  )
              : () =>
                  this.props.openConfModal(
                    <FormattedMessage id="Cancel.New.Audit" />,
                    contentConfModalCancelNewAudit,
                    () => this.onChange("new_audit_request", !auditRequested),
                  ),
            text: !auditRequested ? (
              <FormattedMessage id="Request.New.Audit" />
            ) : (
              <FormattedMessage id="Cancel.New.Audit" />
            ),
          },
        // Delete patient
        role !== Roles.CLIENT &&
          !this.props.limitInfoAssociatedPatients && {
            id: "delete" + patient._id,
            icon: "trash",
            action: () => this.props.delete(patient),
            text: <FormattedMessage id="Delete" />,
          },
      );
    }

    let menuAction = (
      <ActionMenu items={menuItems} variant={auditRequested && "warning"} />
    );

    return (
      <tr
        key={patient._id}
        className={!patient.active ? "text-italic text-muted" : ""}
      >
        {(role === Roles.ADMIN || role === Roles.SALES_REP) && (
          <React.Fragment>
            <td className="align-middle">
              {Util.formatFullName(patient.first_name, patient.name)}
              {auditRequested ? (
                <div className="text-danger small">
                  <FormattedMessage id="Audit.Requested" />
                  <Icon
                    icon="info-circle"
                    className="ms-1"
                    hover={this.formatAuditDetail(patient.new_audit_request)}
                  />
                </div>
              ) : (
                ""
              )}
            </td>
            {!this.props.limitInfoAssociatedPatients && (
              <td className="align-middle">
                {patient.address && (
                  <>
                    <span className="text-capitalize">
                      {patient.address.toLowerCase()}
                    </span>
                    <br />
                  </>
                )}
                {patient.postal_code}{" "}
                {patient.city && patient.city.toUpperCase()}
              </td>
            )}
            <td className="align-middle">{this.getDateLastAudit()}</td>
            {!this.props.limit && !this.props.limitInfoAssociatedPatients && (
              <td className="align-middle">
                <MenuButton
                  icon={prescriberLead ? "user-doctor" : "circle-plus"}
                  className="w-100 text-start"
                  variant={!prescriberLead && "outline-dark"}
                  onClick={() => this.props.displaysPrescribersModal(patient)}
                  hover={
                    prescriberLead && <FormattedMessage id="Prescriber.Lead" />
                  }
                >
                  {prescriberLead ? (
                    Util.formatFullName(
                      prescriberLead.first_name,
                      prescriberLead.name,
                    )
                  ) : (
                    <FormattedMessage id="Add" />
                  )}
                </MenuButton>
              </td>
            )}
            {!this.props.limit && !this.props.limitInfoAssociatedPatients && (
              <td className="align-middle">
                <MenuButton
                  icon={helperLead ? "hand-holding-heart" : "circle-plus"}
                  className="w-100 text-start"
                  variant={!helperLead && "outline-dark"}
                  onClick={() => this.props.displaysHelpersModal(patient)}
                  hover={helperLead && <FormattedMessage id="Helper.Lead" />}
                >
                  {helperLead ? (
                    Util.formatFullName(helperLead.first_name, helperLead.name)
                  ) : (
                    <FormattedMessage id="Add" />
                  )}
                </MenuButton>
              </td>
            )}
            <td className="col-1 align-middle text-center">
              <Icon
                icon={
                  patient.user_id.length > 0
                    ? "circle-check"
                    : "fa-regular fa-circle-xmark"
                }
                size="xl"
                className={
                  patient.user_id.length > 0 ? "text-success" : "text-danger"
                }
              />
            </td>
            {!this.props.limit &&
              (role === Roles.ADMIN || role === Roles.SALES_REP) && (
                <td className="col-1 align-middle text-center">{menuAction}</td>
              )}
          </React.Fragment>
        )}
        {role === Roles.CLIENT && (
          <React.Fragment>
            <td className="align-middle">
              {Util.formatFullName(patient.first_name, patient.name)}
              {auditRequested ? (
                <div className="text-danger small">
                  <FormattedMessage id="Audit.Requested" />
                </div>
              ) : (
                ""
              )}
            </td>
            <td className="align-middle">
              {patient.address} {patient.postal_code}{" "}
              {patient.city && patient.city.toUpperCase()}
            </td>
            <td className="align-middle">{this.getDateLastAudit()}</td>
            <td className="col-2 text-center align-middle">
              <h5>
                <Badge
                  bg="info"
                  role="button"
                  onClick={() => this.props.displaysEquipmentModal(patient)}
                >
                  {patient.equipments.length}
                </Badge>
              </h5>
            </td>
            <td className="col-2 text-center align-middle">
              {this.renewalDate()}
            </td>
            {!this.props.limit && (
              <td className="col-1 align-middle text-center">{menuAction}</td>
            )}
          </React.Fragment>
        )}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    audits: state.audits,
    auditEquipments: state.auditDMs,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onUpdPatient: (patient, successCallback) =>
      dispatch(updPatient(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientRow));
