import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Modal, Form, Row, Col, FormSelect } from "react-bootstrap";
import TextareaCounter from "../sub/TextareaCounter";

import Util from "../../util/Util";

import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";

import { createRequestAdmin } from "../../actions/requests/requests";
import CustomLabel from "../sub/CustomLabel";
import InputLength from "../../enums/InputLength";
import MenuButton from "../sub/bootstrap/MenuButton";

class EstimateModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: "",
      precisions: "",
      disabled: false,
      disabledInputs: false,
    };
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    let data = {
      client_id: this.state.client_id,
      request_type: RequestTypes.NEW_MATERIAL,
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    this.setState({ disabled: true });

    this.props.onCreateRequestAdmin(data, () => this.props.close());
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.client_id) ||
      Util.emptyString(this.state.precisions)
    );
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    let clientsSelectOptions = null;

    if (this.props.clients.length > 0) {
      clientsSelectOptions = this.props.clients.map((client) => {
        return (
          <option key={client._id} value={client._id}>
            {Util.formatFullName(client.first_name, client.name)}
          </option>
        );
      });
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Create.Request" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Group as={Row} className="align-items-center">
            <Col>
              <FormSelect
                className="mb-3"
                id="clientId"
                value={this.state.client_id}
                onChange={(e) => this.onChange("client_id", e.target.value)}
                disabled={this.state.disabledInputs}
                placeholder={this.props.intl.formatMessage({ id: "Client" })}
              >
                <option value="">
                  <FormattedMessage id="Choose.Client" />
                </option>
                {clientsSelectOptions}
              </FormSelect>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="align-items-center">
            <Col>
              <TextareaCounter
                countLimit={InputLength.TEXT_LONG}
                id="precisions"
                rows={7}
                value={this.state.precisions}
                onChange={(e) => this.onChange("precisions", e.target.value)}
                disabled={this.state.disabledInputs}
                placeholder={this.props.intl.formatMessage({
                  id: "Request.Details",
                })}
              />
            </Col>
          </Form.Group>
        </Modal.Body>

        {!this.state.disabledInputs && (
          <Modal.Footer>
            <MenuButton variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Create" />
            </MenuButton>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequestAdmin: (data, successCallback) =>
      dispatch(createRequestAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(EstimateModalAdmin));
