import { GET_FAMILIES } from "../../actions/families/families";

export default function families(state = [], action) {
  switch (action.type) {
    case GET_FAMILIES:
      return action.families;
    default:
      return state;
  }
}
