import React from "react";
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

const propTypes = {
  hasLogo: PropTypes.bool.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
};

const defaultProps = {
  hasLogo: false,
  imgSrc: "",
  imgAlt: "",
};

/**
 * Client logo item for user navbar
 * @param {Boolean} hasLogo detect if customer has company logo into general settings
 * @param {String} imgSrc source link to display image
 * @param {String} imgAlt alternative text if image not displayed
 */
class MenuItemClientLogo extends React.Component {
  render() {
    const { hasLogo, imgSrc, imgAlt } = this.props;
    return (
      hasLogo && (
        <Image fluid src={imgSrc} alt={imgAlt} style={{ maxHeight: "4em" }} />
      )
    );
  }
}

MenuItemClientLogo.propTypes = propTypes;
MenuItemClientLogo.defaultProps = defaultProps;

export default MenuItemClientLogo;
