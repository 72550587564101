import React from "react";
import { connect } from "react-redux";
import {
  updPatientAdmin,
  deletePatientDocument,
  addPatientDocumentFile,
} from "../../actions/patients/patients";
import APIUrl from "../../APIUrl";
import Document from "../sub/documentCrud/Document";
import PatientDocumentTypes from "../../enums/PatientDocumentTypes";

class PatientTechnicalSheets extends React.Component {
  render() {
    const {
      patient,
      user,
      onUpdPatientAdmin,
      onAddPatientDocumentFile,
      onDeletePatientDocument,
    } = this.props;

    return (
      <React.Fragment>
        {patient && (
          <Document
            data={patient}
            documentType={PatientDocumentTypes.TECHNICAL_SHEETS}
            addDataDocument={(patientId, documentType, file, successCallback) =>
              onAddPatientDocumentFile(
                patientId,
                documentType,
                file,
                successCallback,
              )
            }
            updateData={(patient, successCallback) =>
              onUpdPatientAdmin(patient, successCallback)
            }
            deleteDocument={(
              patientId,
              documentType,
              sheetId,
              successCallback,
            ) =>
              onDeletePatientDocument(
                patientId,
                documentType,
                sheetId,
                successCallback,
              )
            }
            messageId={"Patient.Technical.Sheet.Add"}
            adminEmptyMessageId={"Empty.Patient.Technical.Sheets"}
            clientEmptyMessageId={"Client.Empty.Patient.Technical.Sheets"}
            user={user}
            url={APIUrl.getPatientDocumentFile}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
    onAddPatientDocumentFile: (
      patientId,
      documentType,
      file,
      successCallback,
    ) =>
      dispatch(
        addPatientDocumentFile(patientId, documentType, file, successCallback),
      ),
    onDeletePatientDocument: (
      patientId,
      documentType,
      documentId,
      successCallback,
    ) =>
      dispatch(
        deletePatientDocument(
          patientId,
          documentType,
          documentId,
          successCallback,
        ),
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PatientTechnicalSheets);
