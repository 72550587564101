import React from "react";
import APIUrl from "../../../APIUrl";
import { Card, Col, Image, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ReactImageFallback from "react-image-fallback";
import MenuButton from "../../sub/bootstrap/MenuButton";

export default class DeskProductCard extends React.Component {
  goToProduct() {
    this.props.setOngoingState({ product_id: this.props.product._id });
    this.props.scrollToTopDesk();
  }

  render() {
    const { product, deskStore, openCommentModal } = this.props;
    const imgSrc = `${APIUrl.getDeskProductImg}${product._id}?token=${APIUrl.jwtToken}`;
    const isSelected = deskStore && deskStore[product._id];

    return (
      <Col className="mb-4">
        <Card
          bg={!isSelected && "light"}
          className={
            isSelected ? "bg-success-selected h-100" : "cursor-pointer h-100"
          }
        >
          <Card.Body
            className="d-flex flex-column cursor-pointer"
            onClick={() => this.goToProduct()}
          >
            <Row>
              <Col>
                <Row className="position-absolute">
                  <Col className="text-end p-5 pt-0 pe-2 m-3 mt-2 ms-5 me-4">
                    {product.refundable && (
                      <Image
                        thumbnail
                        className="w-50 border border-danger"
                        src={`${APIUrl.rootUrl}/img/medical_advice.svg`}
                      />
                    )}
                  </Col>
                </Row>
                <ReactImageFallback
                  src={imgSrc}
                  className={
                    isSelected
                      ? "border border-success img-thumbnail cursor-pointer"
                      : "img-thumbnail cursor-pointer"
                  }
                  initialImage="/images/loading.gif"
                  fallbackImage="/images/no_image_512.png"
                  onClick={() => this.goToProduct()}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center ps-0 pe-0 mt-2">
                <small>
                  <strong>
                    <span>{product.name}</span>
                  </strong>
                </small>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="text-center">
            {isSelected && openCommentModal && (
              <MenuButton
                icon={deskStore[product._id].comment ? "check" : "comment"}
                className={isSelected && "border border-white"}
                variant={deskStore[product._id].comment ? "warning" : "success"}
                size="sm"
                onClick={() =>
                  openCommentModal(product, deskStore[product._id])
                }
              >
                <FormattedMessage id="Comment" />
              </MenuButton>
            )}
            {!isSelected && (
              <MenuButton
                icon="eye"
                className={"nohover"}
                variant={"light"}
                size="sm"
                onClick={() => this.goToProduct()}
              >
                <FormattedMessage id="Consult.Product" />
              </MenuButton>
            )}
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}
