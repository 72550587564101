import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import DeskProductsModalAdmin from "./DeskProductsModalAdmin";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import DeskProductsRowAdmin from "./DeskProductsRowAdmin";
import { Alert, Col, Row } from "react-bootstrap";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openDeskProductsModalAdmin(deskProduct) {
    const deskProductId = deskProduct ? deskProduct._id : null;
    this.setState({
      modal: (
        <DeskProductsModalAdmin
          deskProductId={deskProductId}
          deskCategoryId={this.props.deskCategoryId}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={title}
          mandatoryConfirmation
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  render() {
    const { deskProducts, deskCategoryId } = this.props;

    const deskProductsCategory = deskProducts.filter(
      (p) => p.category_id === deskCategoryId,
    );

    const deskProductRowNode = deskProductsCategory.map((deskProduct) => {
      return (
        <DeskProductsRowAdmin
          key={deskProduct._id}
          deskProduct={deskProduct}
          deskProductsCategory={deskProductsCategory}
          openDeskProductsModalAdmin={(deskProduct) =>
            this.openDeskProductsModalAdmin(deskProduct)
          }
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
        />
      );
    });

    return (
      <React.Fragment>
        <TableToolbar>
          <Row>
            <Col className="text-end">
              <MenuButton onClick={(e) => this.openDeskProductsModalAdmin()}>
                <FormattedMessage id="Add.Type.Product" />
              </MenuButton>
            </Col>
          </Row>
        </TableToolbar>

        {(!deskProductsCategory || deskProductsCategory.length <= 0) && (
          <Alert className="mt-3" variant="secondary">
            <FormattedMessage id="Empty.Types.Products" />
          </Alert>
        )}

        {deskProductsCategory && deskProductsCategory.length !== 0 && (
          <table className="table table-striped tablee4mad">
            <thead>
              <tr>
                <th className="w-100">
                  <FormattedMessage id="Name" />
                </th>

                <th className="text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{deskProductRowNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskProducts: state.deskProducts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeskProducts);
