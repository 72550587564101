import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { checkIfEmailIsValid, updateUser } from "../../actions/user/user";
import { updPatient } from "../../actions/patients/patients";
import CustomLabel from "../sub/CustomLabel";
import InputLength from "../../enums/InputLength";
import InputTextElement from "../sub/InputTextElement";
import { Col, Form, Row } from "react-bootstrap";
import Fieldset from "../sub/Fieldset";
import Legend from "../sub/Legend";

class UpdateAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.user.username,
      name: this.props.user.name,
      first_name: this.props.user.first_name,
      email: this.props.user.email,
      phone: this.props.user.phone,
      emailError: null,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });

    if (key === "email") {
      if (Util.emptyString(value)) this.setState({ emailError: null });

      if (Util.emptyString(value) && !Util.emptyString(this.state.username))
        this.setState({
          emailError: <FormattedMessage id="Error.Account.Email.Required" />,
        });

      if (!Util.emptyString(value) && !Util.isEmail(value)) {
        this.setState({
          emailError: <FormattedMessage id="Invalid.Email.Error" />,
        });
      } else if (!Util.emptyString(value) && Util.isEmail(value)) {
        this.props.onCheckIfEmailIsValid(
          value,
          (response) => {
            this.setState({ emailError: null });
          },
          (response) => {
            this.setState({
              emailError: (
                <span>
                  <FormattedMessage id="Invalid.Email.Error" /> (
                  {response.data.reason})
                </span>
              ),
            });
            return;
          },
        );
      }
    }
  }

  update(field, value) {
    if (Util.emptyString(value)) return;

    if (field === "email" && this.state.emailError) return;

    const updatePatient = () => {
      if (!this.props.user.patient_id) return;

      const patient = {
        _id: this.props.user.patient_id,
        [field]: value,
      };

      this.props.onUpdPatient(patient);
    };

    let data = {
      updatedField: field,
      updatedValue: value,
    };
    this.props.onUpdateUser(data, updatePatient);
  }

  render() {
    return (
      <>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Identity" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Name" })}
              htmlFor="name"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                type="text"
                id="name"
                autoComplete="off"
                maxLength={InputLength.NAME}
                value={this.state.name || ""}
                onChange={(e) =>
                  this.onChange("name", e.target.value.toUpperCase())
                }
                onBlur={() => this.update("name", this.state.name)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "First.Name" })}
              htmlFor="first_name"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                className="text-capitalize"
                type="text"
                id="first_name"
                autoComplete="off"
                value={this.state.first_name || ""}
                maxLength={InputLength.NAME}
                onChange={(e) =>
                  this.onChange("first_name", e.target.value.toLowerCase())
                }
                onBlur={() => this.update("first_name", this.state.first_name)}
              />
            </Col>
          </Form.Group>
        </Fieldset>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Contact.Information" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Email" })}
              htmlFor="email"
              displayAsCol
              required
            />
            <Col md={7}>
              <InputTextElement
                type="email"
                id="email"
                autoComplete="off"
                maxLength={InputLength.EMAIL}
                value={this.state.email || ""}
                onChange={(e) => this.onChange("email", e.target.value)}
                onBlur={() => this.update("email", this.state.email)}
              />
              <div className="text-danger">
                <small>{this.state.emailError}</small>
              </div>
            </Col>
          </Form.Group>
        </Fieldset>
        <Fieldset>
          <Legend>
            <FormattedMessage id="Access.PHARMAMAD" />
          </Legend>
          <Form.Group as={Row} className="align-items-center mb-3">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Username.Connexion",
              })}
              htmlFor="username"
              displayAsCol
              required
            />
            <Col md={7}>
              <Form.Group as={Row} className="align-items-center mb-0">
                <Col>
                  <InputTextElement
                    type="text"
                    id="username"
                    autoComplete="off"
                    defaultValue={this.state.username || ""}
                    disabled
                  />
                </Col>
                <Col md={4}>-{this.props.company.url}</Col>
              </Form.Group>
            </Col>
          </Form.Group>
        </Fieldset>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatient: (data, successCallback) =>
      dispatch(updPatient(data, successCallback)),
    onUpdateUser: (data, successCallback) =>
      dispatch(updateUser(data, successCallback)),
    onCheckIfEmailIsValid: (email, isValidCallback, notValidCallback) =>
      dispatch(checkIfEmailIsValid(email, isValidCallback, notValidCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(UpdateAccountForm));
