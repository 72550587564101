import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DeskCategoryModalAdmin from "./DeskCategoryModalAdmin";
import DeskProducts from "./DeskProducts";
import { Modal, Tab, Tabs } from "react-bootstrap";

class DeskCategoriesModalAdmin extends React.Component {
  render() {
    const { deskCategory, target } = this.props;
    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size={"xl"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {deskCategory ? (
              <>
                <FormattedMessage id="Category" /> : {deskCategory.name}
              </>
            ) : (
              <FormattedMessage id="Add.Categories" />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey={target || "general"} id="tab-desk-categ">
            <Tab
              eventKey="general"
              title={<FormattedMessage id="General.Informations" />}
            >
              <DeskCategoryModalAdmin
                closeModal={() => this.props.closeModal()}
                deskCategory={deskCategory}
                deskCategoryId={deskCategory ? deskCategory._id : null}
              />
            </Tab>
            <Tab
              eventKey="product"
              title={<FormattedMessage id="Types.Products" />}
              disabled={!deskCategory}
            >
              <DeskProducts
                deskCategoryId={deskCategory ? deskCategory._id : null}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskCategoriesModalAdmin));
