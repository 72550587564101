import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ShippingModalAdmin from "./ShippingModalAdmin";
import ShippingCostSettingAdmin from "./ShippingCostSettingAdmin";
import {
  updateOrderSettingAdmin,
  updateShippingCostsSetting,
} from "../../../../actions/settings/ordersSettings/admin/ordersSettings";
import CustomLabel from "../../../sub/CustomLabel";
import InputNumber from "../../../sub/fields/input/InputNumber";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class OrdersSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      shipping_delay: this.props.ordersSettingsAdmin.shipping_delay,
      min_order_amount: this.props.ordersSettingsAdmin.min_order_amount,
      urgent: this.props.ordersSettingsAdmin.urgent,
    };
  }

  openShippingModal(successCallback) {
    if (this.hasInfinity()) return;

    this.setState({
      modal: (
        <ShippingModalAdmin
          index={-1}
          shippingCosts={this.props.ordersSettingsAdmin.shipping_costs}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  createShippingCostSetting(shippingCosts) {
    // Send to BE
    this.props.onUpdateShippingCostsSetting({ shippingCosts: shippingCosts });
  }

  onFieldChange(field, value = 0) {
    this.setState({ [field]: value });
  }

  updateOrderSettingAdmin(field, value = 0) {
    var data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateOrderSettingAdmin(data);
  }

  hasInfinity() {
    return (
      this.props.ordersSettingsAdmin.shipping_costs.length !== 0 &&
      !this.props.ordersSettingsAdmin.shipping_costs[
        this.props.ordersSettingsAdmin.shipping_costs.length - 1
      ].max
    );
  }

  render() {
    if (this.props.ordersSettingsAdmin.length === 0) return null;
    // Shipping modal set up
    var successCallback = (e) =>
      this.createShippingCostSetting(
        this.props.ordersSettingsAdmin.shipping_costs,
      );

    let shippingCostNode = null;
    if (this.props.ordersSettingsAdmin.shipping_costs.length !== 0) {
      shippingCostNode = (
        <ShippingCostSettingAdmin
          ordersSettingsAdmin={this.props.ordersSettingsAdmin}
        />
      );
    }

    return (
      <React.Fragment>
        {this.state.modal}
        <Container className="w-75">
          <Card>
            <Card.Body>
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Shipping.Delay",
                  })}
                  htmlFor="shippingDelay"
                  displayAsCol
                />
                <Col>
                  <InputNumber
                    id="shippingDelay"
                    min={0}
                    defaultValue={this.state.shipping_delay}
                    onChange={(e) => this.onFieldChange("shipping_delay", e)}
                    onBlur={(e) =>
                      this.updateOrderSettingAdmin(
                        "shipping_delay",
                        e.target.value,
                      )
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Min.Price" })}
                  htmlFor="minPrice"
                  displayAsCol
                />
                <Col>
                  <InputNumber
                    id="minPrice"
                    min={0}
                    defaultValue={this.state.min_order_amount}
                    onChange={(e) => this.onFieldChange("min_order_amount", e)}
                    onBlur={(e) =>
                      this.updateOrderSettingAdmin(
                        "min_order_amount",
                        e.target.value,
                      )
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Settings.Urgent.Cost",
                  })}
                  htmlFor="urgent"
                  displayAsCol
                />
                <Col>
                  <InputNumber
                    id="urgent"
                    min={0}
                    defaultValue={this.state.urgent || 0}
                    onChange={(e) => this.onFieldChange("urgent", e)}
                    onBlur={(e) =>
                      this.updateOrderSettingAdmin("urgent", e.target.value)
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center mb-3">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Shipping.Cost" })}
                  htmlFor="shippingCost"
                  displayAsCol
                />
                <Col>
                  <MenuButton
                    icon="fa-regular fa-square-plus"
                    onClick={() => this.openShippingModal(successCallback)}
                    disabled={this.hasInfinity()}
                    hover={!this.hasInfinity() && <FormattedMessage id="Add" />}
                  />
                </Col>
              </Form.Group>
              {shippingCostNode}
            </Card.Body>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateOrderSettingAdmin: (data) =>
      dispatch(updateOrderSettingAdmin(data)),
    onUpdateShippingCostsSetting: (data) =>
      dispatch(updateShippingCostsSetting(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(OrdersSettingsAdmin));
