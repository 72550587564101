import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addCheckpointToDM,
  deleteCheckpointFromDM,
  sortCheckpoint,
  updateCheckpoint,
} from "../../../../actions/settings/auditDM/admin/auditDM";
import CreateCheckpointModal from "./CreateCheckpointModal";
import Checkpoints from "./Checkpoints";
import { Alert, Col, Row } from "react-bootstrap";
import Util from "../../../../util/Util";
import TableToolbar from "../../../sub/bootstrap/TableToolbar";
import MenuButton from "../../../sub/bootstrap/MenuButton";

function AuditDMCheckpointsAdmin({
  currentDM,
  onSortCheckpoint,
  onAddCheckpointToDM,
  onUpdateCheckpoint,
  onDeleteCheckpointFromDM,
}) {
  const [checkpoints, setCheckpoints] = useState(
    currentDM.checkpoints ? currentDM.checkpoints : [],
  );
  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (currentDM) setCheckpoints(currentDM.checkpoints);
  }, [currentDM]);

  const closeModal = () => setModal(null);

  const sortPoint = (checkpoint, direction) => {
    if (!currentDM._id || !checkpoint) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      auditDMId: currentDM._id,
      checkpointId: checkpoint._id,
      direction: direction,
      updatedValue:
        direction === "down"
          ? checkpoint.sortIndex + 1
          : checkpoint.sortIndex - 1,
    };

    onSortCheckpoint(data, () => closeModal());
  };

  const addCheckpoint = (checkpointName) => {
    if (!currentDM._id || Util.emptyString(checkpointName)) return;

    const data = {
      auditDMId: currentDM._id,
      checkpoint: {
        name: checkpointName,
        sortIndex: checkpoints.length + 1,
      },
    };

    onAddCheckpointToDM(data, () => closeModal());
  };

  const deleteCheckpoint = (checkpoint) => {
    if (!currentDM._id || !checkpoint) return;

    const data = {
      auditDMId: currentDM._id,
      checkpointId: checkpoint._id,
    };

    onDeleteCheckpointFromDM(data, () => closeModal());
  };

  const openCreateCheckpointModal = () => {
    setModal(
      <CreateCheckpointModal
        addCheckpoint={(checkpoint) => addCheckpoint(checkpoint)}
        close={() => closeModal()}
      />,
    );
  };

  return (
    <>
      <Alert variant="info">
        <FormattedMessage id="Checkpoints.Info" />
      </Alert>

      <TableToolbar
        message={
          !checkpoints.length && <FormattedMessage id="Empty.Checkpoint" />
        }
      >
        <Row>
          <Col className="text-end">
            <MenuButton onClick={() => openCreateCheckpointModal()}>
              <FormattedMessage id="Add.Checkpoint" />
            </MenuButton>
          </Col>
        </Row>
      </TableToolbar>

      <Checkpoints
        auditDMId={currentDM._id}
        checkpoints={checkpoints}
        onSort={(checkpoint, direction) => sortPoint(checkpoint, direction)}
        onUpdateCheckpoint={(data) =>
          onUpdateCheckpoint(data, () => closeModal())
        }
        onDeleteCheckpoint={(checkpoint) => deleteCheckpoint(checkpoint)}
      />

      {modal}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    checkpoints: state.checkpoints,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSortCheckpoint: (data, successCallback) =>
      dispatch(sortCheckpoint(data, successCallback)),
    onAddCheckpointToDM: (data, successCallback) =>
      dispatch(addCheckpointToDM(data, successCallback)),
    onUpdateCheckpoint: (data, successCallback) =>
      dispatch(updateCheckpoint(data, successCallback)),
    onDeleteCheckpointFromDM: (data, successCallback) =>
      dispatch(deleteCheckpointFromDM(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AuditDMCheckpointsAdmin));
