const DeskProductMapping = {
  category: "CATEGORIE",
  name: "NOM",
  ref: "REF",
  refundable: "REMBOURSABLE",
  vte_loc: "TYPE",
  prescription_type_vte_text: "PRESCRIPTION TYPE VTE",
  prescription_type_loc_text: "PRESCRIPTION TYPE LOC",
  renouv_vte: "RENOUVELLEMENT A LA VENTE",
  is_renewal_obligatory: "RENOUVELLEMENT OBLIGATOIRE",
  description_text: "DESCRIPTIF",
  relatedQuestions_1: "Q1",
  relatedQuestions_2: "Q2",
  relatedQuestions_3: "Q3",
  relatedQuestions_4: "Q4",
  relatedQuestions_5: "Q5",
  relatedQuestions_6: "Q6",
  relatedQuestions_7: "Q7",
  relatedQuestions_8: "Q8",
  relatedQuestions_9: "Q9",
  relatedQuestions_10: "Q10",
  relatedRecommendations_1: "R1",
  relatedRecommendations_2: "R2",
  relatedRecommendations_3: "R3",
  relatedRecommendations_4: "R4",
  relatedRecommendations_5: "R5",
  relatedRecommendations_6: "R6",
  relatedRecommendations_7: "R7",
  relatedRecommendations_8: "R8",
  relatedRecommendations_9: "R9",
  relatedRecommendations_10: "R10",
  relatedProducts_1: "P1",
  relatedProducts_2: "P2",
  relatedProducts_3: "P3",
  relatedProducts_4: "P4",
  relatedProducts_5: "P5",
  relatedProducts_6: "P6",
  relatedProducts_7: "P7",
  relatedProducts_8: "P8",
  relatedProducts_9: "P9",
  relatedProducts_10: "P10",
  relatedProducts_11: "P11",
  relatedProducts_12: "P12",
  relatedProducts_13: "P13",
  relatedProducts_14: "P14",
  relatedProducts_15: "P15",
  relatedCategories_1: "C1",
  relatedCategories_2: "C2",
  relatedCategories_3: "C3",
  relatedCategories_4: "C4",
  relatedCategories_5: "C5",
  relatedCategories_6: "C6",
  relatedCategories_7: "C7",
  relatedCategories_8: "C8",
  relatedCategories_9: "C9",
  relatedCategories_10: "C10",
  relatedCategories_11: "C11",
  relatedCategories_12: "C12",
  relatedCategories_13: "C13",
  relatedCategories_14: "C14",
  relatedCategories_15: "C15",
};

export default DeskProductMapping;
