import { createRoot } from "react-dom/client";
import { createStore, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { Provider } from "react-redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import axios from "axios";
import { IntlProvider } from "react-intl";
import frMessages from "./i18n/fr.json";
import enMessages from "./i18n/en.json";

import App from "./App";
import rootReducer from "./reducers";

// Allow REDUX DEV TOOLS only in DEV mode
// .env file must include a var named REACT_APP_RUNNING_MODE with value "prod" or "dev"
let initStore = () => {
  if (
    typeof process.env.REACT_APP_RUNNING_MODE !== "undefined" &&
    process.env.REACT_APP_RUNNING_MODE === "dev"
  ) {
    const composeEnhancers = composeWithDevTools({
      // Specify custom devTools options
    });

    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  } else {
    return createStore(rootReducer, applyMiddleware(thunk));
  }
};

const store = initStore();

// If we have authentication data in the session storage, use it right away
var token = sessionStorage.getItem("token");
if (token) axios.defaults.headers["jwtToken"] = token;

const userLanguage =
  navigator.language || navigator.userLanguage || navigator.languages[0];
const languageWithoutRegionCode = userLanguage.toLowerCase().split(/[_-]+/)[0];
const messages = languageWithoutRegionCode === "fr" ? frMessages : enMessages;

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <IntlProvider
      messages={messages}
      locale={languageWithoutRegionCode}
      defaultLocale="en"
    >
      <App />
    </IntlProvider>
  </Provider>,
);
