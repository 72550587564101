import React from "react";
import { Card, Container } from "react-bootstrap";

export default class SectionContainer extends React.Component {
  render() {
    return (
      <Container
        className="flex-grow-1 d-flex flex-column my-4"
        style={{ overflowY: "auto" }}
        fluid={this.props.fluid}
      >
        <Card className="flex-grow-1 d-flex flex-column">
          <Card.Body>{this.props.children}</Card.Body>
        </Card>
      </Container>
    );
  }
}
