import React from "react";
import { FormattedMessage } from "react-intl";
import { nanoid } from "nanoid";
import MenuButton from "../../../sub/bootstrap/MenuButton.js";

function RoomAssociationsRow({
  room,
  roomsCount,
  openConfModal,
  onDeleteRoom,
}) {
  const deleteRoom = (room) => {
    if (!room) return;
    onDeleteRoom(room);
  };

  const modalTitle = <FormattedMessage id="Confirm" />;
  const modalContent = (
    <div>
      <p>
        <FormattedMessage id="Environment.Remove.Confirmation" />
      </p>
      <p>
        <FormattedMessage id="Please.Confirm" />
      </p>
    </div>
  );
  const successCallback = () => deleteRoom(room);
  const disabledDeletion = roomsCount < 2;

  return (
    <React.Fragment>
      <tr key={nanoid()}>
        <td className="align-middle">{room}</td>
        <td className="align-middle text-center">
          <MenuButton
            icon="trash"
            disabled={disabledDeletion}
            onClick={() =>
              !disabledDeletion &&
              openConfModal(modalTitle, modalContent, successCallback)
            }
            hover={<FormattedMessage id="Delete" />}
          />
        </td>
      </tr>
    </React.Fragment>
  );
}

export default RoomAssociationsRow;
