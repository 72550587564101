import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_AUDITS = "GET_AUDITS";

function getAuditsAction(audits) {
  return { type: GET_AUDITS, audits: audits };
}

export const getAudits = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAudits)
      .then(function (response) {
        dispatch(getAuditsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getAuditsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditAdmin)
      .then(function (response) {
        dispatch(getAuditsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteAudit = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteAudit, data)
      .then(function (response) {
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const sendAudit = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.sendAudit, data)
      .then(function (response) {
        if (successCallback) successCallback(response);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
