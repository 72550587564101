import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  deleteDeskProduct,
  sortProduct,
} from "../../../../actions/settings/deskCategories/admin/deskProducts";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskProductsRowAdmin extends React.Component {
  onSort(product, direction) {
    if (!product) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      deskProductId: product._id,
      direction: direction,
      updatedValue:
        direction === "down" ? product.sortIndex + 1 : product.sortIndex - 1,
    };

    this.props.onSortProduct(data);
  }

  render() {
    const {
      deskProductsCategory,
      deskProduct,
      key,
      openDeskProductsModalAdmin,
      openConfModal,
      onDeleteDeskProduct,
    } = this.props;

    const modalTitle = <FormattedMessage id="Confirm" />;
    const modalContent = <FormattedMessage id="Type.DM.Remove.Confirmation" />;
    const disabledUp = deskProduct.sortIndex === 0;
    const disabledDown =
      deskProductsCategory.length - 1 <= deskProduct.sortIndex;

    return (
      <tr key={key}>
        <td className="align-middle">{deskProduct.name}</td>
        <td className="w-auto text-end">
          <div className="text-nowrap">
            <MenuButton
              icon="arrow-up"
              onClick={() => !disabledUp && this.onSort(deskProduct, "up")}
              hover={<FormattedMessage id="Move.Up" />}
              className="me-1"
              disabled={disabledUp}
            />
            <MenuButton
              icon="arrow-down"
              onClick={() => !disabledDown && this.onSort(deskProduct, "down")}
              hover={<FormattedMessage id="Move.Down" />}
              className="me-1"
              disabled={disabledDown}
            />
            <MenuButton
              icon="pen-to-square"
              onClick={() => openDeskProductsModalAdmin(deskProduct)}
              hover={<FormattedMessage id="Modify" />}
              className="me-1"
            />
            <MenuButton
              icon="trash"
              id={"delete" + deskProduct._id}
              onClick={() =>
                openConfModal(modalTitle, modalContent, () =>
                  onDeleteDeskProduct({ deskProductId: deskProduct._id }),
                )
              }
              hover={<FormattedMessage id="Delete" />}
            />
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteDeskProduct: (data) => dispatch(deleteDeskProduct(data)),
    onSortProduct: (data, successCallback) =>
      dispatch(sortProduct(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsRowAdmin));
