import { combineReducers } from "redux";
import user from "./user/userReducer";
import products from "./products/productsReducer";
import oldProducts from "./products/oldProductsReducer";
import cart from "./cart/cartReducer";
import orders from "./orders/ordersReducer";
import clients from "./clients/clientsReducer";
import ordersSettingsAdmin from "./settings/ordersSettings/admin/ordersSettingsAdminReducer";
import ordersSettings from "./settings/ordersSettings/ordersSettingsReducer";
import productsSettingsAdmin from "./settings/productsSettings/admin/productsSettingsAdminReducer";
import notifications from "./notifications/notificationsReducer";
import mercurials from "./mercurials/mercurialsReducer";
import collaboratorTypes from "./configurables/collaboratorTypesReducer";
import requests from "./requests/requestsReducer";
import checkpoints from "./checkpoints/checkpointsReducer";
import patients from "./patients/patientsReducer";
import collaborators from "./collaborators/collabotarorsReducer";
import admin from "./admin/adminReducer";
import generalSettings from "./settings/generalSettings/generalSettingsReducer";
import auditRooms from "./settings/auditRooms/auditRoomsReducer";
import auditDMs from "./settings/auditDMs/auditDMsReducer";
import url from "./authentication/authentication";
import rgpd from "./rgpd/rgpdReducer";
import prescriberTypes from "./settings/prescriberType/prescriberTypeReducer";
import auditSettings from "./settings/auditSettings/auditSettingsReducer";
import auditDeskSettings from "./settings/auditSettings/auditDeskSettingsReducer";
import audits from "./audits/auditsReducer";
import { LOGOUT } from "../actions/authentication/authentication";
import company from "./company/companyReducer";
import promoOperations from "./promoOperations/promoOperationsReducer";
import { resetOldProducts } from "../actions/products/oldProducts";
import maintenances from "./maintenances/maintenancesReducer";
import interventions from "./interventions/InterventionsReducer";
import socket from "./socket/socketReducer";
import coordinationNotifications from "./coordination/coordinationNotifcationsReducer";
import coordination from "./coordination/coordinationReducer";
import deskaudits from "./deskaudits/deskauditsReducer";
import deskCategories from "./settings/deskCategory/deskCategoryReducer";
import deskProducts from "./settings/deskProduct/deskProductReducer";
import documents from "./documents/documentsReducer";
import documentsCategories from "./documents/categoriesReducer";
import families from "./families/familiesReducer";

const appReducer = combineReducers({
  user: user,
  products: products,
  oldProducts: oldProducts,
  cart: cart,
  orders: orders,
  clients: clients,
  ordersSettings: ordersSettings,
  ordersSettingsAdmin: ordersSettingsAdmin,
  productsSettingsAdmin: productsSettingsAdmin,
  mercurials: mercurials,
  notifications: notifications,
  coordination: coordination,
  coordinationNotifications: coordinationNotifications,
  requests: requests,
  checkpoints: checkpoints,
  collaboratorTypes: collaboratorTypes,
  collaborators: collaborators,
  admin: admin,
  generalSettings: generalSettings,
  patients: patients,
  auditDMs: auditDMs,
  auditRooms: auditRooms,
  url: url,
  prescriberTypes: prescriberTypes,
  auditSettings: auditSettings,
  auditDeskSettings: auditDeskSettings,
  audits: audits,
  promoOperations: promoOperations,
  company: company,
  maintenances: maintenances,
  interventions: interventions,
  rgpd: rgpd,
  deskaudits: deskaudits,
  deskCategories: deskCategories,
  deskProducts: deskProducts,
  documents: documents,
  documentsCategories: documentsCategories,
  socket: socket,
  families: families,
});

const urlReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Wipe all redux state
    state = undefined;

    // Call other reset functions that might need a clean state
    resetOldProducts();
  }

  return appReducer(state, action);
};

export default urlReducer;
