import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Col, Modal, Row } from "react-bootstrap";
import FamiliesAdmin from "../../../families/FamiliesAdmin.js";
import MercurialStatus from "../../../../enums/MercurialStatus.js";
import Select from "react-select";
import MenuButton from "../../../sub/bootstrap/MenuButton.js";

class DeskProductsCatalogProductsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mercurialId: "",
      selectedCatalog: null,
    };
  }

  getCatalogSelectOptions() {
    const options = [];
    for (const mercurial of this.props.mercurials) {
      const now = new Date();
      const hasExpired =
        mercurial.end_date &&
        (new Date(mercurial.start_date) > now ||
          new Date(mercurial.end_date) < now);
      if (!hasExpired && mercurial.status !== MercurialStatus.INACTIVE) {
        options.push({ value: mercurial._id, label: mercurial.name });
      }
    }
    return options;
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        size={"xl"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Select.Associated.Products" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center mb-3">
            <Col md={6}>
              <Select
                id="mercurials-filter-mercurialId"
                placeholder={this.props.intl.formatMessage({
                  id: "Select.Mercurial",
                })}
                options={this.getCatalogSelectOptions()}
                noOptionsMessage={() => <FormattedMessage id={"No.Result"} />}
                onChange={(option) => {
                  this.setState({
                    mercurialId: option.value,
                    selectedCatalog: option,
                  });
                }}
                value={this.getCatalogSelectOptions().find(
                  (option) => option.value === this.state.mercurialId,
                )}
              />
            </Col>
          </Row>
          {this.state.mercurialId && (
            <FamiliesAdmin
              mercurialId={this.state.mercurialId}
              setState={(state) => this.setState(state)}
              deskProductId={this.props.deskProductId}
              addProduct={(product) => this.props.addProduct(product)}
              deleteProduct={(product) => this.props.deleteProduct(product)}
              productsFromCatalog={this.props.productsFromCatalog}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          <MenuButton onClick={() => this.props.closeModal()}>
            <FormattedMessage id="Close" />
          </MenuButton>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsCatalogProductsModal));
