import React from "react";
import { Alert, Card, Col, Row } from "react-bootstrap";
import Icon from "../Icon";

export default class TableToolbar extends React.Component {
  render() {
    const { message, variant } = this.props;

    return (
      <Card className="mb-3" bg={variant || "light"}>
        <Card.Body className="p-2">
          {message ? (
            <Alert variant="light" className="mb-0 p-2">
              <Row className="align-items-center">
                <Col>
                  <Icon icon="circle-info" className="me-1" />
                  {message}
                </Col>
                <Col className="text-nowrap d-flex col-auto align-items-stretch">
                  {this.props.children}
                </Col>
              </Row>
            </Alert>
          ) : (
            this.props.children
          )}
        </Card.Body>
      </Card>
    );
  }
}
