import React from "react";
import { connect } from "react-redux";
import { addProductToCart } from "../../actions/cart/cart";
import { addProductToCartAdmin } from "../../actions/cart/cart";
import ProductRow from "./ProductRow";
import { FormattedMessage, injectIntl } from "react-intl";
import Roles from "../../enums/Roles";
import Paginator from "../sub/Paginator";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import ArrayUtil from "../../util/ArrayUtil";
import { Col, Container, Dropdown, FormSelect, Row } from "react-bootstrap";
import Icon from "../sub/Icon.js";
import ProductModal from "./ProductModal.js";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import MenuButton from "../sub/bootstrap/MenuButton";
import { updateUser } from "../../actions/user/user";
import ProductsMarginModal from "./ProductsMarginModal";
import InputTextElement from "../sub/InputTextElement";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { deleteProductAdmin } from "../../actions/products/products";
import ProductDetailsTemplatePDF from "./ProductDetailsTemplatePDF";
import PDFUtil from "../../util/PDFUtil";

class Products extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      searchProductId: this.props.product ? this.props.product._id : "",
      mercurialId: this.props.mercurialId || "",
      subCategoryFilter: "",
      modal: null,
      template: "",
      isLoading: false,
    };

    this.paginator = new Paginator(this);
  }

  add(e, productId, quantity, successCallback) {
    var data = {
      id_product: productId,
      quantity: quantity,
    };

    // Add product. Careful with role
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    )
      this.props.onAddProductToCartAdmin(data, successCallback);
    else this.props.onAddProductToCart(data, successCallback);
  }

  search(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ search: e.target.value, searchProductId: null });
  }

  formatsContain(formats, productId) {
    for (let format of formats) {
      if (format._id === productId) return true;
    }
    return false;
  }

  isClient() {
    return (
      this.props.user.role !== Roles.SALES_REP &&
      this.props.user.role !== Roles.ADMIN
    );
  }

  // Simple close modal function that can handle a possible callback if provided
  closeModal(callback) {
    this.setState({ modal: null }, callback);
  }

  openProductModal(product, subCategories, isAddDeclination = false) {
    this.setState({
      modal: (
        <ProductModal
          product={product}
          closeModal={() => this.closeModal()}
          famille={this.props.family}
          mercurialId={this.props.mercurialId}
          isAddDeclination={isAddDeclination}
          subCategories={subCategories}
        />
      ),
    });
  }

  openProductsMarginModal() {
    this.setState({
      modal: (
        <ProductsMarginModal
          mercurialId={this.props.mercurialId}
          famille={this.props.family}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  openConfModal(product) {
    const successCallback = () =>
      this.props.onDeleteProduct(
        {
          mercurialId: product.mercurial_id,
          productId: product._id,
        },
        () => this.closeModal(),
      );

    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Confirm" />}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          <FormattedMessage
            id="Product.Remove.Confirmation"
            values={{
              designation: ProductsParserUtil.getDesignation(product),
            }}
          />
        </ConfirmationModal>
      ),
    });
  }

  downloadPDF(product, callback) {
    const id = "product-pdf" + product._id;
    const fileName = product._id;
    const template = (
      <ProductDetailsTemplatePDF
        id={id}
        generalSettings={this.props.generalSettings}
        product={product}
      />
    );

    const generatePDF = (field, value, id, fileName, css, callback) => {
      this.setState({ [field]: value }, () => {
        PDFUtil.toPDFWithPuppeteer(
          id,
          fileName,
          css,
          callback,
          { url: "toProductPDF" },
          true,
        );
      });
    };

    generatePDF("template", template, id, fileName, "product_store", callback);
  }

  updateRateVisibility() {
    let data = {
      updatedField: "preferencies",
      updatedValue: this.props.user.preferencies,
    };
    data.updatedValue.is_rate_visible =
      !this.props.user.preferencies.is_rate_visible;
    this.props.onUpdateUser(data);
  }

  render() {
    let isRendered = true;
    let favorites_found;

    switch (true) {
      // No 'Products'? No render
      case this.props.products.length === 0:
        isRendered = false;
        break;
      // Current family is favorite
      case this.props.family.isFavoriteFamily:
        let favoriteRefs = this.props.user.favorite_products.map(
          (value) => value.ref_frn,
        );

        // Lets check if we find favorite products for current user
        if (favoriteRefs.length !== 0) {
          let products_ref_frn = this.props.products.map(
            (value) => value.ref_frn,
          );
          favorites_found = ArrayUtil.intersection(
            products_ref_frn,
            favoriteRefs,
          );
        }

        isRendered =
          favorites_found && favorites_found.length !== 0 ? true : false;
        break;
      default:
        break;
    }

    let products = ProductsParserUtil.parseProducts(
      this.props.products,
      !this.props.family.isFavoriteFamily
        ? this.props.family.name
        : isRendered
          ? favorites_found
          : "",
    );
    const subCategories = Array.from(
      new Set(products.map((p) => p.sous_famille)),
    );

    this.paginator.init(products.length);

    let productsNode = products.map((product) => {
      // NOTE : THESE CHECKS MIGHT BE UNNECESSARY IF PRODUCT PARSING WAS WELL DONE BEFORE
      // First of all, check if we are in favorite category
      if (!this.props.family.isFavoriteFamily) {
        // If we are not, check if product family is the right one
        if (product.famille_id !== this.props.family._id) {
          return null;
        }
      }

      if (product.famille === this.state.searchProductId) {
        this.setState({ searchProductId: null });
      }

      // Search filter
      if (this.state.search && this.state.search !== "") {
        if (
          product.sous_famille
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product.designation
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product.caracteristiques
            .toUpperCase()
            .indexOf(this.state.search.toUpperCase()) === -1 &&
          product._id !== "" + this.state.searchProductId
        )
          return null;
      }

      if (
        this.state.searchProductId &&
        product._id !== this.state.searchProductId &&
        !this.formatsContain(product.formats, this.state.searchProductId)
      ) {
        return null;
      }

      if (
        this.state.subCategoryFilter &&
        product.sous_famille !== this.state.subCategoryFilter
      ) {
        return null;
      }

      if (!this.paginator.keep()) return null;

      return (
        <ProductRow
          product={product}
          searchProductId={this.state.searchProductId}
          key={product._id}
          openProductModal={(productFormat, isAddDeclination) =>
            this.openProductModal(
              productFormat,
              subCategories,
              isAddDeclination,
            )
          }
          openConfModal={(product) => this.openConfModal(product)}
          downloadPDF={(product, callback) =>
            this.downloadPDF(product, callback)
          }
          onAdd={(e, productId, quantity, successCallback) =>
            this.add(e, productId, quantity, successCallback)
          }
          mode={this.props.mode}
          addProduct={(product) => this.props.addProduct(product)}
          deleteProduct={(product) => this.props.deleteProduct(product)}
          productsFromCatalog={this.props.productsFromCatalog}
          isLoading={this.state.isLoading}
          deskProductId={this.props.deskProductId}
        />
      );
    });

    let placeholder = this.props.intl.formatMessage({ id: "Search" });

    const menuAction = (
      <Dropdown className="ms-auto">
        <Dropdown.Toggle id="dropdownMenuLink">
          <FormattedMessage id="Actions" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!this.props.clientId && (
            <>
              <Dropdown.Item
                onClick={(e) => this.openProductModal(null, subCategories)}
              >
                <Icon icon="circle-plus" className={"me-2"} />
                <FormattedMessage id="Add.Product" />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.updateRateVisibility()}>
                <Icon
                  icon={
                    this.props.user.preferencies.is_rate_visible
                      ? "eye-slash"
                      : "eye"
                  }
                  className={"me-2"}
                />
                <FormattedMessage
                  id={
                    this.props.user.preferencies.is_rate_visible
                      ? "Hide.Rate"
                      : "Display.Rate"
                  }
                />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={(e) => this.openProductsMarginModal()}>
                <Icon icon="calculator" className={"me-2"} />
                <FormattedMessage id={"Products.Apply.Margin"} />
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );

    return (
      <Container fluid>
        <TableToolbar>
          <Row className="align-items-center">
            <Col className="col-auto">
              <MenuButton
                icon="arrow-circle-left"
                className="ms-auto"
                onClick={() =>
                  this.props.setState({ family: null, product: null })
                }
              >
                {<FormattedMessage id="Return" />}
              </MenuButton>
            </Col>
            <Col className="col-auto">
              {this.props.family.name}
              {this.props.family.isFavoriteFamily && (
                <strong>({products.length})</strong>
              )}
            </Col>
            <Col className="col-auto">
              <Icon icon="chevron-right" />
            </Col>
            <Col className="col-auto">
              <FormSelect
                id="products-filter-sub-category"
                value={this.state.subCategoryFilter}
                onChange={(e) =>
                  this.setState({ subCategoryFilter: e.target.value })
                }
              >
                <option value="">
                  {this.props.intl.formatMessage({ id: "All.Products" })}
                </option>
                {subCategories &&
                  subCategories.length > 0 &&
                  subCategories.map((c) => (
                    <option value={c} key={c}>
                      {c}
                    </option>
                  ))}
              </FormSelect>
            </Col>
            {isRendered && (
              <Col>
                <InputTextElement
                  type="search"
                  value={this.state.search}
                  onChange={(e) => this.search(e)}
                  placeholder={placeholder}
                />
              </Col>
            )}
            {isRendered &&
              !this.isClient() &&
              this.props.user.permissions.can_manage_catalog &&
              !this.props.deskProductId && (
                <Col className="col-auto text-end">{menuAction}</Col>
              )}
          </Row>
        </TableToolbar>

        {!isRendered && (
          <TableToolbar message={<FormattedMessage id="Products.None" />} />
        )}

        {isRendered && (
          <table className="table table-striped tablee4mad">
            <thead>
              <tr>
                <th className="col-2">
                  <FormattedMessage id="Product" />
                </th>
                <th className="col-auto">
                  <FormattedMessage id="Description" />
                </th>
                <th className="col-2">
                  <FormattedMessage id="Features" />
                </th>
                <th className="text-nowrap">
                  <FormattedMessage id="Unit.Price" />
                </th>
                {!this.isClient() &&
                  this.props.user.permissions.can_manage_catalog &&
                  this.props.user.preferencies.is_rate_visible && (
                    <th className="text-nowrap">
                      <FormattedMessage
                        id={
                          this.props.productsSettingsAdmin.margin_selected ===
                          "margin"
                            ? "Margin.Rate"
                            : "Gross.Margin.Rate"
                        }
                      />
                    </th>
                  )}
                {this.isClient() && (
                  <th className="text-end">
                    <FormattedMessage id="Quantity" />
                  </th>
                )}
                <th className="text-end">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{productsNode}</tbody>
          </table>
        )}

        {this.paginator.render()}
        {this.state.modal}
        {this.state.template && (
          <div
            className="d-none"
            style={{ position: "absolute", marginLeft: "-10000px" }}
          >
            {this.state.template}
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    products: state.products,
    mercurials: state.mercurials,
    productsSettingsAdmin: state.productsSettingsAdmin,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddProductToCart: (data, successCallback) =>
      dispatch(addProductToCart(data, successCallback)),
    onAddProductToCartAdmin: (data, successCallback) =>
      dispatch(addProductToCartAdmin(data, successCallback)),
    onUpdateUser: (data, successCallback) =>
      dispatch(updateUser(data, successCallback)),
    onDeleteProduct: (data, successCallback) =>
      dispatch(deleteProductAdmin(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Products));
