import React from "react";
import { Modal } from "react-bootstrap";
import Icon from "../Icon.js";

class LoadingModal extends React.Component {
  close() {
    this.props.closeModal();
  }

  render() {
    const { title, content, event } = this.props;
    event();
    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="alert alert-warning">{content}</p>
          <div className="text-center m-5">
            <div>
              <Icon icon="fa-spinner" className={"fa-spin-pulse"} size="2xl" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default LoadingModal;
