const MercurialsMapping = {
  famille: "FAMILLE",
  sous_famille: "SOUS FAMILLE",
  caracteristiques: "CARACTERISTIQUES",
  designation: "DESIGNATION",
  unite_de_vente: "UNITE DE VENTE",
  min_cde: "MIN CDE",
  ref: "REF",
  prix_u_ht_emera: "PRIX U. HT",
  prix_limite_ttc: "PRIX LIMITE DE VENTE TTC",
  prix_achat_ht: "PRIX ACHAT HT",
  tva: "TVA",
  fournisseur: "FOURNISSEUR",
  ref_frn: "REF FRN",
  lpp_code: "CODE LPP",
  lpp_amount: "MONTANT LPP",
  ref_product_desk: "REF PRODUIT TYPE",
  taux_marge: "TAUX MARGE",
  taux_marque: "TAUX MARQUE",
};

export default MercurialsMapping;
