import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { updateGeneralSettings } from "../../../../actions/settings/generalSettings/generalSettings";
import { Col, Row, Tabs, Tab, Alert, Container, Card } from "react-bootstrap";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal";
import SwitchToggle from "../../../sub/SwitchToggle";
import CustomLabel from "../../../sub/CustomLabel";

class GeneralSettingsAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null,
    };
  }

  onCheck(key, value) {
    const successCallback = () => this.update(key, value);

    if (!value) {
      return this.setState({
        modal: (
          <ConfirmationModal variant="warning" onAccept={successCallback}>
            <Alert variant="warning">
              <FormattedMessage id="Securised.Documents.Message" />
            </Alert>
          </ConfirmationModal>
        ),
      });
    }

    successCallback();
  }

  update(field, value) {
    const data = {
      generalSettingsId: this.props.generalSettings._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateGeneralSettings(data);
  }

  render() {
    if (this.props.generalSettings.length === 0) return null;

    return (
      <>
        <Tabs defaultActiveKey="emails" id="securityTabs">
          <Tab
            eventKey="emails"
            title={this.props.intl.formatMessage({ id: "Emails" })}
          >
            <Container className="w-75">
              <Card>
                <Card.Body>
                  <Row>
                    <Col className="col-auto">
                      <SwitchToggle
                        checked={
                          this.props.generalSettings.useEmailSecurisedDocuments
                        }
                        onChange={(e) =>
                          this.onCheck(
                            "useEmailSecurisedDocuments",
                            !this.props.generalSettings
                              .useEmailSecurisedDocuments,
                          )
                        }
                      />
                    </Col>
                    <Col>
                      <CustomLabel
                        label={<FormattedMessage id="Securised.Documents" />}
                        labelClassName={
                          this.props.generalSettings.useEmailSecurisedDocuments
                            ? "text-success"
                            : "text-danger"
                        }
                        help={
                          <FormattedMessage id="Securised.Documents.Message" />
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Container>
          </Tab>
        </Tabs>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateGeneralSettings: (data) => dispatch(updateGeneralSettings(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(GeneralSettingsAdmin));
