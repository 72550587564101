var InputLength = {
  TEXT: 500,
  TEXT_LONG: 1000,
  NAME: 50,
  EMAIL: 255,
  PHONE: 20,
  TITLE: 50,
  ID: 100,
  URL: 2048,
  ADDRESS: 1000,
  POSTAL_CODE: 10,
};

module.exports = InputLength;
