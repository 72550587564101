import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addProductFromCatalog,
  deleteProductFromCatalog,
  sortProductFromCatalog,
  updateDeskProduct,
} from "../../../../actions/settings/deskCategories/admin/deskProducts.js";
import ConfirmationModal from "../../../sub/modals/ConfirmationModal.js";
import { Alert, Col, Row } from "react-bootstrap";
import { getProductsByMercurialIdAdmin } from "../../../../actions/products/products.js";
import ProductsParserUtil from "../../../../util/ProductsParserUtil.js";
import MercurialStatus from "../../../../enums/MercurialStatus.js";
import DeskProductsCatalogProductsModal from "./DeskProductsCatalogProductsModal.js";
import DeskProductsCatalogProductRow from "./DeskProductsCatalogProductRow.js";
import MenuButton from "../../../sub/bootstrap/MenuButton.js";

class DeskProductsCatalogProducts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      mercurialId: "",
      selectedCatalog: null,
    };
  }

  componentDidMount() {
    const mercurialsIds = [];
    for (const p of this.props.productsFromCatalog) {
      if (p.mercurial_id && !mercurialsIds.includes(p.mercurial_id)) {
        mercurialsIds.push(p.mercurial_id);
      }
    }

    if (mercurialsIds.length !== 0) {
      for (const id of mercurialsIds) {
        this.props.onGetProductsByMercurialIdAdmin(id);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.productsFromCatalog !== this.props.productsFromCatalog ||
      (prevState.modal !== this.state.modal && this.state.modal === null)
    ) {
      const mercurialsIds = [];
      for (const p of this.props.productsFromCatalog) {
        if (p.mercurial_id && !mercurialsIds.includes(p.mercurial_id)) {
          mercurialsIds.push(p.mercurial_id);
        }
      }

      if (mercurialsIds.length !== 0) {
        for (const id of mercurialsIds) {
          this.props.onGetProductsByMercurialIdAdmin(id);
        }
      }
    }
  }

  deleteProduct(product) {
    const data = {
      deskProductId: this.props.deskProductId,
      product: product,
    };

    this.props.onDeleteProductFromCatalog(data);
  }

  addProduct(product) {
    const data = {
      deskProductId: this.props.deskProductId,
      product: product,
    };

    this.props.onAddProductFromCatalog(data);
  }

  closeModal() {
    this.setState({ modal: null });
  }

  openConfModal(product) {
    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Confirm" />}
          onAccept={() => this.deleteProduct(product)}
          onDecline={() => this.closeModal()}
        >
          <p>
            <FormattedMessage id={"Product.Remove.Confirmation.2"} />
          </p>
          <p>
            <FormattedMessage id={"Product.Remove.Warning"} />
          </p>
        </ConfirmationModal>
      ),
    });
  }

  getCatalogSelectOptions() {
    const options = [];
    for (const mercurial of this.props.mercurials) {
      const now = new Date();
      const hasExpired =
        mercurial.end_date &&
        (new Date(mercurial.start_date) > now ||
          new Date(mercurial.end_date) < now);
      if (!hasExpired && mercurial.status !== MercurialStatus.INACTIVE) {
        options.push({ value: mercurial._id, label: mercurial.name });
      }
    }
    return options;
  }

  openCatalogModal() {
    this.setState({
      modal: (
        <DeskProductsCatalogProductsModal
          deskProductId={this.props.deskProductId}
          productsFromCatalog={this.props.productsFromCatalog}
          addProduct={(product) => this.addProduct(product)}
          deleteProduct={(product) => this.deleteProduct(product)}
          onUpdateDeskProduct={(data) => this.props.onUpdateDeskProduct(data)}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  onSort(product, direction) {
    if (!product) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      deskProductId: this.props.deskProductId,
      product: product,
      direction: direction,
    };

    this.props.onSortProductFromCatalog(data);
  }

  render() {
    const { deskProductId, productsFromCatalog, products } = this.props;

    if (!deskProductId || !productsFromCatalog) return null;

    const productsFromC = products.filter((p) =>
      productsFromCatalog.find(
        (pFromCatalog) => pFromCatalog.product_id === p._id,
      ),
    );

    for (let p of productsFromC) {
      p.sortIndex = productsFromCatalog.find(
        (_p) => _p.product_id === p._id,
      ).sortIndex;
    }

    const productsFromCatalogIds = productsFromCatalog.map((p) =>
      p.product_id,
    );

    const formatedProducts = ProductsParserUtil.parseProducts(
      productsFromC,
      productsFromCatalogIds,
    );

    const deskProductProductsFromCatalogNodes =
      formatedProducts &&
      formatedProducts.length > 0 &&
      formatedProducts
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((product) => (
          <DeskProductsCatalogProductRow
            key={product._id}
            product={product}
            productsFromCatalog={productsFromCatalog}
            onSort={(product, direction) => this.onSort(product, direction)}
            deleteProduct={(product) => this.openConfModal(product)}
            lastSortIndex={
              formatedProducts[formatedProducts.length - 1].formats[
                formatedProducts[formatedProducts.length - 1].formats.length - 1
              ].sortIndex
            }
          />
        ));

    return (
      <div>
        <Alert variant="info">
          <FormattedMessage id="Types.Products.From.Catalog.Notice" />
        </Alert>
        <table className="table table-striped col-12 tablee4mad mt-3">
          <thead>
            <tr>
              <th className="align-middle col-2">
                <FormattedMessage id={"Mercurial"} />
              </th>
              <th className="align-middle col-2">
                <FormattedMessage id={"Family"} />
              </th>
              <th className="align-middle col-2">
                <FormattedMessage id={"Product"} />
              </th>
              <th className="align-middle col-3">
                <FormattedMessage id="Declinations" />
              </th>
              <th className="col-3">
                <Row>
                  <Col md={12} className="text-end">
                    <MenuButton onClick={() => this.openCatalogModal()}>
                      <FormattedMessage id="Select.Products" />
                    </MenuButton>
                  </Col>
                </Row>
              </th>
            </tr>
          </thead>
          {formatedProducts.length > 0 && (
            <tbody>{deskProductProductsFromCatalogNodes}</tbody>
          )}
        </table>

        {formatedProducts.length === 0 && (
          <Alert variant="secondary" className="mt-3">
            <FormattedMessage id={"Empty.Products.From.Catalog"} />
          </Alert>
        )}

        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    auditDeskSettings: state.auditDeskSettings,
    mercurials: state.mercurials,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddProductFromCatalog: (data, successCallback) =>
      dispatch(addProductFromCatalog(data, successCallback)),
    onSortProductFromCatalog: (data, successCallback) =>
      dispatch(sortProductFromCatalog(data, successCallback)),
    onDeleteProductFromCatalog: (data, successCallback) =>
      dispatch(deleteProductFromCatalog(data, successCallback)),
    onUpdateDeskProduct: (data) => dispatch(updateDeskProduct(data)),
    onGetProductsByMercurialIdAdmin: (mercurialId, getAllProducts) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId, getAllProducts)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskProductsCatalogProducts));
