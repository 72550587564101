import {
  useState,
  FC,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent,
  useEffect,
} from "react";
import { Form } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { NotificationManager } from "react-notifications";

interface InputNumberProps {
  id: string;
  defaultValue: number;
  min?: number;
  max?: number;
  step?: number;
  onChange: (value: number) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

const InputNumber: FC<InputNumberProps> = ({
  id,
  defaultValue,
  min,
  max,
  step,
  onChange,
  onKeyDown,
  onBlur,
  placeholder,
  disabled,
  className,
}) => {
  // Utilisation de useState pour gérer l'état de la valeur du champ input
  const [inputValue, setInputValue] = useState<number>(defaultValue);

  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);

  // Function to handle the change in select list
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let inputValue = parseFloat(event.target.value);

    if (min !== undefined && max !== undefined) {
      switch (true) {
        case inputValue < min:
        case inputValue > max:
          // Define inputValue from min or max
          inputValue = inputValue > max ? max : min;
          // Display notification
          NotificationManager.warning(
            <FormattedMessage
              id="Value.Must.Be.Between"
              values={{
                min: min,
                max: max,
              }}
            />,
            <FormattedMessage id="Warning" />,
            3000,
          );
          break;
        default:
          break;
      }
    }

    onChange(inputValue);
    setInputValue(inputValue);
  };

  // Function to handle the blur event
  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return (
    <Form.Control
      id={id}
      type="number"
      min={min !== undefined ? min.toString() : ""}
      max={max !== undefined ? max.toString() : ""}
      step={step !== undefined ? step.toString() : ""}
      value={inputValue !== undefined ? inputValue.toString() : ""}
      onChange={handleChange}
      /* placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ""} */
      placeholder={placeholder ? placeholder : ""}
      disabled={disabled || false}
      onKeyDown={onKeyDown}
      onBlur={handleBlur}
      className={className}
    />
  );
};

export default InputNumber;
