import React from "react";
import Switch from "react-switch";
import Util from "../../util/Util";

export default class SwitchToggle extends React.Component {
  // Check if required props are set and are of type "string" and not empty
  checkProp = (myProp) => {
    return Util.typeOf(myProp) === "String" && myProp.length > 0;
  };

  onChange = (e) => {
    return this.props.onChange(e);
  };

  render() {
    return (
      <label htmlFor={this.props.id} className="m-0">
        <Switch
          height={22}
          width={42}
          onChange={(e) => {
            this.onChange(e);
          }}
          checked={this.props.checked}
          disabled={this.props.disabled}
        />
      </label>
    );
  }
}
