import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TextareaCounter from "../sub/TextareaCounter";
import Util from "../../util/Util";
import { contact } from "../../actions/contacts/contacts";
import AlertModal from "../sub/modals/AlertModal";
import CustomLabel from "../sub/CustomLabel";
import InputTextElement from "../sub/InputTextElement";
import InputLength from "../../enums/InputLength";
import { Alert, Col, Form, Row } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";

class Contacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      object: "",
      message: "",
      disabled: false,
      modal: null,
    };
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
      error: null,
    });
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.object) ||
      Util.emptyString(this.state.message)
    );
  }

  closeModal() {
    this.setState({
      modal: null,
      object: "",
      message: "",
    });
  }

  send() {
    if (
      Util.emptyString(this.state.object) ||
      Util.emptyString(this.state.message) ||
      this.state.disabled
    )
      return;

    this.setState({ disabled: true });
    let onUpdatePwdSuccess = () => {
      let title = <FormattedMessage id="Success" />;
      let content = <FormattedMessage id="Mail.Sent" />;
      this.setState({
        disabled: false,
        modal: (
          <AlertModal
            variant="success"
            title={title}
            content={content}
            onClose={() => this.closeModal()}
          />
        ),
      });
    };

    let data = {
      object: this.state.object,
      message: this.state.message,
    };

    this.props.onContact(data, onUpdatePwdSuccess);
  }

  render() {
    let commercialSign = false;

    if (
      this.props.generalSettings.commercial_sign ||
      this.props.generalSettings.contact_information ||
      this.props.generalSettings.phone ||
      this.props.generalSettings.email ||
      this.props.generalSettings.website
    ) {
      commercialSign = true;
    }

    return (
      <React.Fragment>
        <div className="row">
          {commercialSign && (
            <div className="col-5 ms-5">
              <Form.Group as={Row} className="align-items-center">
                <strong className="col-12">
                  {this.props.generalSettings.commercial_sign}
                </strong>
                <span className="col-12">
                  {this.props.generalSettings.contact_information}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.phone}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.email}
                </span>
                <span className="col-12">
                  {this.props.generalSettings.website}
                </span>
              </Form.Group>
            </div>
          )}
          {this.props.admin && (
            <Col md={5}>
              <Form.Group as={Row} className="align-items-center">
                <strong className="col-12">
                  <FormattedMessage id="Commercial" />
                </strong>
                <span className="col-12">{this.props.admin.email}</span>
                <span className="col-12">{this.props.admin.phone}</span>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Object" })}
                  htmlFor="object"
                  displayAsCol
                  required
                />
                <Col md={7}>
                  <InputTextElement
                    className="form-control"
                    type="text"
                    id="object"
                    rows="7"
                    autoComplete="off"
                    value={this.state.object}
                    onChange={(e) => this.onChange("object", e.target.value)}
                    maxLength={InputLength.TEXT}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="align-items-center">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Message" })}
                  htmlFor="message"
                  displayAsCol
                  required
                />
                <Col md={7}>
                  <TextareaCounter
                    countLimit={InputLength.TEXT_LONG}
                    id="message"
                    value={this.state.message}
                    onChange={(e) => this.onChange("message", e.target.value)}
                  />
                </Col>
              </Form.Group>

              <br />
              <br />

              <MenuButton
                type="submit"
                onClick={() => this.send()}
                disabled={this.state.disabled || this.hasEmptyField()}
              >
                <FormattedMessage id="Send" />
              </MenuButton>
            </Col>
          )}
          {!this.props.admin && (
            <Col md={5}>
              <Alert variant="secondary">
                <FormattedMessage id="Have.Not.Admin" />
              </Alert>
            </Col>
          )}
        </div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onContact: (data, successCallback) =>
      dispatch(contact(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Contacts));
