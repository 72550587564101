import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addDeskCategory,
  updateDeskCategory,
  getDeskCategory,
  addDeskCategoryFile,
} from "../../../../actions/settings/deskCategories/admin/deskCategories";
import FileDropZone from "../../../sub/FileDropZone";
import Util from "../../../../util/Util";
import APIUrl from "../../../../APIUrl";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import InputLength from "../../../../enums/InputLength";
import InputTextElement from "../../../sub/InputTextElement";
import TextareaCounter from "../../../sub/TextareaCounter";
import { Col, Form, Row } from "react-bootstrap";
import CustomLabel from "../../../sub/CustomLabel";

class DeskCategoryModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description_text: "",
      nameError: null,
      disabled: false,
      modal: null,
    };

    if (this.props.deskCategoryId) {
      const category = this.props.deskCategories.find(
        (r) => r._id === this.props.deskCategoryId,
      );

      if (category) {
        this.state = {
          name: category.name,
          description_text: category.description_text || "",

          nameError: null,
          disabled: false,
          modal: null,
        };
      }
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
    });
  }

  onDropFile(file, clearCallback) {
    if (!file || file.length === 0) return;

    // Update mode. Immediately send file to BE
    if (this.props.deskCategoryId) {
      this.setState({ disabledFileUpload: true });

      let formData = new FormData();
      formData.append(file.name, file);

      var successCallback = () => {
        this.setState({ disabledFileUpload: false });
        clearCallback();
      };

      // Send to BE
      this.props.onAddDeskCategoryFile(
        this.props.deskCategoryId,
        formData,
        successCallback,
      );
    }
    // Create mode. Store the file and wait for the onComplete event
    else {
      this.setState({ file: file });
    }
  }

  onSubmit() {
    if (this.checkErrors()) return;

    const sendfile = (category) => {
      if (!this.state.file || this.state.file.length === 0)
        return this.props.closeModal();

      let formData = new FormData();
      formData.append(this.state.file.name, this.state.file);

      this.props.onAddDeskCategoryFile(category._id, formData, () =>
        this.props.closeModal(),
      );
    };

    const imageExist = !!this.state.file;

    const data = {
      name: this.state.name,
      description_text: this.state.description_text,
      image: imageExist,
      sortIndex: this.props.deskCategories
        ? this.props.deskCategories.length
        : 1,
    };

    this.props.onAddDeskCategory(data, sendfile);
  }

  onBlur(updatedField, updatedValue) {
    if (Util.emptyString(updatedValue)) return;

    const data = {
      deskCategoryId: this.props.deskCategoryId,
      updatedField: updatedField,
      updatedValue: updatedValue,
    };

    if (updatedField === "name") {
      const successCallback = (category) => {
        if (category)
          return this.setState({
            nameError: <FormattedMessage id="Category.Already.Exists" />,
          });

        this.setState({ nameError: null });
        if (this.props.deskCategoryId) this.props.onUpdateDeskCategory(data);
      };

      if (this.props.deskCategoryId) {
        const category = this.getDeskCategory(this.props.deskCategoryId);
        if (category && updatedValue === category.name)
          return this.setState({ nameError: null });
      }
      this.props.onGetDeskCategory(updatedValue, successCallback);
    } else {
      if (!this.props.deskCategoryId) return;
      this.props.onUpdateDeskCategory(data);
    }
  }

  checkErrors() {
    return (
      this.state.nameError ||
      this.state.disabled ||
      Util.emptyString(this.state.name)
    );
  }

  getDeskCategory(categoryId) {
    for (let category of this.props.deskCategories) {
      if (category._id === categoryId) return category;
    }
  }

  render() {
    let img, category;
    const { deskCategories, deskCategoryId } = this.props;

    if (deskCategoryId) {
      category = deskCategories.find((r) => r._id === deskCategoryId);
      if (!category) return null;

      let imgSrc =
        APIUrl.getDeskCategoryImg +
        category._id +
        "/" +
        Math.random() +
        "?token=" +
        APIUrl.jwtToken;
      img = (
        <img
          src={imgSrc}
          className="col-form-label m-3"
          alt={"desk_category_img" + Math.random()}
          height="150"
          width="150"
        />
      );
    }

    return (
      <React.Fragment>
        {category && (
          <div className="d-none d-lg-block w-150 rounded light-card text-center">
            <div className="m-0 p-2">
              {img}
              {category.image && (
                <MenuButton
                  icon="trash"
                  variant="outline-dark"
                  onClick={(e) => this.onBlur("image", false)}
                  hover={<FormattedMessage id="Restore.Default.Image" />}
                />
              )}
            </div>
          </div>
        )}

        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={<FormattedMessage id="Name" />}
            htmlFor="name"
            displayAsCol
            required
          />
          <Col md={7}>
            <InputTextElement
              className="form-control"
              type="text"
              id="name"
              maxLength={InputLength.NAME}
              autoComplete="off"
              value={this.state.name}
              onChange={(e) =>
                this.onChange("name", e.target.value.toUpperCase())
              }
              onBlur={(e) => this.onBlur("name", e.target.value.toUpperCase())}
            />
            <div className="text-danger">
              <small>{this.state.nameError}</small>
            </div>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={<FormattedMessage id="Visuel" />}
            htmlFor="logo"
            displayAsCol
          />
          <Col md={7}>
            <FileDropZone
              disabled={this.state.disabledDropZone}
              onDropFile={(file, clearCallback) =>
                this.onDropFile(file, clearCallback)
              }
              acceptedExtensions={["jpg", "png", "jpeg"]}
              multiple={false}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="align-items-center mb-3">
          <CustomLabel
            label={<FormattedMessage id="Description" />}
            htmlFor="defaultText"
            displayAsCol
          />
          <Col md={7} id="defaultText">
            <TextareaCounter
              countLimit={InputLength.TEXT_LONG}
              id="description"
              rows="7"
              value={this.state.description_text}
              onChange={(e) =>
                this.onChange("description_text", e.target.value)
              }
              onBlur={(e) =>
                this.onBlur("description_text", this.state.description_text)
              }
            />
          </Col>
        </Form.Group>

        {!this.props.deskCategoryId && (
          <div className="modal-footer">
            <MenuButton
              className="secondary"
              data-dismiss="modal"
              onClick={() => this.close()}
            >
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              onClick={() => this.onSubmit()}
              disabled={this.checkErrors()}
            >
              <FormattedMessage id="Add" />
            </MenuButton>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddDeskCategory: (data, successCallback) =>
      dispatch(addDeskCategory(data, successCallback)),
    onGetDeskCategory: (data, successCallback) =>
      dispatch(getDeskCategory(data, successCallback)),
    onUpdateDeskCategory: (data) => dispatch(updateDeskCategory(data)),
    onAddDeskCategoryFile: (deskCategoryId, data, successCallback) =>
      dispatch(addDeskCategoryFile(deskCategoryId, data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskCategoryModalAdmin));
