export const GET_SOCKET = "GET_SOCKET";

export function getSocketAction(data) {
  return { type: GET_SOCKET, socket: data };
}

export const getSocket = function (data) {
  return function (dispatch) {
    dispatch(getSocketAction(data));
  };
};
