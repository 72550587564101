import { GET_INTERVENTIONS } from "../../actions/interventions/Interventions";

export default function interventions(state = [], action) {
  switch (action.type) {
    case GET_INTERVENTIONS:
      return action.interventions;
    default:
      return state;
  }
}
