import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../../util/DateUtil";
import APIUrl from "../../../APIUrl";
import { FormattedMessage, injectIntl } from "react-intl";
import Footer from "../../template/Footer";
import PriceUtil from "../../../util/PriceUtil";
import { nanoid } from "nanoid";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import Util from "../../../util/Util";

class DeskTemplateEstimatePDF extends React.Component {
  formatTva(tva) {
    return tva >= 1 ? tva : tva * 100;
  }

  render() {
    const {
      user,
      id,
      patient,
      prescribers,
      prescriber_id,
      helpers,
      helper_id,
      generalSettings,
      deskCart,
    } = this.props;

    const helper = helpers.find((h) => h._id === helper_id);
    const prescriber = prescribers.find((p) => p._id === prescriber_id);

    let totalHt = 0;
    let totalTtc = 0;
    let totalLppAmount = 0;

    for (const p of deskCart) {
      const lppAmount = p.lpp_amount * p.quantity || 0;
      totalHt += PriceUtil.priceHt(p, p.quantity, 2);
      totalTtc += PriceUtil.priceTtc(p, p.quantity, 2);
      totalLppAmount += lppAmount;
    }

    const totalTva = totalTtc - totalHt;
    const total = totalTtc - totalLppAmount;

    // RENDER PDF TEMPLATE
    return (
      <div id={id}>
        <div id="header">
          <table>
            <tbody>
              <tr>
                <td id="pharma_logo_td">
                  {generalSettings.logo && (
                    <img
                      id="pharma_logo"
                      src={`${APIUrl.getGeneralSettingsLogo}${
                        this.props.user.company_id
                      }/${generalSettings._id}/${Math.random()}`}
                      alt="logo client"
                      onError={(e) => {
                        e.target.src = "/images/placeholder_120x120.png";
                      }}
                    />
                  )}
                  <div className="titulaire">
                    <p>
                      <FormattedMessage id="Date" /> :{" "}
                      {DateUtil.toDate(Date.now())}
                      <br />
                      <FormattedMessage id="Estimate.Made.By" /> :{" "}
                      {Util.formatFullName(user.first_name, user.name)}
                    </p>
                  </div>
                </td>
                <td id="app_logo_td">
                  <img
                    id="app_logo"
                    src={APIUrl.rootUrl + "/img/pharmamad_logo.svg"}
                    alt=""
                  />
                  <div id="audit_infos">
                    <p>
                      <FormattedMessage id="Patient" /> :{" "}
                      {Util.formatFullName(patient.first_name, patient.name)}
                      <br />
                      {patient.address}
                      <br />
                      {patient.postal_code || ""} {patient.city || ""}
                    </p>
                    {helper?.name && (
                      <p>
                        <FormattedMessage id="Helper" /> :{" "}
                        {Util.formatFullName(helper.first_name, helper.name)}
                        <br />
                      </p>
                    )}
                    {prescriber?.name && (
                      <p>
                        <FormattedMessage id="Prescriber" /> :{" "}
                        {Util.formatFullName(
                          prescriber.first_name,
                          prescriber.name,
                        )}
                        <br />
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 id="audit_title">
          <FormattedMessage id="Price.Schedule" />
        </h1>

        <div id="estimate">
          <table className="table">
            <thead>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Ref.Short" />
                </td>
                <td style={{ width: "20%" }}>
                  <FormattedMessage id="Product" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Qty" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Lpp.Code" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Lpp.Amount" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Unit.Price.Excl.Tax" />
                </td>
                <td style={{ width: "8%" }}>
                  <FormattedMessage id="Unit.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Unit.Price.Incl.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Total.Excl.Tax" />
                </td>
                <td className="align-middle">
                  <FormattedMessage id="Total.Incl.Tax" />
                </td>
              </tr>
            </thead>
            <tbody>
              {deskCart.map((p) => (
                <tr key={nanoid()}>
                  <td className="align-middle">{p.ref}</td>
                  <td style={{ width: "20%" }}>
                    {ProductsParserUtil.swapDesignationDelimiter(p.designation)}
                  </td>
                  <td className="align-middle">{p.quantity}</td>
                  <td className="align-middle">{p.lpp_code || "-"}</td>
                  <td className="align-middle">
                    {p.lpp_amount
                      ? PriceUtil.formatEuro(p.lpp_amount * p.quantity)
                      : "-"}
                  </td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceHt(p, 1, 2))}
                  </td>
                  <td style={{ width: "8%" }}>{this.formatTva(p.tva)} %</td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceTtc(p, 1, 2))}
                  </td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceHt(p, p.quantity, 2))}
                  </td>
                  <td className="align-middle">
                    {PriceUtil.formatEuro(PriceUtil.priceTtc(p, p.quantity, 2))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <table
            className="table table-total"
            style={{ marginTop: "30px", width: "30%", float: "right" }}
          >
            <tbody>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Total.Excl.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalHt)}
                </td>
              </tr>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Unit.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalTva)}
                </td>
              </tr>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Total.Incl.Tax" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalTtc)}
                </td>
              </tr>
              <tr>
                <td className="align-middle">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Lpp.Amount" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(totalLppAmount)}
                </td>
              </tr>
              <tr className="total">
                <td className="align-middle">
                  <FormattedMessage id="Remains" />
                </td>
                <td className="align-middle">
                  {PriceUtil.formatEuro(total < 0 ? 0 : total)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Footer generalSettings={this.props.generalSettings} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskTemplateEstimatePDF));
