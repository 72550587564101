import ArrayUtil from "./ArrayUtil";
import { CrmProviders, CrmProvidersConfig } from "../enums/CrmProviders";

export default class MercurialslUtil {
  // Pass a company object to the constructor
  constructor(company) {
    this.company = company;
  }

  MercurialsDefaultMapping = {
    famille: "FAMILLE",
    sous_famille: "SOUS FAMILLE",
    caracteristiques: "CARACTERISTIQUES",
    designation: "DESIGNATION",
    unite_de_vente: "UNITE DE VENTE",
    min_cde: "MIN CDE",
    ref: "REF",
    prix_u_ht_emera: "PRIX U. HT",
    prix_limite_ttc: "PRIX LIMITE DE VENTE TTC",
    prix_achat_ht: "PRIX ACHAT HT",
    tva: "TVA",
    fournisseur: "FOURNISSEUR",
    ref_frn: "REF FRN",
    lpp_code: "CODE LPP",
    lpp_amount: "MONTANT LPP",
    ref_product_desk: "REF PRODUIT TYPE",
  };

  MercurialsMissingImagesMapping = {
    famille: "FAMILLE",
    designation: "DESIGNATION",
    ref: "REF",
    ref_frn: "REF FRN",
  };

  // This is the default list of excluded fields to generate Excel exports
  basicExcludedExportFieldList = [
    "_id",
    "__v",
    "company_id",
    "mercurial_id",
    "crm_id",
    "customImg",
    "createdAt",
    "updatedAt",
    "updatedBy",
    "famille_id",
    "family",
  ];

  // Basic excluded
  getFullExcludedExportFieldList() {
    let providersSpecificFieldsList = CrmProvidersConfig.map((provider) => {
      const fields = provider.mercurialFields;
      return Object.entries(fields)
        .map(([field, value]) => {
          return Object.entries(fields[field])
            .map(([key, value]) => {
              return key;
            })
            .shift();
        })
        .shift();
    });

    // Concat basic excluded fields with custom crm providers fields
    return this.basicExcludedExportFieldList.concat(
      providersSpecificFieldsList,
    );
  }

  getCRMData() {
    return this.company && this.company.crm ? this.company.crm : false;
  }

  getCRMProvider() {
    const crmData = this.getCRMData();

    if (crmData) {
      return CrmProvidersConfig.filter(
        (CrmProviders) => crmData.provider === CrmProviders.name,
      ).shift();
    } else {
      // console.info("CRM provider is null");
      return false;
    }
  }

  // Define specific field mapping depending on CRM provider
  getMercurialMapping() {
    const currentProvider = this.getCRMProvider();

    // No CRM provider defined for this company : we use the default params
    if (!currentProvider) {
      return this.MercurialsDefaultMapping;
    }

    // Get current provider specific mercurial fields
    const customFields = currentProvider.mercurialFields;

    // Add custom mercurial fields to field mapping
    customFields.map((customField) => {
      return Object.entries(customField).forEach(([key, value]) => {
        this.MercurialsDefaultMapping[key] = value;
      });
    });

    return this.MercurialsDefaultMapping;
  }

  getMercurialsMissingImagesMapping() {
    return this.MercurialsMissingImagesMapping;
  }

  // List the fields to exclude from mercurial Excel export depending on CRM provider
  getExcludedExportFields() {
    const currentMappingKeys = Object.keys(this.getMercurialMapping());
    return ArrayUtil.difference(
      this.getFullExcludedExportFieldList(),
      currentMappingKeys,
    );
  }

  /**
   * Test CRM Providers
   */
  providerIsMUST() {
    return this.getCRMProvider().name === CrmProviders.MUST.name;
  }

  providerIsLOMACO() {
    return this.getCRMProvider().name === CrmProviders.LOMACO.name;
  }

  providerIsHABY() {
    return this.getCRMProvider().name === CrmProviders.HABY.name;
  }
}
