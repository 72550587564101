import {
  GET_PRESCRIBER_TYPES,
  DELETE_PRESCRIBER_TYPE,
} from "../../../actions/settings/prescriberType/admin/prescriberType";

export default function prescriberTypes(state = [], action) {
  switch (action.type) {
    case GET_PRESCRIBER_TYPES:
      return action.prescriberTypes;
    case DELETE_PRESCRIBER_TYPE:
      return state.filter((t) => t._id !== action.prescriberTypeId);
    default:
      return state;
  }
}
