import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { Modal, Tab, Tabs } from "react-bootstrap";
import PatientGeneralInfo from "./PatientGeneralInfo";
import PatientClients from "./PatientClients";
import PatientEquipments from "./PatientEquipments";
import PatientAudit from "./PatientAudit";
import PatientDocuments from "./PatientDocuments";
import PatientDesk from "./PatientDesk";
import { FormattedMessage } from "react-intl";
import Util from "../../util/Util";

class PatientModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  getPatient(patientId) {
    for (let p of this.props.patients) {
      if (p._id === patientId) return p;
    }
  }

  render() {
    const {
      patientId,
      target,
      isOpen,
      intl,
      close,
      openModal,
      user,
      audits,
      deskaudits,
    } = this.props;

    const pId = user.client_type === "patient" ? user.patient_id : patientId;

    const patient = this.getPatient(pId);

    if (pId && !patient) {
      return null;
    }

    let aClassName = patient ? "" : "disabled";

    const content = (
      <>
        <Tabs defaultActiveKey={target} id="patientsTabs" mountOnEnter>
          <Tab
            eventKey="general"
            title={intl.formatMessage({ id: "General.Informations" })}
          >
            <PatientGeneralInfo
              patient={patient}
              openModal={(patient) => openModal(patient, "general")}
              close={() => close()}
            />
          </Tab>

          <Tab
            eventKey="helpers"
            title={intl.formatMessage({ id: "Helpers" })}
            tabClassName={aClassName}
          >
            <PatientClients patient={patient} client_typeFilter={"helper"} />
          </Tab>

          {user.client_type !== "prescriber" && (
            <Tab
              eventKey="prescribers"
              title={intl.formatMessage({ id: "Prescribers" })}
              tabClassName={aClassName}
            >
              <PatientClients
                patient={patient}
                client_typeFilter={"prescriber"}
              />
            </Tab>
          )}

          {(user.access_pharmamad || user.access_module_home) && (
            <Tab
              eventKey="audit"
              title={intl.formatMessage({ id: "Audit.MAD" })}
              tabClassName={aClassName}
            >
              <PatientAudit patient={patient} audits={audits} />
            </Tab>
          )}

          {user.access_module_desk && (
            <Tab
              eventKey="patients-audit-desk"
              title={intl.formatMessage({ id: "Audit.Desk.Secure" })}
              tabClassName={aClassName}
            >
              <PatientDesk patient={patient} audits={deskaudits} />
            </Tab>
          )}

          <Tab
            eventKey="equipments"
            title={intl.formatMessage({ id: "Equipments" })}
            tabClassName={aClassName}
          >
            <PatientEquipments
              patient={patient}
              openModal={(patient) => openModal(patient, "equipments")}
            />
          </Tab>

          <Tab
            eventKey="documents"
            title={intl.formatMessage({ id: "Documents" })}
            tabClassName={aClassName}
          >
            <PatientDocuments patient={patient} />
          </Tab>
        </Tabs>
        {this.state.modal}
      </>
    );

    return (
      <>
        {user.client_type !== "patient" ? (
          <Modal
            show={isOpen}
            onHide={() => close()}
            backdrop="static"
            keyboard={false}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {patient ? (
                  <>
                    <FormattedMessage id="Patient" /> :{" "}
                    {Util.formatFullName(patient.first_name, patient.name)}
                  </>
                ) : (
                  <FormattedMessage id="Patient.Add" />
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
          </Modal>
        ) : (
          content
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patients,
    clients: state.clients,
    audits: state.audits,
    deskaudits: state.deskaudits,
    user: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientModal));
