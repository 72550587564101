import React from "react";
import { connect } from "react-redux";
import CartRow from "./CartRow";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import { deleteAll } from "../../actions/cart/cart";
import { FormattedMessage, injectIntl } from "react-intl";
import { passOrder, passOrderAdmin } from "../../actions/orders/orders";
import ProductsTotalUtil from "../../util/ProductsTotalUtil";
import Roles from "../../enums/Roles";
import CartPromptModalAdmin from "./CartPromptModalAdmin";
import Paginator from "../sub/Paginator";
import { Link } from "react-router-dom";
import PriceUtil from "../../util/PriceUtil";
import DateUtil from "../../util/DateUtil";
import APIUrl from "../../APIUrl";
import { getListProducts } from "../../actions/products/products";
import Icon from "../sub/Icon";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import { Card, Badge, Col, Container, Row, Alert } from "react-bootstrap";
import MenuButton from "../sub/bootstrap/MenuButton";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import PopoverHelper from "../sub/bootstrap/PopoverHelper";
import TextareaCounter from "../sub/TextareaCounter";
import InputLength from "../../enums/InputLength";
import CustomLabel from "../sub/CustomLabel";

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disableButton: true,
      orderComment: "",
    };

    this.paginator = new Paginator(this);
  }

  passOrder() {
    const data = { comment: this.state.orderComment };

    // Admin
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      // Add the clientId to the call
      const clientId = document.getElementById("order-clientId").value;
      data.clientId = clientId;

      this.props.onPassOrderAdmin(data);
    }
    // User
    else {
      this.props.onPassOrder(data);
    }
  }

  deleteAll() {
    this.props.onDeleteAll();
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ConfirmationModal
          variant="warning"
          title={title}
          onAccept={successCallback}
          onDecline={() => this.closeModal()}
        >
          {content}
        </ConfirmationModal>
      ),
    });
  }

  handleOrderComment(orderComment) {
    return this.setState({ orderComment: orderComment });
  }

  openOrderPromptModal() {
    // Admin user
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    ) {
      this.setState({
        modal: (
          <CartPromptModalAdmin
            isOpen={true}
            successCallback={() => this.passOrder()}
            closeModal={() => this.closeModal()}
          />
        ),
      });
    }
    // Normal user
    else {
      this.setState({
        modal: (
          <ConfirmationModal
            title={<FormattedMessage id="Finalize.Order" />}
            onAccept={() => this.passOrder()}
            onDecline={() => this.closeModal()}
          >
            <Row>
              <Col>
                <Alert variant="info">
                  <FormattedMessage id="Hint.Order.Comment" />
                </Alert>
                <CustomLabel label={<FormattedMessage id="Comment" />} />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextareaCounter rows={7} countLimit={InputLength.TEXT_LONG} />
              </Col>
            </Row>
          </ConfirmationModal>
        ),
      });
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    var ordersSettings;
    if (
      this.props.ordersSettings.length === 0 &&
      this.props.ordersSettingsAdmin.length === 0
    )
      return null;
    if (this.props.ordersSettings.length !== 0)
      ordersSettings = this.props.ordersSettings;
    if (this.props.ordersSettingsAdmin.length !== 0)
      ordersSettings = this.props.ordersSettingsAdmin;

    var offreOfMoment;
    if (this.props.promoOperations.length > 0) {
      var promoOperation =
        this.props.promoOperations[
          Math.floor(
            Math.random() * Math.floor(this.props.promoOperations.length),
          )
        ];
      var product = promoOperation.product;
      if (!product) {
        offreOfMoment = null;
      } else {
        offreOfMoment = (
          <>
            <Card className="w-50 m-auto shadow" border="success">
              <Card.Header>
                <Card.Title className="text-success">
                  <Row className="align-items-center">
                    <Col className="col-auto pe-0">
                      <Icon icon="medal" size="2x" />
                    </Col>
                    <Col className="ps-0 text-nowrap text-end">
                      {this.props.intl.formatMessage({
                        id: "Offer.Of.Moment.Until",
                      }) +
                        DateUtil.toDate(promoOperation.endDate) +
                        ")"}
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Container>
                  <Row className="mb-3">
                    <Col>
                      {ProductsParserUtil.swapDesignationDelimiter(
                        product.designation,
                      ) +
                        " " +
                        product.unite_de_vente +
                        " "}
                    </Col>

                    <Col className="col-auto text-end p-0">
                      <h4>
                        <Badge bg="danger">
                          {PriceUtil.formatEuro(
                            PriceUtil.priceTtc(product, 1, 2),
                          )}
                        </Badge>
                      </h4>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
              <Card.Footer>
                <div className="text-end">
                  {promoOperation.fileName && (
                    <a
                      className="text-warning"
                      role="button"
                      href={
                        APIUrl.getPromoOperationFile +
                        promoOperation._id +
                        "/" +
                        promoOperation.fileName +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      rel="noopener noreferrer"
                      download={promoOperation.fileName}
                      target="_blank"
                    >
                      <MenuButton
                        icon="file"
                        className="me-2"
                        variant="outline-success"
                      >
                        <FormattedMessage id="Know.More" />
                      </MenuButton>
                    </a>
                  )}
                  <Link
                    to={{
                      pathname: "/home/families",
                      state: { family: product.family, product: product },
                    }}
                    className="text-decoration-none"
                  >
                    <MenuButton variant="success" icon="shopping-cart">
                      <FormattedMessage id="Consult.Product" />
                    </MenuButton>
                  </Link>
                </div>
              </Card.Footer>
            </Card>
          </>
        );
      }
    }

    if (this.props.cart.length === 0) {
      return (
        <React.Fragment>
          {offreOfMoment}
          <TableToolbar message={<FormattedMessage id="Empty.Cart" />}>
            <Link to="/home/families">
              <MenuButton>
                <FormattedMessage id="Go.To.Mercurial" />
              </MenuButton>
            </Link>
          </TableToolbar>
        </React.Fragment>
      );
    }

    // Helper that will do all calculations for us
    var productsTotalUtil = new ProductsTotalUtil(
      this.props.cart,
      this.props.products,
      null,
      null,
      ordersSettings,
    );
    // 'Delete cart' modal setup
    var modalTitle = <FormattedMessage id="Confirm" />;
    var modalContent = <FormattedMessage id="Empty.Cart.Confirm" />;
    var successCallbackConfModal = (e) => this.deleteAll(e);
    var isUndefinedProduct = false;
    this.paginator.init(this.props.cart.length);
    let cartNode = this.props.cart.map((cart) => {
      if (!this.paginator.keep()) return null;

      let product = cart.product;

      if (!product || product === undefined) isUndefinedProduct = true;

      return (
        <CartRow
          key={cart._id}
          cart={cart}
          product={product}
          openConfModal={(title, content, successCallback) =>
            this.openConfModal(title, content, successCallback)
          }
        />
      );
    });

    var shoppingAlert = null;
    var shippingAlert = null;

    if (!productsTotalUtil.aboveMinToOrder()) {
      shoppingAlert = (
        <Alert variant="danger">
          <FormattedMessage
            id="Min.Order.Cost"
            values={{
              cost: PriceUtil.formatEuro(productsTotalUtil.min_cost_to_order),
            }}
          />
        </Alert>
      );
    }

    if (productsTotalUtil.freeShipping()) {
      var max =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .max;
      var min =
        ordersSettings.shipping_costs[ordersSettings.shipping_costs.length - 1]
          .min;
      shippingAlert = (
        <Alert variant="info">
          <FormattedMessage
            id="Min.Shipping.Cost"
            values={{
              cost: max ? PriceUtil.formatEuro(max) : PriceUtil.formatEuro(min),
            }}
          />
        </Alert>
      );
    }

    return (
      <React.Fragment>
        <TableToolbar>
          <Link
            to={{
              pathname: "/home/families",
            }}
          >
            <MenuButton icon="arrow-circle-left">
              <FormattedMessage id="Go.To.Mercurial" />
            </MenuButton>
          </Link>
        </TableToolbar>

        <div className="content mt-3">
          {this.state.modal}
          {shoppingAlert}
          {shippingAlert}

          <table className="table table-striped tablee4mad">
            <thead>
              <tr>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Reference" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Family" />
                </th>
                <th scope="col" className="col col-3 text-no-wrap">
                  <FormattedMessage id="Designation" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Vending.unit" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Unit.Price.Excl.Tax" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Unit.Tax" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Unit.Price.Incl.Tax" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Excl.Tax" />
                </th>
                <th scope="col" className="col col-auto text-no-wrap">
                  <FormattedMessage id="Total" />{" "}
                  <FormattedMessage id="Incl.Tax" />
                </th>
                <th scope="col" className="col col-auto text-center">
                  <FormattedMessage id="Qty" />
                </th>
                <th scope="col" className="col col-auto text-center">
                  <FormattedMessage id="Actions" />
                </th>
              </tr>
            </thead>
            <tbody>{cartNode}</tbody>
          </table>

          {this.paginator.render()}

          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              {offreOfMoment}
            </Col>
            <Col md={4}>
              <Card>
                <Card.Body className="p-4">
                  <Row className="mb-3 border-bottom border-light">
                    <Col>
                      <FormattedMessage id="Total.Cart.Excl.Tax" />
                    </Col>
                    <Col
                      className={
                        !productsTotalUtil.aboveMinToOrder()
                          ? "text-danger text-end"
                          : "text-end"
                      }
                    >
                      <strong>
                        {!productsTotalUtil.aboveMinToOrder() && (
                          <PopoverHelper className="me-1" variant="danger">
                            <FormattedMessage
                              id="Cart.Min.Order"
                              values={{
                                amount: PriceUtil.formatEuro(
                                  productsTotalUtil.min_cost_to_order,
                                ),
                                missing: PriceUtil.formatEuro(
                                  Number(
                                    productsTotalUtil.min_cost_to_order -
                                      productsTotalUtil.totalHtCart,
                                  ),
                                ),
                              }}
                            />
                          </PopoverHelper>
                        )}
                        {PriceUtil.formatEuro(productsTotalUtil.totalHtCart)}
                      </strong>
                    </Col>
                  </Row>
                  {productsTotalUtil.urgent && (
                    <Row className="mb-3 border-bottom border-light text-danger">
                      <Col>
                        <FormattedMessage id="Emergency.Cost" />
                      </Col>
                      <Col className="text-end">
                        <strong>
                          {PriceUtil.formatEuro(
                            productsTotalUtil.urgent_shipping_cost,
                          )}
                        </strong>
                      </Col>
                    </Row>
                  )}
                  <Row className="mb-3 border-bottom border-light">
                    <Col>
                      <FormattedMessage id="Total.Excl.Tax" />
                    </Col>
                    <Col className="text-end">
                      <strong>
                        {PriceUtil.formatEuro(productsTotalUtil.totalHt)}
                      </strong>
                    </Col>
                  </Row>
                  <Row className="mb-3 border-bottom border-secondary pb-3">
                    <Col>
                      <FormattedMessage id="VAT" />
                    </Col>
                    <Col className="text-end">
                      <strong>
                        {PriceUtil.formatEuro(productsTotalUtil.totalTva)}
                      </strong>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <FormattedMessage id="Total.Cart.Excl.Tax" />
                    </Col>
                    <Col className="text-end">
                      <strong>
                        {PriceUtil.formatEuro(productsTotalUtil.totalTtc)}
                      </strong>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col className="pe-0">
                      <MenuButton
                        size="sm"
                        icon="trash"
                        variant="secondary"
                        onClick={() =>
                          this.openConfModal(
                            modalTitle,
                            modalContent,
                            successCallbackConfModal,
                          )
                        }
                      >
                        <FormattedMessage id="Cart.Empty" />
                      </MenuButton>
                    </Col>
                    <Col className="ps-0 text-end">
                      <MenuButton
                        size="sm"
                        icon="circle-check"
                        onClick={() => this.openOrderPromptModal()}
                        disabled={
                          !productsTotalUtil.aboveMinToOrder() ||
                          isUndefinedProduct
                        }
                      >
                        <FormattedMessage id="Finalize.Order" />
                      </MenuButton>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    products: state.products,
    user: state.user,
    ordersSettings: state.ordersSettings,
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    promoOperations: state.promoOperations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteAll: () => dispatch(deleteAll()),
    onPassOrder: (data) => dispatch(passOrder(data)),
    onPassOrderAdmin: (data) => dispatch(passOrderAdmin(data)),
    onGetListProducts: (data) => dispatch(getListProducts(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Cart));
