import React from "react";
import { connect } from "react-redux";
import APIUrl from "../../APIUrl";
import { FormattedMessage, injectIntl } from "react-intl";
import Footer from "../template/Footer";
import PriceUtil from "../../util/PriceUtil";
import { nanoid } from "nanoid";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { Badge } from "react-bootstrap";
import Roles from "../../enums/Roles";

class ProductDetailsTemplatePDF extends React.Component {
  formatTva(tva) {
    return tva >= 1 ? tva : tva * 100;
  }

  render() {
    const { user, id, generalSettings, product } = this.props;

    const imgSrc = `${APIUrl.getProductImg}${product.mercurial_id}/${encodeURIComponent(product.ref_frn)}/0/${encodeURIComponent(product.ref)}/${encodeURIComponent(product.parentProductRefFrn)}/?token=${APIUrl.jwtToken}`;
    const designation = ProductsParserUtil.getDesignation(this.props.product);
    const modelSubstrings = this.props.product.designation.split(
      process.env.REACT_APP_PRODUCT_DELIMITER,
    );
    const model = modelSubstrings[modelSubstrings.length - 1].trim();

    const hasFormats =
      product.formats &&
      product.formats[0].format &&
      product.formats[0].format !== product.designation;

    return (
      <div id={id}>
        <div id="header">
          <table>
            <tbody>
              <tr>
                <td id="pharma_logo_td">
                  {generalSettings.logo && (
                    <img
                      id="pharma_logo"
                      src={`${APIUrl.getGeneralSettingsLogo}${
                        user.company_id
                      }/${generalSettings._id}/${Math.random()}`}
                      alt="logo client"
                      onError={(e) => {
                        e.target.src = "/images/placeholder_120x120.png";
                      }}
                    />
                  )}
                </td>
                <td id="app_logo_td">
                  <img
                    id="app_logo"
                    src={APIUrl.rootUrl + "/img/pharmamad_logo.svg"}
                    alt=""
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 id="title">{designation}</h1>

        <div className="product-container">
          <Badge className="product-hierarchy">
            <label>
              {product.famille} <strong>{">"}</strong> {product.sous_famille}
            </label>
          </Badge>

          <div className="product-img">
            <img src={imgSrc} alt="" />
          </div>

          <div className="product-reference">
            <Badge>
              <FormattedMessage id="Reference" /> : {product.ref}
            </Badge>
          </div>

          <h3>
            <FormattedMessage id="Description" />
          </h3>

          <div className="product-description">{product.caracteristiques}</div>

          <h3>
            <FormattedMessage id="General.Informations" />
          </h3>

          <div className="product-info">
            <div>
              <strong>
                <FormattedMessage id="Brand" /> :
              </strong>{" "}
              {product.fournisseur}
            </div>
            {hasFormats && (
              <div>
                <strong>
                  <FormattedMessage id="Model" /> :
                </strong>{" "}
                {model}
              </div>
            )}
          </div>

          {user.role !== Roles.CLIENT && (
            <div className="product-info">
              <div>
                <strong>
                  <FormattedMessage id="Lpp.Code" /> :
                </strong>{" "}
                {product.lpp_code ? product.lpp_code : "-"}
              </div>
              <div>
                <strong>
                  <FormattedMessage id="Lpp.Amount" /> :
                </strong>{" "}
                {product.lpp_amount && product.lpp_amount > 0
                  ? PriceUtil.formatEuro(product.lpp_amount)
                  : "-"}
              </div>
            </div>
          )}

          <div className="product-info">
            <div>
              <strong>
                <FormattedMessage id="Unit.Price.Excl.Tax" /> :
              </strong>{" "}
              {PriceUtil.formatEuro(PriceUtil.priceHt(product, 1, 2))}
            </div>
            <div>
              <strong>
                <FormattedMessage id="Unit.Tax" /> :
              </strong>{" "}
              {this.formatTva(product.tva)} %
            </div>
            <div>
              <strong>
                <FormattedMessage id="Unit.Price.Incl.Tax" /> :
              </strong>{" "}
              {PriceUtil.formatEuro(PriceUtil.priceTtc(product, 1, 2))}
            </div>
          </div>

          <div className="product-info">
            <div>
              <strong>
                <FormattedMessage id="Min.To.Order" /> :
              </strong>{" "}
              {product.min_cde}
            </div>
            <div>
              <strong>
                <FormattedMessage id="Vending.unit" /> :
              </strong>{" "}
              {product.unite_de_vente}
            </div>
          </div>

          {hasFormats && (
            <div id="product-variant">
              <h3>
                <FormattedMessage id="Models" />
              </h3>

              <table className="table">
                <thead>
                  <tr>
                    <td className="align-middle">
                      <FormattedMessage id="Ref.Short" />
                    </td>
                    <td style={{ width: "30%" }}>
                      <FormattedMessage id="Model" />
                    </td>
                    {user.role !== Roles.CLIENT && (
                      <>
                        <td className="align-middle">
                          <FormattedMessage id="Lpp.Code" />
                        </td>
                        <td className="align-middle">
                          <FormattedMessage id="Lpp.Amount" />
                        </td>
                      </>
                    )}
                    <td className="align-middle">
                      <FormattedMessage id="Unit.Price.Excl.Tax" />
                    </td>
                    <td className="align-middle">
                      <FormattedMessage id="Unit.Tax" />
                    </td>
                    <td className="align-middle">
                      <FormattedMessage id="Unit.Price.Incl.Tax" />
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {product.formats.map((p) => (
                    <tr key={nanoid()}>
                      <td className="align-middle">{p.ref}</td>
                      <td style={{ width: "30%" }}>{p.format}</td>
                      {user.role !== Roles.CLIENT && (
                        <>
                          <td className="align-middle">
                            {p.lpp_code ? p.lpp_code : "-"}
                          </td>
                          <td className="align-middle">
                            {p.lpp_amount
                              ? PriceUtil.formatEuro(p.lpp_amount)
                              : "-"}
                          </td>
                        </>
                      )}
                      <td className="align-middle">
                        {PriceUtil.formatEuro(PriceUtil.priceHt(p, 1, 2))}
                      </td>
                      <td className="align-middle">
                        {this.formatTva(p.tva)} %
                      </td>
                      <td className="align-middle">
                        {PriceUtil.formatEuro(PriceUtil.priceTtc(p, 1, 2))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {this.props.id !== "preview-product-store" && (
          <Footer generalSettings={this.props.generalSettings} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ProductDetailsTemplatePDF));
