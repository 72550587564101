import APIUrl from "../../APIUrl";
import axios from "axios";
import { getPatientsAdmin } from "../patients/patients";

export const GET_DOCUMENTS = "GET_DOCUMENTS";

export const createDocument = function (data, successCallback) {
  return function () {
    return axios
      .post(APIUrl.createDocument, data)
      .then(function (response) {
        if (successCallback) return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addDocumentFile = function (documentId, file, successCallback) {
  return function () {
    return axios
      .post(APIUrl.addDocumentFile + "/" + documentId, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateDocument = function (data, successCallback) {
  return function () {
    return axios
      .post(APIUrl.updateDocument, data)
      .then(function () {
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteDocument = function (dataId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteDocument, { documentId: dataId })
      .then(function () {
        dispatch(getPatientsAdmin());
        if (successCallback) return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
