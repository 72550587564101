import React from "react";
import { FormattedMessage } from "react-intl";
import { Row, Col, Container, Badge, Card, Table } from "react-bootstrap";
import MenuButton from "../../sub/bootstrap/MenuButton";
import PriceUtil from "../../../util/PriceUtil";
import { nanoid } from "nanoid";
import ProductsParserUtil from "../../../util/ProductsParserUtil";
import CustomLabel from "../../sub/CustomLabel";

export default class DeskProductEstimate extends React.Component {
  formatTva(tva) {
    return tva >= 1 ? tva : tva * 100;
  }

  render() {
    const {
      deskCart,
      setOngoingState,
      scrollToTopDesk,
      addAudit,
      template,
      templateCerfa,
      templateEstimate,
      openConfirmationModal,
      openRelatedCatalogProductsModal,
      isLoading,
      updateCartProductQuantity,
    } = this.props;

    const titleConfirm = <FormattedMessage id="Confirm" />;
    const contentConfirmFinalyseAudit = <FormattedMessage id="Confirm.Audit" />;

    let totalTtc = 0;
    let totalLppAmount = 0;

    for (const p of deskCart) {
      const lppAmount = p.lpp_amount * p.quantity || 0;
      totalTtc += PriceUtil.priceTtc(p, p.quantity, 2);
      totalLppAmount += lppAmount;
    }

    const total = totalTtc - totalLppAmount;

    return (
      <Container>
        <Row>
          <Col>
            <Row>
              <Col className="text-center mb-3">
                <h1>
                  <Badge bg="dark">
                    <FormattedMessage id="Price.Schedule" />
                  </Badge>
                </h1>
              </Col>
            </Row>
            {deskCart.length > 0 && (
              <Card className="mt-3">
                <Card.Body>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>
                          <FormattedMessage id="Ref.Short" />
                        </th>
                        <th className="col-3">
                          <FormattedMessage id="Product" />
                        </th>
                        <th>
                          <FormattedMessage id="Lpp.Code" />
                        </th>
                        <th>
                          <FormattedMessage id="Lpp.Amount" />
                        </th>
                        <th>
                          <FormattedMessage id="Unit.Price.Incl.Tax" />
                        </th>
                        <th>
                          <FormattedMessage id="Total.Incl.Tax" />
                        </th>
                        <th className="text-center">
                          <FormattedMessage id="Actions" />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {deskCart.map((p) => (
                        <tr key={nanoid()}>
                          <td className="align-middle">{p.ref}</td>
                          <td className="col-3">
                            {ProductsParserUtil.swapDesignationDelimiter(
                              p.designation,
                            )}
                          </td>
                          <td className="align-middle">{p.lpp_code || "-"}</td>
                          <td className="align-middle">
                            {p.lpp_amount
                              ? PriceUtil.formatEuro(p.lpp_amount * p.quantity)
                              : "-"}
                          </td>
                          <td className="align-middle">
                            {PriceUtil.formatEuro(PriceUtil.priceTtc(p, 1, 2))}
                          </td>
                          <td className="align-middle">
                            {PriceUtil.formatEuro(
                              PriceUtil.priceTtc(p, p.quantity, 2),
                            )}
                          </td>
                          <td className="align-middle text-center">
                            <Row>
                              <Col md={12} className="text-center mb-2">
                                <MenuButton
                                  icon="minus"
                                  size="sm"
                                  disabled={p.quantity === 1}
                                  variant="outline-secondary"
                                  onClick={() =>
                                    updateCartProductQuantity(p, -1)
                                  }
                                />
                                <label className="me-3 ms-3">
                                  <strong>{p.quantity}</strong>
                                </label>
                                <MenuButton
                                  icon="plus"
                                  size="sm"
                                  variant="outline-secondary"
                                  onClick={() =>
                                    updateCartProductQuantity(p, 1)
                                  }
                                />
                              </Col>
                            </Row>
                            <MenuButton
                              icon="exchange"
                              size="sm"
                              variant="outline-secondary"
                              onClick={() =>
                                openRelatedCatalogProductsModal(
                                  p.deskStoreProduct,
                                  p,
                                )
                              }
                            >
                              <FormattedMessage id="Replace" />
                            </MenuButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row>
                    <Col className="text-end">
                      <FormattedMessage id="Total.Incl.Tax" />
                      <span className="ms-3">
                        <strong>{PriceUtil.formatEuro(totalTtc)}</strong>
                      </span>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-end">
                      <FormattedMessage id="Lpp.Support" />
                      <span className="ms-3">
                        <strong>{PriceUtil.formatEuro(totalLppAmount)}</strong>
                      </span>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col className="text-end text-info">
                      <strong>
                        <CustomLabel
                          label={<FormattedMessage id="Remains" />}
                          help={<FormattedMessage id="Hint.Def.Remains" />}
                        />
                      </strong>
                      <span className="ms-3">
                        <strong>
                          {PriceUtil.formatEuro(total < 0 ? 0 : total)}
                        </strong>
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}
            <Card className="mt-3">
              <Card.Body>
                <Row>
                  <Col>
                    <MenuButton
                      icon="arrow-left"
                      onClick={(e) => {
                        setOngoingState({ panier: true, estimate: null });
                        scrollToTopDesk();
                      }}
                    >
                      <FormattedMessage id="Return" />
                    </MenuButton>
                  </Col>
                  <Col className="text-end">
                    <MenuButton
                      icon="circle-check"
                      disabled={isLoading}
                      processing={isLoading}
                      onClick={() =>
                        !isLoading &&
                        openConfirmationModal(
                          titleConfirm,
                          contentConfirmFinalyseAudit,
                          () => addAudit(),
                        )
                      }
                      className="ms-2"
                      variant="success"
                    >
                      <FormattedMessage id="End.Audit" />
                    </MenuButton>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {template && (
              <div
                className="d-none"
                style={{ position: "absolute", marginLeft: "-10000px" }}
              >
                {template}
              </div>
            )}
            {templateCerfa && (
              <div
                className="d-none"
                style={{ position: "absolute", marginLeft: "-20000px" }}
              >
                {templateCerfa}
              </div>
            )}
            {templateEstimate && (
              <div
                className="d-none"
                style={{ position: "absolute", marginLeft: "-30000px" }}
              >
                {templateEstimate}
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
