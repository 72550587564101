import APIUrl from "../../APIUrl";
import axios from "axios";
import { getClientsAdmin } from "../clients/clients";

export const GET_PATIENTS = "GET_PATIENTS";

function getPatientsAction(patients) {
  return { type: GET_PATIENTS, patients: patients };
}

//********************************** PATIENT(ADMIN & SALEREP) ************************************/

export const getPatientsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatientsAdmin)
      .then(function (response) {
        dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatientAdmin = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getPatientEquipmentsReminded = function (successCallback) {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatientEquipmentsReminded)
      .then(function (response) {
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** PATIENT(USER) ************************************/

export const addPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatient, patient)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// Import patients
export const importPatients = function (
  data,
  successCallback,
  failureCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.importPatients, data)
      .then(function (response) {
        dispatch(getPatientsAdmin());
        dispatch(getClientsAdmin());
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        failureCallback(err.response.data);
        throw err;
      });
  };
};

export const getPatients = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPatients)
      .then(function (response) {
        dispatch(getPatientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updPatient = function (patient, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updPatient, patient)
      .then(function (response) {
        dispatch(getPatients());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatient = function (patientId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deletePatient, { patientId })
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

//********************************** DOCUMENTS ************************************/

export const addPatientDocumentFile = function (
  patientId,
  documentType,
  file,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPatientDocument + patientId + "/" + documentType, file, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        return successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deletePatientDocument = function (
  patientId,
  documentType,
  documentId,
  successCallback,
) {
  return function (dispatch) {
    return axios
      .get(
        APIUrl.deletePatientDocument +
          patientId +
          "/" +
          documentId +
          "/" +
          documentType,
      )
      .then(function (response) {
        dispatch(getPatientsAdmin());
        if (successCallback) {
          return successCallback();
        }
      })
      .catch(function (err) {
        throw err;
      });
  };
};
