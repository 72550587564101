import React from "react";
import DateUtil from "../../../../util/DateUtil";
import MercurialStatus from "../../../../enums/MercurialStatus";
import APIUrl from "../../../../APIUrl";
import ActionMenu from "../../../sub/ActionMenu";
import { FormattedMessage } from "react-intl";

class PromotionalOperationsRow extends React.Component {
  render() {
    const { promoOperation, mercurial } = this.props;

    let isInactive = true;
    let isOutDated = () => {
      const today = new Date(new Date().setHours(0, 0, 0, 0));

      const mercurialEndDate = mercurial.end_date
        ? new Date(new Date(mercurial.end_date).setHours(0, 0, 0, 0))
        : false;

      const operationEndDate = new Date(
        new Date(promoOperation.endDate).setHours(0, 0, 0, 0),
      );

      return (
        (mercurialEndDate && mercurialEndDate < today) ||
        operationEndDate < today
      );
    };

    isInactive = mercurial.status === MercurialStatus.INACTIVE;

    let menuItems = [];

    menuItems.push(
      {
        icon: "pen-to-square",
        action: () => {
          if (!isInactive && !isOutDated())
            this.props.editPromotionalOperationModal(promoOperation);
        },
        text: <FormattedMessage id="Modify" />,
        disabled: isInactive || isOutDated(),
      },
      {
        icon: "trash",
        action: () =>
          this.props.deletePromotionalOperationModal(promoOperation._id),
        text: <FormattedMessage id="Delete" />,
      },
    );

    const menuAction = (
      <ActionMenu
        icon={isOutDated() && "fa-regular fa-clock"}
        variant={isOutDated() && "danger"}
        items={menuItems}
      />
    );

    return (
      <tr key={this.props.keyId}>
        <td
          className={
            isOutDated() || isInactive
              ? "align-middle text-muted fst-italic"
              : "align-middle"
          }
        >
          {promoOperation.operationName}
        </td>
        <td
          className={
            isOutDated() || isInactive
              ? "align-middle text-muted fst-italic"
              : "align-middle"
          }
        >
          {DateUtil.toDate(promoOperation.startDate)}
        </td>
        <td
          className={
            isOutDated() || isInactive
              ? "align-middle text-muted fst-italic"
              : "align-middle"
          }
        >
          {DateUtil.toDate(promoOperation.endDate)}
        </td>
        <td
          className={
            isOutDated() || isInactive
              ? "align-middle text-muted fst-italic"
              : "align-middle"
          }
        >
          <a
            role="button"
            href={
              APIUrl.getPromoOperationFile +
              promoOperation._id +
              "/" +
              promoOperation.fileName +
              "?token=" +
              APIUrl.jwtToken
            }
            rel="noopener noreferrer"
            download={promoOperation.fileName}
            target="_blank"
          >
            {promoOperation.fileName}
          </a>
        </td>
        <td className="col-1 text-center ">{menuAction}</td>
      </tr>
    );
  }
}

export default PromotionalOperationsRow;
