import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Util from "../../../../util/Util";
import {
  Container,
  Row,
  Col,
  Form,
  Image,
  Alert,
  FormSelect,
} from "react-bootstrap";
import CustomLabel from "../../../sub/CustomLabel";
import SelectGender from "../../../sub/fields/select/SelectGender";
import InputNumber from "../../../sub/fields/input/InputNumber";
import Fieldset from "../../../sub/Fieldset";
import Legend from "../../../sub/Legend";
import MenuButton from "../../../sub/bootstrap/MenuButton";

class DeskInitialPage extends React.Component {
  constructor(props) {
    super(props);

    const { patient_analysis } = this.props;

    this.state = {
      name: "",
      helper_id: null,
      prescriber_id: null,
      patient_analysis: {
        gender: patient_analysis.gender || "",
        height: patient_analysis.height || 0,
        weight: patient_analysis.weight || 0,
        shoesize: patient_analysis.shoesize || 0,
        incontinence: patient_analysis.incontinence || "",
        bedsores: patient_analysis.bedsores || "",
        homecare_workers: patient_analysis.homecare_workers || "",
        home_accessibility: patient_analysis.home_accessibility || "",
      },
    };
  }

  onChangeSelect(field, value) {
    this.props.setState({ [field]: value });
  }

  onChangePatientData(field, value) {
    this.setState(
      (prevState) => ({
        patient_analysis: {
          ...prevState.patient_analysis, // Keep other patient props unchanged
          [field]: value, // Update current patient prop
        },
      }),
      () => {
        // Callback function to ensure that this.props.setState is called after state is updated
        this.props.setState({ patient_analysis: this.state.patient_analysis });
      },
    );
  }

  render() {
    const { user, patient, prescribers, helpers, helper_id, prescriber_id } =
      this.props;

    return (
      <Container>
        <Row>
          <Col md={3} className="mt-4 pe-0">
            <Image alt="" fluid src="./images/svg/pharma_logo_v2.svg" />
          </Col>
          <Col>
            <Form>
              <Fieldset>
                <Legend>
                  <FormattedMessage id="Participants" />
                </Legend>
                <Form.Group as={Row} className="mb-3 align-items-center">
                  <Col>
                    <CustomLabel
                      label={this.props.intl.formatMessage({ id: "Patient" })}
                      required
                    />
                  </Col>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      id="name"
                      autoComplete="off"
                      disabled
                      defaultValue={Util.formatFullName(
                        patient.first_name,
                        patient.name,
                      )}
                    ></Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Prescriber",
                    })}
                    displayAsCol
                  />
                  <Col sm={8}>
                    <FormSelect
                      id="prescribers_ids"
                      value={prescriber_id}
                      onChange={(e) =>
                        this.onChangeSelect("prescriber_id", e.target.value)
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "None" })}
                      </option>
                      {patient.prescribers_ids.map((pId) => {
                        const prescriber = prescribers.find(
                          (p) => p._id === pId,
                        );
                        if (!prescriber) return null;

                        return (
                          <option value={prescriber._id} key={prescriber._id}>
                            {Util.formatFullName(
                              prescriber.first_name,
                              prescriber.name,
                            )}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Helper" })}
                    displayAsCol
                  />
                  <Col sm={8}>
                    <FormSelect
                      id="helper_id"
                      value={helper_id}
                      onChange={(e) =>
                        this.onChangeSelect("helper_id", e.target.value)
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({ id: "None" })}
                      </option>
                      {patient.helpers_ids.map((pId) => {
                        const helper = helpers.find((p) => p._id === pId);
                        if (!helper) return null;

                        return (
                          <option value={helper._id} key={helper._id}>
                            {Util.formatFullName(
                              helper.first_name,
                              helper.name,
                            )}
                          </option>
                        );
                      })}
                    </FormSelect>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Audit.Made.By",
                    })}
                    displayAsCol
                  />
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      id="collaborator_name"
                      autoComplete="off"
                      disabled
                      defaultValue={Util.formatFullName(
                        user.first_name,
                        user.name,
                      )}
                    />
                  </Col>
                </Form.Group>
              </Fieldset>

              <Fieldset>
                <Legend>
                  <FormattedMessage id="Patient.About" />
                </Legend>
                <Alert variant="warning" className="mb-4">
                  <FormattedMessage id="Patient.About.Warning" />
                </Alert>
                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Gender" })}
                    displayAsCol
                  />
                  <Col sm={2} className="p-0">
                    <SelectGender
                      id="gender"
                      selectedOption={this.state.patient_analysis.gender}
                      onOptionChange={(event) => {
                        this.onChangePatientData("gender", event);
                      }}
                    />
                  </Col>
                  <Col>
                    <CustomLabel
                      labelClassName="no-wrap"
                      label={this.props.intl.formatMessage({
                        id: "Height.Centimeters",
                      })}
                    />
                  </Col>
                  <Col className="p-0">
                    <InputNumber
                      id="height"
                      min={0}
                      max={250}
                      defaultValue={this.state.patient_analysis.height}
                      onChange={(event) => {
                        this.onChangePatientData("height", event);
                      }}
                    />
                  </Col>
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Weight" })}
                    displayAsCol
                  />
                  <Col className="p-0">
                    <InputNumber
                      id="weight"
                      min={0}
                      max={300}
                      defaultValue={this.state.patient_analysis.weight}
                      onChange={(event) => {
                        this.onChangePatientData("weight", event);
                      }}
                    />
                  </Col>
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Shoe.Size" })}
                    displayAsCol
                  />
                  <Col className="ps-0">
                    <InputNumber
                      id="shoesize"
                      min={0}
                      max={60}
                      defaultValue={this.state.patient_analysis.shoesize}
                      onChange={(event) => {
                        this.onChangePatientData("shoesize", event);
                      }}
                    />
                  </Col>
                </Form.Group>
                <hr className="mb-3" />

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <CustomLabel
                    label={this.props.intl.formatMessage({
                      id: "Urinary.Incontinence",
                    })}
                    displayAsCol
                  />
                  <Col sm={7}>
                    <FormSelect
                      id="incontinence"
                      value={this.state.patient_analysis.incontinence}
                      onChange={(e) =>
                        this.onChangePatientData("incontinence", e.target.value)
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "No.Information",
                        })}
                      </option>
                      <option value="None">
                        {this.props.intl.formatMessage({
                          id: "Urinary.Incontinence.Select.None",
                        })}
                      </option>
                      <option value="Low">
                        {this.props.intl.formatMessage({
                          id: "Urinary.Incontinence.Select.Low",
                        })}
                      </option>
                      <option value="Medium">
                        {this.props.intl.formatMessage({
                          id: "Urinary.Incontinence.Select.Medium",
                        })}
                      </option>
                      <option value="High">
                        {this.props.intl.formatMessage({
                          id: "Urinary.Incontinence.Select.High",
                        })}
                      </option>
                    </FormSelect>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <Col>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Bedsores.Risk",
                      })}
                    />
                  </Col>
                  <Col sm={7}>
                    <FormSelect
                      id="bedsores"
                      value={this.state.patient_analysis.bedsores}
                      onChange={(e) =>
                        this.onChangePatientData("bedsores", e.target.value)
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "No.Information",
                        })}
                      </option>
                      <option value="None">
                        {this.props.intl.formatMessage({
                          id: "Bedsores.Risk.Select.None",
                        })}
                      </option>
                      <option value="Low">
                        {this.props.intl.formatMessage({
                          id: "Bedsores.Risk.Select.Low",
                        })}
                      </option>
                      <option value="Medium">
                        {this.props.intl.formatMessage({
                          id: "Bedsores.Risk.Select.Medium",
                        })}
                      </option>
                      <option value="High">
                        {this.props.intl.formatMessage({
                          id: "Bedsores.Risk.Select.High",
                        })}
                      </option>
                    </FormSelect>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3 align-items-center">
                  <Col>
                    <CustomLabel
                      label={this.props.intl.formatMessage({
                        id: "Homecare.Workers",
                      })}
                    />
                  </Col>
                  <Col sm={7}>
                    <FormSelect
                      id="homecare_workers"
                      value={this.state.patient_analysis.homecare_workers}
                      onChange={(e) =>
                        this.onChangePatientData(
                          "homecare_workers",
                          e.target.value,
                        )
                      }
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "No.Information",
                        })}
                      </option>
                      <option value="Yes">
                        {this.props.intl.formatMessage({ id: "Yes" })}
                      </option>
                      <option value="No">
                        {this.props.intl.formatMessage({ id: "No" })}
                      </option>
                    </FormSelect>
                  </Col>
                </Form.Group>
              </Fieldset>

              <Form.Group as={Row} className="mb-3 align-items-center">
                <Col className="text-end">
                  <MenuButton
                    icon="arrow-circle-right"
                    className="ms-auto"
                    variant="warning"
                    onClick={() =>
                      this.props.setState({
                        mode: "ongoing",
                      })
                    }
                  >
                    <FormattedMessage id="Start.Audit" />
                  </MenuButton>
                </Col>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    generalSettings: state.generalSettings,
    prescribers: Util.filterClients(state.clients, "prescriber"),
    helpers: Util.filterClients(state.clients, "helper"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(DeskInitialPage)),
);
