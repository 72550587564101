import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import AddPatientEquipmentModal from "./AddPatientEquipmentModal";
import { updPatientAdmin } from "../../actions/patients/patients";
import ConfirmationModal from "../sub/modals/ConfirmationModal";
import DateUtil from "../../util/DateUtil";
import EqSellModes from "../../enums/EqSellModes";
import ActionMenu from "../sub/ActionMenu";
import MaintenancesModal from "../maintenance/MaintenancesModal";
import Interventions from "../intervention/Interventions";
import { Alert, Col } from "react-bootstrap";
import Icon from "../sub/Icon";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import Util from "../../util/Util";

class PatientsEquipmentsReminded extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openModal(patient, equipment) {
    this.setState({
      modal: (
        <AddPatientEquipmentModal
          patient={patient}
          equipment={equipment}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  openInterventions(patient, equipment) {
    this.setState({
      modal: (
        <Interventions
          equipment={equipment}
          patient={patient}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  delete(patient, equipment) {
    if (!patient || !equipment) return;

    const onConfirm = () => {
      const updatedPatient = {
        _id: patient._id,
        equipments: patient.equipments,
      };

      updatedPatient.equipments = updatedPatient.equipments.filter(
        (e) => e._id !== equipment._id,
      );

      // Send to BE
      this.props.onUpdPatientAdmin(updatedPatient);
    };

    this.setState({
      modal: (
        <ConfirmationModal
          title={<FormattedMessage id="Confirm" />}
          onAccept={onConfirm}
          onDecline={() => this.closeModal()}
        >
          <FormattedMessage id="Confirm.Equipment.Removal" />
        </ConfirmationModal>
      ),
    });
  }

  renewalDateAlert(renewalDate) {
    const expirationWarningInMonths = 2;
    const todayIsoDate = DateUtil.DateTime().now().toISO();
    const warningDate = DateUtil.removeMonth(
      renewalDate,
      expirationWarningInMonths,
    );

    // Renewal date expired
    if (
      DateUtil.DateTime().fromISO(renewalDate) <
      DateUtil.DateTime().fromISO(todayIsoDate)
    ) {
      return "danger";
    }

    // Renewal date will expire within two months
    if (
      DateUtil.DateTime().fromISO(warningDate) <
      DateUtil.DateTime().fromISO(todayIsoDate)
    ) {
      return "warning";
    }

    // Not expired, expiration will be in more than two months
    return "success";
  }

  // Calculate renew date
  renewalDate(equipment) {
    // Get stored date
    let date = equipment.last_prescription_date;

    if (!equipment.auditEquipment) return null;

    // Calculate duration in SELL mode
    if (equipment.mode === EqSellModes.SALE) {
      if (parseInt(equipment.auditEquipment.purchase_renewal) !== 0) {
        date = DateUtil.addYear(
          date,
          equipment.auditEquipment.purchase_renewal,
        );
      } else {
        date = null;
      }
    }

    // Calculate duration in LEASING mode
    else if (equipment.mode === EqSellModes.LEASING) {
      // date = new Date(date.setMonth(date.getMonth() + parseInt(equipment.rental_renewal)));
      date = DateUtil.addMonth(date, equipment.rental_renewal);
    }

    return date;
  }

  openMaintenancesModal(equipment, patient) {
    // Quick patch to fix body while waiting to completely redo the interface
    // (Try to fix bug of difficulty to add a signature when multiple modals are opened)
    document.body.classList.add("modal-fixed");
    this.setState({
      modal: (
        <MaintenancesModal
          equipment={equipment}
          patient={patient}
          closeModal={() => this.closeModal()}
        />
      ),
    });
  }

  getHeaderActionMenu(auditEquipment) {
    if (auditEquipment && auditEquipment.is_renewal_obligatory)
      return (
        <span className="text-danger">
          <Icon icon="triangle-exclamation" />{" "}
          <FormattedMessage id="Renewal.Obligatory" />
        </span>
      );
    return "";
  }

  render() {
    const { equipments, user } = this.props;

    const EquipmentsNode = equipments.map((e) => {
      const renewalDate = this.renewalDate(e);

      if (this.renewalDateAlert(renewalDate) === "success") return null;

      const menuItems = [];

      menuItems.push({
        id: null,
        icon: "pen-to-square",
        disabled: false,
        action: () => this.openModal(e.patient, e),
        text: <FormattedMessage id="Modify" />,
      });

      if (user.access_pharmamad || user.access_module_home) {
        menuItems.push({
          id: null,
          icon: "wrench",
          disabled: !(
            e.auditEquipment &&
            e.auditEquipment.checkpoints &&
            e.auditEquipment.checkpoints.length
          ),
          action: () => this.openMaintenancesModal(e, e.patient),
          text: <FormattedMessage id="Maintenance" />,
        });

        menuItems.push({
          id: null,
          icon: "list",
          // Allow maintenance only if DM has checkpoints
          disabled: !e.auditEquipment,
          action: () => this.openInterventions(e.patient, e),
          text: <FormattedMessage id="Intervention" />,
        });
      }

      menuItems.push({
        id: null,
        icon: "trash",
        disabled: false,
        action: () => this.delete(e.patient, e),
        text: <FormattedMessage id="Delete" />,
      });

      const menuAction = (
        <ActionMenu
          variant={this.renewalDateAlert(renewalDate)}
          items={menuItems}
          icon={
            e.auditEquipment && e.auditEquipment.is_renewal_obligatory
              ? "triangle-exclamation"
              : "info-circle"
          }
          header={this.getHeaderActionMenu(e.auditEquipment)}
        />
      );

      return (
        <tr key={"equipment-" + e._id}>
          <td className="align-middle">
            {e.patient &&
              Util.formatFullName(e.patient.first_name, e.patient.name)}
          </td>
          <td className="align-middle">
            {e.auditEquipment && e.auditEquipment.name}
          </td>
          {!this.props.limit && (
            <td className="align-middle">
              {this.props.intl.formatMessage({ id: "EqSellMode." + e.mode })}
            </td>
          )}
          {!this.props.limit && (
            <td className="align-middle">
              {DateUtil.DateTime().fromISO(e.last_prescription_date).isValid ? (
                DateUtil.DateTime()
                  .fromISO(e.last_prescription_date)
                  .toLocaleString()
              ) : (
                <FormattedMessage id={"None.Feminine"} />
              )}
            </td>
          )}
          <td className="align-middle">
            {DateUtil.DateTime().fromISO(e.first_install_date).isValid ? (
              DateUtil.DateTime().fromISO(e.first_install_date).toLocaleString()
            ) : (
              <FormattedMessage id={"None.Feminine"} />
            )}
          </td>
          <td className="align-middle">
            <Alert
              variant={this.renewalDateAlert(renewalDate)}
              className="p-1 px-2 mb-0"
            >
              {DateUtil.DateTime().fromISO(renewalDate).toLocaleString()}
            </Alert>
          </td>
          {!this.props.limit && <td className="col-1">{menuAction}</td>}
        </tr>
      );
    });

    return (
      <>
        {(!equipments || (equipments && equipments.length === 0)) && (
          <TableToolbar>
            <Col>
              <Alert variant="info" className="mt-2 mb-2">
                <FormattedMessage id="Empty.Renewal.Alert" />
              </Alert>
            </Col>
          </TableToolbar>
        )}

        {equipments && equipments.length > 0 && (
          <table
            className={
              this.props.limit
                ? "table table-striped tablee4mad pointer-events-none"
                : "table table-striped tablee4mad"
            }
          >
            <thead>
              <tr className="tablerowhead">
                <th scope="col" className="text-nowrap">
                  <FormattedMessage id="Patient" />
                </th>
                <th scope="col" className="text-nowrap">
                  <FormattedMessage id="Equipment" />
                </th>
                {!this.props.limit && (
                  <th scope="col" className="text-nowrap">
                    <FormattedMessage id="Mode" />
                  </th>
                )}
                <th scope="col" className="text-nowrap">
                  <FormattedMessage id="Last.Prescription.Date" />
                </th>
                {!this.props.limit && (
                  <th scope="col" className="text-nowrap">
                    <FormattedMessage id="First.Install.Date" />
                  </th>
                )}
                <th scope="col" className="text-nowrap">
                  <FormattedMessage id="Next.Renewal.Date" />
                </th>
                {!this.props.limit && (
                  <th scope="col" className="text-nowrap">
                    <FormattedMessage id="Actions" />
                  </th>
                )}
              </tr>
            </thead>
            <tbody>{EquipmentsNode}</tbody>
          </table>
        )}

        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdPatientAdmin: (patient, successCallback) =>
      dispatch(updPatientAdmin(patient, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PatientsEquipmentsReminded));
