import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { updateCollaborator } from "../../../../actions/collaborators/collaborators";
import CustomLabel from "../../../sub/CustomLabel";
import { Form, Row, Col } from "react-bootstrap";
import SwitchToggle from "../../../sub/SwitchToggle";
import { FormattedMessage } from "react-intl";

class CollaboratorPermissions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      can_manage_catalog:
        this.props.collaborator?.permissions?.can_manage_catalog || false,
    };
  }

  updatePermission(field, value) {
    let data = {
      collaboratorId: this.props.collaborator._id,
      updatedField: "permissions",
      updatedValue: this.props.collaborator.permissions || {},
    };
    data.updatedValue[field] = value;
    this.setState({ [field]: value });
    this.props.onUpdateCollaborator(data);
  }

  render() {
    return (
      <Row>
        <Form.Group as={Row} className="d-flex col-12 align-items-center">
          <CustomLabel
            label={this.props.intl.formatMessage({
              id: "Can.Manage.Catalog",
            })}
            displayAsCol
            htmlFor="name"
            help={<FormattedMessage id="Hint.Manage.Catalog" />}
          />
          <Col md={2}>
            <SwitchToggle
              id={"can_manage_catalog"}
              onChange={(e) => {
                this.updatePermission(
                  "can_manage_catalog",
                  !this.state.can_manage_catalog,
                );
              }}
              checked={this.state.can_manage_catalog}
            />
          </Col>
        </Form.Group>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateCollaborator: (data) => dispatch(updateCollaborator(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(CollaboratorPermissions));
