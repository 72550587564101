import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Util from "../../../../util/Util";

import { logout } from "../../../../actions/authentication/authentication";
import { getListProducts } from "../../../../actions/products/products";

import Roles from "../../../../enums/Roles";
import Accessibilities from "../../../../enums/MenuAccessibilities";

import ProductsTotalUtil from "../../../../util/ProductsTotalUtil";

import MenuItemClientLogo from "../MenuItems/MenuItemClientLogo";
import DropdownMenuProfile from "../DropdownMenuProfile";
import MenuItem from "../MenuItems/MenuItem";
import MenuItemLogo from "../MenuItems/MenuItemLogo";
import APIUrl from "../../../../APIUrl";
import MenuItemCart from "../MenuItems/MenuItemCart";
import { Container, Nav, Navbar } from "react-bootstrap";
import "./menu.scss";

const propTypes = {
  cart: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  ordersSettings: PropTypes.any.isRequired,
  generalSettings: PropTypes.object.isRequired,
};

/**
 * Menu (navbar)
 * @param {Array} menuItems list of all informations for items menu
 * @param {Array} menuItemLogo list of informations for logo item menu
 */
class Menu extends React.Component {
  checkProductsExistById(productsId) {
    for (let i = 0; i < this.props.products.length; i++) {
      if (this.props.products[i]._id === productsId) {
        return false;
      }
    }
    return true;
  }

  getCartProducts() {
    let checkProducts = [];

    for (let cart of this.props.cart) {
      if (this.checkProductsExistById(cart.id_product)) {
        checkProducts.push(cart.id_product);
      }
    }

    if (checkProducts.length) {
      this.props.onGetListProducts(checkProducts);
    }
  }

  countCartProduct() {
    let nbr = 0;
    for (let c of this.props.cart) {
      nbr += c.quantity;
    }
    return nbr;
  }

  countTotalCart() {
    // Helper that will do all calculations for us
    let productsTotalUtil = 0;
    const { cart, products, ordersSettings } = this.props;
    if (cart.length !== 0 && products.length !== 0 && ordersSettings) {
      productsTotalUtil = new ProductsTotalUtil(
        cart,
        products,
        null,
        null,
        ordersSettings,
      );
      return productsTotalUtil.totalTtc;
    }
    return productsTotalUtil;
  }

  checkIsDisabled() {
    let disabled = true;
    const { cart, products, ordersSettings } = this.props;
    if (cart.length !== 0 && products.length !== 0) {
      let productsTotalUtil = new ProductsTotalUtil(
        cart,
        products,
        null,
        null,
        ordersSettings,
      );
      disabled = !productsTotalUtil.aboveMinToOrder();
    }
    return disabled;
  }

  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    let token = APIUrl.jwtToken;

    function onLogoutSuccess() {
      return window.location.replace(
        Util.checkHttps(process.env.REACT_APP_LOGIN_FE_URL) + token,
      );
    }

    this.props.onLogout(onLogoutSuccess);
  }

  getAccessibilityByRole(itemAccessibility) {
    let returnValue = false;
    switch (this.props.user.role) {
      case Roles.CLIENT:
        if (
          itemAccessibility ===
            Accessibilities.ADMIN_AND_SALES_REP_AND_PRESCRIBER &&
          this.props.user.client_type === "prescriber"
        )
          returnValue = true;
        if (
          itemAccessibility === Accessibilities.CLIENT_ONLY ||
          itemAccessibility === Accessibilities.COMMON
        )
          returnValue = true;
        break;
      case Roles.ADMIN:
        if (
          itemAccessibility ===
          Accessibilities.ADMIN_AND_SALES_REP_AND_PRESCRIBER
        )
          returnValue = true;
        if (
          itemAccessibility === Accessibilities.ADMIN_ONLY ||
          itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP ||
          itemAccessibility === Accessibilities.COMMON
        )
          returnValue = true;
        break;
      case Roles.SALES_REP:
        if (
          itemAccessibility ===
          Accessibilities.ADMIN_AND_SALES_REP_AND_PRESCRIBER
        )
          returnValue = true;
        if (
          itemAccessibility === Accessibilities.ADMIN_AND_SALES_REP ||
          itemAccessibility === Accessibilities.COMMON
        )
          returnValue = true;
        break;
      default:
    }
    return returnValue;
  }

  getAccessibilityByModules(itemModules, user = this.props.user) {
    let returnValue = false;
    for (let i = 0; i < itemModules.length; i++) {
      if (
        (itemModules[i] === "DESK" &&
          (user.access_pharmamad || user.access_module_desk)) ||
        (itemModules[i] === "HOME" &&
          (user.access_pharmamad || user.access_module_home)) ||
        (itemModules[i] === "STORE" &&
          (user.access_pharmamad || user.access_module_store))
      )
        return (returnValue = true);
    }

    return returnValue;
  }

  render() {
    this.getCartProducts();

    const { generalSettings, user, menuItems, menuItemLogo } = this.props;

    let totalCart = this.countTotalCart();
    let CartProductsNbr = this.countCartProduct();
    let disabled = this.checkIsDisabled();

    let clientLogoSrc = `${APIUrl.getGeneralSettingsLogo}${user.company_id}/${generalSettings._id}/${Math.random()}`;
    let clientName = generalSettings.commercial_sign;
    let hasLogo = generalSettings.logo;

    let isAdmin = user.role === Roles.ADMIN;
    let isCollaborator = user.role === Roles.SALES_REP;

    const menuItemNode = (items) => {
      return items.map((item, index) => {
        return (
          this.getAccessibilityByRole(item.accessibility) &&
          this.getAccessibilityByModules(item.modules) && (
            <MenuItem
              key={index}
              link={item.link}
              event={item.event}
              formattedMessageId={item.formattedMessageId}
              isCountBadge={item.isCountBadge}
              countValue={item.countValue}
            />
          )
        );
      });
    };

    return (
      <div id="mainMenuContainer" className="sticky-top">
        <Navbar collapseOnSelect expand="xl">
          <Container>
            <MenuItemLogo
              link={isAdmin || isCollaborator ? "/#" : "/home"}
              imgSrc={menuItemLogo.imgSrc}
              imgAlt={menuItemLogo.imgAlt}
              onClick={isAdmin || isCollaborator ? (e) => this.logout(e) : null}
            />
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>{menuItemNode(menuItems)}</Nav>

              {!isAdmin && !isCollaborator && (
                <MenuItemCart
                  link={"/home/cart"}
                  countValue={CartProductsNbr}
                  totalCart={totalCart}
                  disabled={disabled}
                />
              )}
              <Nav className="pe-3 me-auto">
                <MenuItemClientLogo
                  hasLogo={hasLogo}
                  imgSrc={clientLogoSrc}
                  imgAlt={clientName}
                />
              </Nav>
              <Nav>
                <DropdownMenuProfile />
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    cart: state.cart,
    products: state.products,
    ordersSettings: state.ordersSettings,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
    onGetListProducts: (data) => dispatch(getListProducts(data)),
  };
};

Menu.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
