import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Icon from "../../../sub/Icon.js";
import MenuButton from "../../../sub/bootstrap/MenuButton";
import ActionMenu from "../../../sub/ActionMenu";
import {
  deleteDeskCategory,
  sortCategory,
} from "../../../../actions/settings/deskCategories/admin/deskCategories.js";

class DeskCategoriesRowAdmin extends React.Component {
  onSort(category, direction) {
    if (!category) return;

    direction = direction === "up" || direction === "down" ? direction : "down";

    const data = {
      deskCategoryId: category._id,
      direction: direction,
      updatedValue:
        direction === "down" ? category.sortIndex + 1 : category.sortIndex - 1,
    };

    this.props.onSortCategory(data);
  }

  render() {
    const modalTitle = <FormattedMessage id="Confirm" />;
    const modalContent = (
      <div>
        <FormattedMessage id="Type.Category.Remove.Confirmation" />
        <br />
        <br />
        <div className="card text-white bg-danger">
          <div className="card-header">
            <Icon icon="triangle-exclamation" className="me-2 text-white" />
            <FormattedMessage id="Warning" />
          </div>
          <div className="card-body">
            <p className="card-text">
              <FormattedMessage id="Category.Delete.Warning.Content" />
            </p>
          </div>
        </div>
      </div>
    );

    const successCallback = () =>
      this.props.onDeleteDeskCategory({
        deskCategoryId: this.props.deskCategory._id,
      });
    const disabledUp = this.props.deskCategory.sortIndex === 0;
    const disabledDown =
      this.props.deskCategories.length - 1 <= this.props.deskCategory.sortIndex;
    const menuItems = [];

    menuItems.push(
      {
        icon: "arrow-up",
        action: (e) => {
          !disabledUp && this.onSort(this.props.deskCategory, "up");
        },
        disabled: disabledUp,
        text: <FormattedMessage id="Move.Up" />,
      },
      {
        icon: "arrow-down",
        action: (e) => {
          !disabledDown && this.onSort(this.props.deskCategory, "down");
        },
        disabled: disabledDown,
        text: <FormattedMessage id="Move.Down" />,
      },
      {
        icon: "pen-to-square",
        action: () => this.props.openDeskCategoriesModal(),
        text: <FormattedMessage id="Modify" />,
      },
      {
        icon: "trash",
        text: <FormattedMessage id="Delete" />,
        action: () =>
          this.props.openConfModal(modalTitle, modalContent, successCallback),
      },
    );

    const menuAction = <ActionMenu items={menuItems} />;

    return (
      <tr key={this.props.key}>
        <td className="align-middle">{this.props.deskCategory.name}</td>
        <td className="align-middle text-center">
          <MenuButton
            icon="eye"
            onClick={(e) => this.props.openDeskCategoriesModal("product")}
            hover={<FormattedMessage id="Display.Typeproducts" />}
          />
        </td>

        <td className="text-center align-middle">{menuAction}</td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deskCategories: state.deskCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSortCategory: (data, successCallback) =>
      dispatch(sortCategory(data, successCallback)),
    onDeleteDeskCategory: (data, successCallback) =>
      dispatch(deleteDeskCategory(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(DeskCategoriesRowAdmin));
