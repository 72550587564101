import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COMPANY = "GET_COMPANY";

export function getCompanyAction(company) {
  return { type: GET_COMPANY, company: company };
}

export const checkIfCompanyUrlExists = function (
  url,
  existsCallback,
  noExistsCallback,
) {
  return axios
    .get(APIUrl.checkIfCompanyUrlExists + url)
    .then(function (response) {
      if (response && response.status === 200) {
        if (existsCallback) existsCallback();
      } else {
        if (noExistsCallback) noExistsCallback();
      }
    });
};

export const getCompany = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCompany)
      .then(function (response) {
        if (response) dispatch(getCompanyAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteCompanyNotifAdmin = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteCompanyNotifAdmin, data)
      .then(function (response) {
        dispatch(getCompany());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
