import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../APIUrl";
import AutoSuggestProducts from "../sub/fields/autosuggest/AutoSuggestProducts";
import ProductsParserUtil from "../../util/ProductsParserUtil";
import { getProducts } from "../../actions/products/products";
import TableToolbar from "../sub/bootstrap/TableToolbar";
import ReactImageFallback from "react-image-fallback";
import { Card, Col, Row } from "react-bootstrap";
import { nanoid } from "nanoid";
import Products from "../products/Products";
import { getFamilies } from "../../actions/families/families";

class Families extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      family: props.location.state ? props.location.state.family : null,
      product: props.location.state ? props.location.state.product : null,
    };
  }

  onSearch(value, products) {
    // Find the product and redirect to it
    for (let product of products) {
      if (product.ref === value.ref) {
        this.setState({ family: product.family, product: product });
      }
    }
  }

  componentDidMount() {
    if (!this.props.user || !this.props.user.mercurial_Id) return;
    this.props.onGetFamiliesByMercurialId({
      mercurialId: this.props.user.mercurial_Id,
    });
    this.props.onGetProducts();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.props.onGetFamiliesByMercurialId({
        mercurialId: this.props.user.mercurial_Id,
      });
      this.props.onGetProducts();
    }
  }

  render() {
    const isXsScreen = window.innerWidth < 576;

    // No 'families'? No render
    if (
      !this.props.families ||
      this.props.families.length === 0 ||
      !this.props.products ||
      this.props.products.length === 0
    ) {
      return (
        <TableToolbar message={<FormattedMessage id="Empty.Families" />} />
      );
    }

    if (this.state.family) {
      return (
        <Products
          mercurialId={this.props.user.mercurial_Id}
          family={this.state.family}
          product={this.state.product}
          setState={(state) => this.setState(state)}
        />
      );
    }

    let suggestions = [];

    this.props.products.forEach((product) => {
      let obj = {};
      obj.designation = ProductsParserUtil.swapDesignationDelimiter(
        product.designation,
      );
      obj.caracteristiques = product.caracteristiques;
      obj.ref = product.ref;
      obj.mercurial_id = product.mercurial_id;
      obj.ref_frn = product.ref_frn;
      obj.famille = product.famille;
      obj.sous_famille = product.sous_famille;
      suggestions.push(obj);
    });

    const favoritesFamily = {
      name: this.props.intl.formatMessage({ id: "Top.Products" }).toUpperCase(),
      mercurial_id: this.props.user.mercurial_Id,
      isFavoriteFamily: true,
    };

    const families = [...this.props.families];
    families.unshift(favoritesFamily);

    return (
      <React.Fragment>
        <TableToolbar>
          <div className={isXsScreen ? "w-100" : "mx-auto w-50"}>
            <AutoSuggestProducts
              id="search"
              name="search"
              onSuggestionSelected={(value) =>
                this.onSearch(value, this.props.products)
              }
              suggestions={suggestions}
              placeholder={"Search.Product.Placeholder"}
            />
          </div>
        </TableToolbar>

        <Row>
          {families.map((family) => {
            const imgSrc = family.isFavoriteFamily
              ? "/images/favorite_family.png"
              : `${APIUrl.getFamilyImg}${family.mercurial_id}/${family._id}/${family.name}/${family.hasCustomImg}?token=${APIUrl.jwtToken}`;
            return (
              <Col
                sm={12}
                md={6}
                lg={4}
                className="mb-4 mx-auto mw-400"
                key={nanoid()}
              >
                <Card onClick={() => this.setState({ family: family })}>
                  <Card.Header>
                    <h6 className="text-dark text-center mb-0">
                      <strong>{family.name}</strong>
                    </h6>
                  </Card.Header>
                  <Card.Body className="cursor-pointer text-center">
                    <ReactImageFallback
                      src={imgSrc}
                      fallbackImage="/images/no_image_512.png"
                      initialImage="/images/loading.gif"
                      alt=""
                      className="w-50 zoomable-sm m-1"
                    />
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    user: state.user,
    families: state.families,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetFamiliesByMercurialId: (data) => {
      dispatch(getFamilies(data));
    },
    onGetProducts: () => dispatch(getProducts()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Families));
