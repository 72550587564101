import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_AUDIT_SETTINGS = "GET_AUDIT_SETTINGS";

function getAuditSettingsAction(auditSettings) {
  return { type: GET_AUDIT_SETTINGS, auditSettings: auditSettings };
}

export const getAuditSettings = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getAuditSettings)
      .then(function (response) {
        if (response) dispatch(getAuditSettingsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateAuditSettings = function (auditSettings) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateAuditSettings, auditSettings)
      .then(function (response) {
        dispatch(getAuditSettings());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addLogo = function (auditSettingsId, data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addLogoAuditSettings + auditSettingsId, data, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        dispatch(getAuditSettings());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};
